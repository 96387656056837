import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LanguageService } from 'src/app/service/language.service';
import Client from 'src/app/service/model/Client';
import MandateSigner from 'src/app/service/model/MandateSigner';
import PersonType from 'src/app/service/model/PersonType';

@Component({
  selector: 'mandates-publish-mandate-dialog',
  templateUrl: './publish-mandate-dialog.html',
  styleUrls: ['./publish-mandate-dialog.scss'],
})
export class PublishMandateDialog implements OnInit {
  constructor(
    private languageService: LanguageService,
    public dialogRef: MatDialogRef<PublishMandateDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {}

  get clientType(): PersonType {
    return this.data.client.person.getPersonType();
  }

  get clientName(): string {
    return this.data.client.person.getScreenName();
  }

  get clientNumber(): string {
    return this.data.client.person.getScreenNumber();
  }

  get address() {
    const { street, streetNumber, box, zipCode, city, country } =
      this.data.client.person.address || {};
    return street && streetNumber && zipCode && city && country
      ? `${street} ${streetNumber} ${
          box ? box : ''
        }, ${zipCode} ${city} (${this.languageService.getCountryName(country)})`
      : '-';
  }

  get isMultiple(): boolean {
    return this.data.mandates.length > 1;
  }

  get mandateTitle(): string {
    return this.isMultiple
      ? 'DIALOG.PUBLISH-MANDATE.SELECTED-MANDATE-TYPES'
      : 'DIALOG.PUBLISH-MANDATE.SELECTED-MANDATE-TYPE';
  }

  get signerName(): String {
    return `${this.data.mandateSigner.firstName} ${this.data.mandateSigner.lastName}`;
  }

  get signerNationalNumber(): string {
    return this.data.mandateSigner.nationalNumber;
  }

  get signerEmail(): string {
    return this.data.mandateSigner.emailAddress;
  }
}

interface DialogData {
  client: Client;
  mandates: { mandateType: string; mandateTypeName: string; endDate: Date }[];
  mandateSigner: MandateSigner;
}
