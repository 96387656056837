<header class="header">
  <mandates-return-button routerLink="/clients"></mandates-return-button>
  <h1>
    {{ 'HEADING.CREATE-NEW-CLIENT' | translate }}
  </h1>
  <p>
    {{ 'COMMON.FILL-IN' | translate }}
  </p>
</header>
<article [ngSwitch]="selectedPerson">
  <div class="button-container">
    <button
      mat-stroked-button
      [ngClass]="{
        select: selectedPerson === PersonType.NATURAL_PERSON
      }"
      (click)="onPersonButton(PersonType.NATURAL_PERSON)"
    >
      <div class="person-button">
        <mat-icon>person</mat-icon>
        <p>
          {{ 'PERSON.NATURAL' | translate }}
        </p>
      </div>
    </button>
    <button
      mat-stroked-button
      (click)="onPersonButton(PersonType.MORAL_PERSON)"
      [ngClass]="{
        select: selectedPerson === PersonType.MORAL_PERSON
      }"
    >
      <div class="person-button">
        <mat-icon>corporate_fare</mat-icon>
        <p>
          {{ 'PERSON.MORAL' | translate }}
        </p>
      </div>
    </button>
  </div>

  <mat-divider></mat-divider>

  <mandates-create-natural-person
    *ngSwitchCase="PersonType.NATURAL_PERSON"
    (formSaved)="onFormSaved($event)"
  ></mandates-create-natural-person>
  <mandates-create-moral-person
    *ngSwitchCase="PersonType.MORAL_PERSON"
    (formSaved)="onFormSaved($event)"
  ></mandates-create-moral-person>
</article>
