import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import MandateImportRow from '../../../service/model/MandateImportRow';
import PersonType from '../../../service/model/PersonType';

@Component({
  selector: 'mandates-import-mandates-list',
  templateUrl: './import-mandates-list.component.html',
  styleUrls: ['./import-mandates-list.component.scss'],
})
export class ImportMandatesListComponent implements OnInit, AfterViewInit {
  @Input() rows: MandateImportRow[];
  data: { type: PersonType; name: String; identifier: String; mandateTypes: String }[] = [];
  displayedColumns: string[] = ['type', 'name', 'identifier', 'mandateTypes'];
  datasource = new MatTableDataSource();

  @ViewChild(MatSort) sort: MatSort;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.rows.forEach((row) => this.mapRow(row));
    this.datasource.data = this.data;
  }

  ngAfterViewInit() {
    this.datasource.sort = this.sort;
  }

  mapRow(row: MandateImportRow) {
    const existing = this.data.find((item) => row.mandatorIdentifier === item.identifier);
    const mandateType = this.translateService.instant('MANDATES.TYPE.' + row.mandateType!!);
    if (existing) {
      existing.mandateTypes = existing.mandateTypes + `, ${mandateType}`;
    } else {
      this.data.push({
        type:
          row.mandatorIdentifier?.length === 10
            ? PersonType.MORAL_PERSON
            : PersonType.NATURAL_PERSON,
        name: row.mandator!!,
        identifier: row.mandatorIdentifier!!,
        mandateTypes: mandateType,
      });
    }
  }
}
