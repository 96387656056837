import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import MandateStatus from 'src/app/service/model/MandateStatus';
import MandateType from 'src/app/service/model/MandateType';
import PersonType from 'src/app/service/model/PersonType';
import {
  MandatesApiService,
  TerminateMandateCommand,
  TerminateMandatesCommand,
} from '../../core/mandates-api/mandates-api.service';
import Client from '../../service/model/Client';
import Page from '../../service/model/Page';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';
import { ToastService } from 'src/app/service/toast.service';
import MandateConceptListItem from 'src/app/service/dto/MandateConceptListItem';

@Component({
  selector: 'mandates-mandates-concept-list',
  templateUrl: './mandates-concept-list.component.html',
  styleUrls: ['./mandates-concept-list.component.scss'],
})
export class MandatesConceptListComponent implements OnInit {
  @Input() client?: Client;
  @Input('status') defaultStatusList?: MandateStatus[];
  @Input() filterForm?: UntypedFormGroup;
  @Input() displayedColumns: string[] = [
    'select',
    'clientType',
    'clientName',
    // 'mandateTypes',
    'lastOpened',
    'status',
    'actions',
  ];

  page: Page<MandateConceptListItem> = {
    content: [],
    pageable: undefined,
    totalPages: 0,
    totalElements: 0,
    last: true,
    numberOfElements: 0,
    first: true,
    number: 0,
    size: 0,
    empty: true,
  };

  isLoading = false;

  isSelectingAll: boolean = false;

  @Input() selection: SelectionModel<MandateConceptListItem> =
    new SelectionModel<MandateConceptListItem>(true, []);
  dataSource: MatTableDataSource<MandateConceptListItem> =
    new MatTableDataSource<MandateConceptListItem>();
  totalAvailableRows: number = 0;

  sort: Sort | undefined;

  readonly MAX_SELECTABLE_ROWS = 250;

  constructor(
    private mandatesApiService: MandatesApiService,
    private router: Router,
    private translateService: TranslateService,
    readonly matDialog: MatDialog,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.mandatesApiService.concepts$.subscribe((result) => {
      this.dataSource.data = result?.content || [];
      if (result) this.page = result;
      this.isSelectingAll = false;
    });

    this.mandatesApiService.totalAvailableConcepts$.subscribe((result) => {
      this.totalAvailableRows = result;
    });

    if (this.filterForm) {
      this.filterForm.valueChanges.subscribe(() => {
        this.handleAllFilter();
      });
    }
  }

  handlePageEvent(event?: PageEvent) {
    if (this.isSelectingAll) {
      return;
    }

    this.fetchConcepts(event?.pageIndex, event?.pageSize);
  }

  fetchAll() {
    const promise = this.fetchConcepts(0, this.totalAvailableRows);

    promise.then(
      () => {
        this.handleAllFilter();
        this.isSelectingAll = true;
      },
      () => {
        this.isSelectingAll = false;
      }
    );
  }

  handleAllFilter() {
    // if (this.isConcept || !this.filterForm) return;
    // const {
    //   clientName,
    //   clientType,
    //   mandateStatus,
    //   mandateType,
    // }: {
    //   clientName?: string;
    //   clientType?: PersonType;
    //   mandateStatus?: MandateStatus;
    //   mandateType: MandateType;
    // } = this.filterForm.value;
    // this.isLoading = true;
    // const filteredData = this.page.content.filter((mandate) => {
    //   return (
    //     (clientName
    //       ? mandate.clientName?.toLowerCase().includes(clientName.toLowerCase())
    //       : true) &&
    //     (clientType ? mandate.clientType === clientType : true) &&
    //     (mandateStatus
    //       ? mandate.status === mandateStatus
    //       : [...(this.defaultStatusList || [])].includes(mandate.status)) &&
    //     (mandateType
    //       ? mandate.mandateTypes?.toLowerCase().includes(mandateType.typeName.toLowerCase())
    //       : true)
    //   );
    // });
    // this.dataSource.data = filteredData;
    // this.handleAllSort();
  }

  handleAllSort() {
    // if (!this.sort?.direction) {
    //   this.dataSource.data = this.dataSource.data.sort((a, b) => {
    //     const idA = a.id;
    //     const idB = b.id;
    //     return idA.localeCompare(idB) || 0;
    //   });
    //   return;
    // }
    // const { active, direction } = this.sort;
    // switch (active) {
    //   case 'clientName':
    //     this.dataSource.data = this.dataSource.data.sort((a, b) => {
    //       const nameA = a.clientName?.toLowerCase().trim() || '';
    //       const nameB = b.clientName?.toLowerCase().trim() || '';
    //       return direction === 'asc'
    //         ? nameA.localeCompare(nameB) || 0
    //         : nameB.localeCompare(nameA) || 0;
    //     });
    //     break;
    //   case 'modifiedDate':
    //     this.dataSource.data = this.dataSource.data.sort((a, b) => {
    //       const aDate = new Date(a.lastOpened);
    //       const bDate = new Date(b.lastOpened);
    //       return direction === 'asc'
    //         ? aDate.getTime() - bDate.getTime() || 0
    //         : bDate.getTime() - aDate.getTime() || 0;
    //     });
    //     break;
    //   case 'mandateType.typeName':
    //     this.dataSource.data = this.dataSource.data.sort((a, b) => {
    //       const nameA = a.mandateTypes?.toLowerCase().trim() || '';
    //       const nameB = b.mandateTypes?.toLowerCase().trim() || '';
    //       return direction === 'asc'
    //         ? nameA.localeCompare(nameB) || 0
    //         : nameB.localeCompare(nameA) || 0;
    //     });
    //     break;
    // }
  }

  handleSortChange(sort: Sort) {
    this.sort = sort.direction ? sort : undefined;

    if (this.isSelectingAll) {
      this.handleAllSort();
      return;
    }

    if (sort.active === 'clientName' && sort.direction)
      this.sort = { active: 'engagementReference', direction: sort.direction };

    this.fetchConcepts(0, this.page.size);
  }

  private fetchConcepts(page?: number, size?: number, fetchAll?: boolean) {
    return this.mandatesApiService.getConcepts(page, size, this.client?.id || undefined, this.sort);
  }

  get status(): MandateStatus[] | undefined {
    if (this.filterForm?.get('mandateStatus')) {
      const status = this.filterForm?.get('mandateStatus')?.value;
      return [status];
    } else {
      return this.defaultStatusList;
    }
  }

  get mandateType(): MandateType | undefined {
    return this.filterForm?.get('mandateType')?.value;
  }

  get clientType(): PersonType | undefined {
    return this.filterForm?.get('clientType')?.value;
  }

  get clientName(): string | undefined {
    return this.filterForm?.get('clientName')?.value;
  }

  getClientName(row: MandateConceptListItem): string | undefined {
    if (row.concept) {
      return row.concept.clientName;
    } else {
      return row.bulkConcept.concepts.map((c) => c.clientName).join(', ');
    }
  }

  onEditClick(viewData: MandateConceptListItem) {
    console.log(viewData);

    this.router.navigate(['/update-mandate'], {
      queryParams: {
        clientId: viewData.concept?.clientReference,
        clientIds: viewData.bulkConcept?.concepts.map((c) => c.clientReference).join(','),
        mandatesConceptId: viewData.concept?.id,
        mandatesBulkConceptId: viewData.bulkConcept?.id,
      },
    });
  }

  onDeleteClick(item: MandateConceptListItem) {
    const clientReference = item.concept
      ? item.concept.clientReference
      : item.bulkConcept.concepts[0].clientReference;
    const clientName = item.concept
      ? item.concept.clientName
      : item.bulkConcept.concepts[0].clientName;

    if (item.bulkConcept) {
      const terminateMandateCommand = new TerminateMandatesCommand();

      terminateMandateCommand.pageNumber = this.page!!.number;
      terminateMandateCommand.pageSize = this.page!!.size;
      terminateMandateCommand.clientId = clientReference;
      terminateMandateCommand.clientName = clientName || undefined;

      item.bulkConcept.concepts.forEach((concept) => {
        terminateMandateCommand.conceptReferences.push(concept.id.toString());
      });

      this.mandatesApiService.terminateBulkConcept(terminateMandateCommand);
    } else {
      const terminateMandateCommand = new TerminateMandateCommand();
      terminateMandateCommand.id = item.concept.id.toString();
      terminateMandateCommand.pageNumber = this.page!!.number;
      terminateMandateCommand.pageSize = this.page!!.size;
      terminateMandateCommand.clientId = clientReference;
      terminateMandateCommand.clientName = clientName || undefined;

      this.mandatesApiService.terminateConcept(terminateMandateCommand);
    }
  }

  hasSelectedAllOfCurrentPage(): boolean {
    if (this.isSelectingAll) return true;

    const selectedIds = this.selection.selected.map((mandate) => mandate.id);
    const dataIds = this.dataSource.data.map((mandate) => mandate.id);
    return (
      dataIds.every((id) => selectedIds.includes(id)) &&
      this.selection.hasValue() &&
      this.totalAvailableRows > this.selection.selected.length
    );
  }
}
