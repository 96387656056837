import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { CreateMandateComponent } from './create-mandate/create-mandate.component';
import { MandateClientSearchComponent } from './create-mandate/mandate-client-search/mandate-client-search.component';
import { MandateInformationFormComponent } from './create-mandate/mandate-information-form/mandate-information-form.component';
import { PublishMandateDialog } from './create-mandate/publish-mandate-dialog/publish-mandate-dialog';
import { MandatesComponent } from './mandates.component';
import { MandateTypeCardComponent } from './mandate-type-card/mandate-type-card.component';
import { MandatesConceptListComponent } from './mandates-concept-list/mandates-concept-list.component';
import { MandatesListComponent } from './mandates-list/mandates-list.component';
import { DetailMandateComponent } from './detail-mandate/detail-mandate.component';
import { MandateStatusStepperComponent } from './detail-mandate/mandate-status-stepper/mandate-status-stepper.component';
import { MandatesKanbanComponent } from './mandates-kanban/mandates-kanban.component';
import { KanbanMandateItemComponent } from './mandates-kanban/kanban-mandate-item/kanban-mandate-item.component';
import { MandateSignerFormComponent } from './mandate-signer-form/mandate-signer-form.component';
import { ImportMandatesComponent } from './import-mandates/import-mandates.component';
import { ImportMandatesListComponent } from './import-mandates/import-mandates-list/import-mandates-list.component';
import { InvalidImportMandatesListComponent } from './import-mandates/invalid-import-mandates-list/invalid-import-mandates-list.component';
import { InvalidMandatesImportDialogComponent } from './import-mandates/invalid-mandates-import-dialog/invalid-mandates-import-dialog.component';
import { ImportMandatesLoadingComponent } from './import-mandates/import-mandates-loading/import-mandates-loading.component';
import { PublishBulkMandateDialog } from './create-mandate/publish-bulk-mandate-dialog/publish-bulk-mandate-dialog';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [
    MandatesComponent,
    CreateMandateComponent,
    MandateInformationFormComponent,
    MandateTypeCardComponent,
    MandatesListComponent,
    MandatesConceptListComponent,
    MandateClientSearchComponent,
    PublishMandateDialog,
    DetailMandateComponent,
    MandateStatusStepperComponent,
    MandatesKanbanComponent,
    KanbanMandateItemComponent,
    MandateSignerFormComponent,
    ImportMandatesComponent,
    ImportMandatesListComponent,
    InvalidImportMandatesListComponent,
    InvalidMandatesImportDialogComponent,
    ImportMandatesLoadingComponent,
    PublishBulkMandateDialog,
  ],
  imports: [
    CommonModule,
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    ReactiveFormsModule,
    ToastrModule,
  ],
  exports: [
    MandatesListComponent,
    MandatesConceptListComponent,
    MandatesKanbanComponent,
    DetailMandateComponent,
  ],
})
export class MandatesModule {}
