import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/service/language.service';
import MoralPerson from 'src/app/service/model/MoralPerson';

@Component({
  selector: 'mandates-moral-person-detail',
  templateUrl: './moral-person-detail.component.html',
  styleUrls: ['./moral-person-detail.component.scss'],
})
export class MoralPersonDetailComponent implements OnInit {
  @Input() person: MoralPerson;

  constructor(
    private languageService: LanguageService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {}

  get country(): string | undefined {
    return this.person.country
      ? this.languageService.getCountryName(this.person.country)
      : undefined;
  }

  get companyFormName(): string {
    return this.person.companyForm ? `COMPANY-FORM.CODE.${this.person.companyForm}` : '-';
  }

  get activeRegions(): string {
    let regionsString: string = '';
    this.person.activeRegions.forEach((region) => {
      const translatedRegion: string = this.translate.instant(`REGION.${region}`);
      regionsString += `${translatedRegion}, `;
    });
    return regionsString.slice(0, -2);
  }

  get address(): { name: string; value: string }[] {
    const { zipCode, street, city, streetNumber, box } = this.person.address || {};

    return [
      { name: 'COMMON.COUNTRY', value: this.country ? this.country : '-' },
      { name: 'COMMON.CITY', value: city ? city : '-' },
      { name: 'COMMON.POSTAL-CODE', value: zipCode ? zipCode : '-' },
      { name: 'COMMON.STREET', value: street ? street : '-' },
      { name: 'COMMON.STREET-NUMBER', value: streetNumber ? streetNumber : '-' },
      { name: 'COMMON.BUS-NUMBER', value: box ? box : '-' },
    ];
  }

  get companyInfo(): { name: string; value: string }[] {
    const { companyName, companyStatus, hasEmployees, language, companyNumber } = this.person;
    return [
      { name: 'COMMON.COMPANY-NAME', value: companyName },
      { name: 'COMMON.COMPANY-FORM', value: this.companyFormName },
      {
        name: 'COMMON.STATUS',
        value: companyStatus ? `COMPANY-STATUS.${companyStatus}` : '-',
      },
      {
        name: 'COMMON.EMPLOYEES',
        value: hasEmployees ? 'FORM-FIELD.YES' : 'FORM-FIELD.NO',
      },
      {
        name: 'COMMON.LANGUAGE',
        value: this.translate.instant('LANGUAGE.' + language),
      },
      {
        name: 'COMMON.COMPANY-NUMBER',
        value: companyNumber ? companyNumber : '-',
      },
    ];
  }

  get vatInfo(): { name: string; value: string }[] {
    const { isVatObligated, isVatUnityMember, hasPermanentEstablishment, vatNumber } = this.person;
    return [
      {
        name: 'COMMON.VAT-LIABLE',
        value: isVatObligated ? 'FORM-FIELD.YES' : 'FORM-FIELD.NO',
      },
      {
        name: 'COMMON.VAT-UNIT',
        value: isVatUnityMember ? 'FORM-FIELD.YES' : 'FORM-FIELD.NO',
      },
      {
        name: 'COMMON.PERM-IN-BE',
        value: hasPermanentEstablishment ? 'FORM-FIELD.YES' : 'FORM-FIELD.NO',
      },
      {
        name: 'COMMON.ACTIVE-IN-BELGIAN-REGIONS',
        value: this.activeRegions,
      },
      {
        name: 'COMMON.VAT-NUMBER',
        value: vatNumber ? vatNumber : '-',
      },
    ];
  }

  get sections() {
    return [
      { title: 'COMMON.COMPANY-INFO', list: this.companyInfo },
      { title: 'COMMON.ADDRESS', list: this.address },
      { title: 'COMMON.VAT-INFORMATION', list: this.vatInfo },
    ];
  }
}
