<div class="container" appDnd (fileDropped)="handleFile($event)" [class.file-selected]="file">
  <ng-container *ngIf="!file; else fileSelected">
    <input
      type="file"
      #fileDropRef
      id="fileDropRef"
      (change)="handleFile($event)"
      [accept]="allowedExtensionsFormatted"
    />
    <mat-icon class="folder">folder_open</mat-icon>
    <label for="fileDropRef">{{ 'UPLOAD.CHOOSE' | translate }}</label>
  </ng-container>
  <ng-template #fileSelected>
    <div class="filename">{{ file?.name }} <mat-icon>file_download_done</mat-icon></div>
    <p (click)="clear()">({{ 'UPLOAD.CLEAR' | translate }})</p>
  </ng-template>
</div>
