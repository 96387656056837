import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import MandateStatus from 'src/app/service/model/MandateStatus';
import MandateType from 'src/app/service/model/MandateType';
import PersonType from 'src/app/service/model/PersonType';
import {
  MandatesApiService,
  TerminateMandateCommand,
} from '../../core/mandates-api/mandates-api.service';
import MandateListItem from '../../service/dto/MandateListItem';
import Client from '../../service/model/Client';
import Page from '../../service/model/Page';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';
import { ToastService } from 'src/app/service/toast.service';
import { StorageService } from 'src/app/service/storage.service';

@Component({
  selector: 'mandates-mandates-list',
  templateUrl: './mandates-list.component.html',
  styleUrls: ['./mandates-list.component.scss'],
})
export class MandatesListComponent implements OnInit {
  @Input() client?: Client;
  @Input('status') defaultStatusList?: MandateStatus[];
  @Input() filterForm?: UntypedFormGroup;
  @Input() displayedColumns: string[] = [
    'select',
    'clientType',
    'clientName',
    'mandateTypes',
    'lastOpened',
    'status',
    'actions',
  ];

  page: Page<MandateListItem> = {
    content: [],
    pageable: undefined,
    totalPages: 0,
    totalElements: 0,
    last: true,
    numberOfElements: 0,
    first: true,
    number: 0,
    size: 0,
    empty: true,
  };

  isLoading = false;

  isSelectingAll: boolean = false;

  @Input() selection: SelectionModel<MandateListItem> = new SelectionModel<MandateListItem>(
    true,
    []
  );
  dataSource: MatTableDataSource<MandateListItem> = new MatTableDataSource<MandateListItem>();
  totalAvailableRows: number = 0;

  sort: Sort | undefined;

  readonly MAX_SELECTABLE_ROWS = 250;

  constructor(
    private mandatesApiService: MandatesApiService,
    private router: Router,
    private translateService: TranslateService,
    readonly matDialog: MatDialog,
    private toastService: ToastService,
    private storageService: StorageService
  ) {}

  ngOnInit(): void {
    this.mandatesApiService.mandates$.subscribe((result) => {
      this.dataSource.data = result?.content || [];
      if (result) this.page = result;
      this.isSelectingAll = false;
    });

    this.mandatesApiService.totalAvailableMandates$.subscribe((result) => {
      this.totalAvailableRows = result;
    });
    if (this.filterForm) {
      this.filterForm.valueChanges.subscribe(() => {
        this.isSelectingAll ? this.handleAllFilter() : this.fetchMandates();
      });
    }
  }

  handlePageEvent(event?: PageEvent) {
    if (this.isSelectingAll) {
      return;
    }

    this.fetchMandates(event?.pageIndex, event?.pageSize);
  }

  fetchAll() {
    const promise = this.fetchMandates(0, this.totalAvailableRows, true);

    promise.then(
      () => {
        this.handleAllFilter();
        this.isSelectingAll = true;
      },
      () => {
        this.isSelectingAll = false;
      }
    );
  }

  handleAllFilter() {
    if (!this.filterForm) return;
    const {
      clientName,
      clientType,
      mandateStatus,
      mandateType,
    }: {
      clientName?: string;
      clientType?: PersonType;
      mandateStatus?: MandateStatus;
      mandateType: MandateType;
    } = this.filterForm.value;

    this.isLoading = true;

    const filteredData = this.page.content.filter((mandate) => {
      return (
        (clientName
          ? mandate.clientName?.toLowerCase().includes(clientName.toLowerCase())
          : true) &&
        (clientType ? mandate.clientType === clientType : true) &&
        (mandateStatus
          ? mandate.status === mandateStatus
          : [...(this.defaultStatusList || [])].includes(mandate.status)) &&
        (mandateType
          ? mandate.mandateTypes?.toLowerCase().includes(mandateType.typeName.toLowerCase())
          : true)
      );
    });

    this.dataSource.data = filteredData;
    this.handleAllSort();
  }

  handleAllSort() {
    if (!this.sort?.direction) {
      this.dataSource.data = this.dataSource.data.sort((a, b) => {
        const idA = a.id;
        const idB = b.id;
        return idA.localeCompare(idB) || 0;
      });
      return;
    }

    const { active, direction } = this.sort;

    switch (active) {
      case 'clientName':
        this.dataSource.data = this.dataSource.data.sort((a, b) => {
          const nameA = a.clientName?.toLowerCase().trim() || '';
          const nameB = b.clientName?.toLowerCase().trim() || '';
          return direction === 'asc'
            ? nameA.localeCompare(nameB) || 0
            : nameB.localeCompare(nameA) || 0;
        });
        break;
      case 'modifiedDate':
        this.dataSource.data = this.dataSource.data.sort((a, b) => {
          const aDate = new Date(a.lastOpened);
          const bDate = new Date(b.lastOpened);
          return direction === 'asc'
            ? aDate.getTime() - bDate.getTime() || 0
            : bDate.getTime() - aDate.getTime() || 0;
        });
        break;
      case 'mandateType.typeName':
        this.dataSource.data = this.dataSource.data.sort((a, b) => {
          const nameA = a.mandateTypes?.toLowerCase().trim() || '';
          const nameB = b.mandateTypes?.toLowerCase().trim() || '';
          return direction === 'asc'
            ? nameA.localeCompare(nameB) || 0
            : nameB.localeCompare(nameA) || 0;
        });
        break;
    }
  }

  handleSortChange(sort: Sort) {
    this.sort = sort.direction ? sort : undefined;

    if (this.isSelectingAll) {
      this.handleAllSort();
      return;
    }

    if (sort.active === 'clientName' && sort.direction)
      this.sort = { active: 'engagementReference', direction: sort.direction };

    this.fetchMandates(0, this.page.size);
  }

  private fetchMandates(page?: number, size?: number, fetchAll?: boolean) {
    let defaultStatusList = this.defaultStatusList;
    if (fetchAll) defaultStatusList = [...(defaultStatusList || []), MandateStatus.ARCHIVED];
    return this.mandatesApiService.getMandates(
      page,
      size,
      fetchAll ? defaultStatusList : this.status,
      undefined,
      fetchAll ? undefined : this.mandateType,
      fetchAll ? undefined : this.clientType,
      fetchAll ? undefined : this.clientName,
      this.sort
    );
  }

  get status(): MandateStatus[] | undefined {
    if (this.filterForm?.get('mandateStatus')) {
      const status = this.filterForm?.get('mandateStatus')?.value;
      return [status];
    } else {
      return this.defaultStatusList;
    }
  }

  get mandateType(): MandateType | undefined {
    return this.filterForm?.get('mandateType')?.value;
  }

  get clientType(): PersonType | undefined {
    return this.filterForm?.get('clientType')?.value;
  }

  get clientName(): string | undefined {
    return this.filterForm?.get('clientName')?.value;
  }

  onEditClick(viewData: MandateListItem) {
    this.router.navigate(['/update-mandate'], {
      queryParams: {
        clientId: viewData.clientReference,
        mandatesConceptId: viewData.id,
      },
    });
  }

  onDeleteClick(item: MandateListItem) {
    const terminateMandateCommand = new TerminateMandateCommand();
    if (item.status != MandateStatus.DRAFT) {
      terminateMandateCommand.id = item.id;
      terminateMandateCommand.pageNumber = this.page!!.number;
      terminateMandateCommand.pageSize = this.page!!.size;
      terminateMandateCommand.redirect = false;
      terminateMandateCommand.clientName = item.clientName || undefined;
      terminateMandateCommand.clientId = item.clientReference;
      terminateMandateCommand.status = this.status;

      this.mandatesApiService.terminateMandate(terminateMandateCommand);
    }
  }

  canSendReminder(item: MandateListItem) {
    return !!this.storageService.getItemWithExpiry(item.id) && item.mandateSigner;
  }

  onSendReminder(item: MandateListItem) {
    this.mandatesApiService
      .sendReminderEmail(item.id, item.mandateSigner!!.engagementReference)
      .subscribe({
        next: () => {
          this.toastService.openSuccessToast(
            this.translateService.instant('TOAST.REMINDER.SUCCESS')
          );
          this.storageService.setItemWithExpiry(item.id, true);
        },
        error: (error) => {
          console.error(error);
          this.toastService.openErrorToast(this.translateService.instant('TOAST.REMINDER.ERROR'));
        },
      });
  }

  onInfoClick(mandate: MandateListItem) {
    this.router.navigate(['clients', mandate.clientReference, 'detail-mandate', mandate.id]);
  }

  canArchiveRow(mandate: MandateListItem): boolean {
    return (
      mandate.status === MandateStatus.DECLINED ||
      mandate.status === MandateStatus.ERROR ||
      mandate.status === MandateStatus.TERMINATED
    );
  }

  onArchiveClick(mandate: MandateListItem) {
    this.mandatesApiService.archiveMandate(mandate.id).subscribe({
      next: () => {
        if (this.isSelectingAll) {
          this.fetchAll();
        } else {
          this.fetchMandates();
        }
        this.toastService.openSuccessToast(
          this.translateService.instant('TOAST.MANDATE-ARCHIVED.SUCCESS')
        );
      },
      error: (error) => {
        console.error(error);
        this.toastService.openErrorToast(
          this.translateService.instant('TOAST.MANDATE-ARCHIVED.ERROR')
        );
      },
    });
  }

  hasSelectedAllOfCurrentPage(): boolean {
    if (this.isSelectingAll) return true;

    const selectedIds = this.selection.selected.map((mandate) => mandate.id);
    const dataIds = this.dataSource.data.map((mandate) => mandate.id);
    return (
      dataIds.every((id) => selectedIds.includes(id)) &&
      this.selection.hasValue() &&
      this.totalAvailableRows > this.selection.selected.length
    );
  }
}
