import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { LanguageService } from 'src/app/service/language.service';
import { AuthService } from '../../../core/authentication/auth.service';
import { UserInfo } from '../../../core/authentication/UserInfo';
import { LoadingService } from '../../../core/loading/loading.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'mandates-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavComponent implements OnInit {
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => result.matches),
    shareReplay()
  );

  defaultLang: string = 'nl';
  manualUrl: string = environment.manual.nl;
  faqUrl: string = environment.faq.nl;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private languageSerivce: LanguageService,
    private translateService: TranslateService,
    private authService: AuthService,
    private loadingService: LoadingService
  ) {}

  async ngOnInit() {
    this.translateService.onDefaultLangChange.subscribe(({ lang }) => {
      this.defaultLang = lang;
      if (lang.includes('fr')) {
        this.manualUrl = environment.manual.fr;
        this.faqUrl = environment.faq.fr;
      } else {
        this.manualUrl = environment.manual.nl;
        this.faqUrl = environment.faq.nl;
      }
    });
    await this.authService.login();
  }

  onHelpClick() {
    window.open(this.manualUrl, '_blank');
  }

  onFaqClick() {
    window.open(this.faqUrl, '_blank');
  }

  onSelectionChange(event: any) {
    this.languageSerivce.use(event.value);
  }

  get isAuthenticated$(): Observable<boolean> {
    return this.authService.isAuthenticated$;
  }

  get userInfo$(): Observable<UserInfo | undefined> {
    return this.authService.userInfo$;
  }

  get isLoading$(): Observable<boolean> {
    return this.loadingService.loading$;
  }
}
