import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
  selector: 'mandates-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
})
export class InputFieldComponent implements OnInit {
  @Input() parentForm: UntypedFormGroup;
  @Input() control: string;
  @Input() label: string;
  @Input() type: string;
  @Input() options: { name: string; value: any }[];
  @Input() multiple: boolean = false;
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() icon: string | null = null;
  @Input() isLoading = false;
  @Input() disabled = false;
  @Input() minDate?: Date = undefined;
  @Input() maxDate?: Date = undefined;
  @Input() isReadonly?: boolean = false;

  @Output() selectionChange = new EventEmitter();

  constructor() {}

  ngOnInit() {
    if (this.disabled) {
      this.formControl?.disable();
    }
  }

  onSelectChange(event: any) {
    this.selectionChange.emit(event);
  }

  get formControl(): UntypedFormControl {
    return this.parentForm.get(this.control) as UntypedFormControl;
  }

  get showErrorMessage(): boolean | null {
    return this.formControl?.errors && this.formControl?.touched;
  }

  get errorMessage() {
    const errors = this.formControl?.errors;
    if (!errors || !Object.keys(errors).length) return null;

    switch (Object.keys(errors)[0]) {
      case 'required':
        return 'VALIDATION-ERRORS.REQUIRED';
      case 'empty':
        return 'VALIDATION-ERRORS.NOT-EMPTY';
      case 'pattern':
        return 'VALIDATION-ERRORS.PATTERN';
      case 'min':
      case 'max':
        return 'VALIDATION-ERRORS.INVALID';
      case 'minlength':
        return 'VALIDATION-ERRORS.MIN-LENGTH';
      case 'maxlength':
        return 'VALIDATION-ERRORS.MAX-LENGTH';
      case 'email':
        return 'VALIDATION-ERRORS.EMAIL';
      case 'custom':
        return this.formControl?.errors?.custom;
      default:
        return null;
    }
  }

  get isRequired(): boolean {
    if (this.formControl?.validator) {
      const validator = this.formControl.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    return false;
  }
}
