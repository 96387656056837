<mat-card [ngClass]="isDark ? 'dark' : 'light'">
  <section class="header">
    <p class="name">{{ name | titlecase }}</p>
    <p class="national-number" [ngClass]="isDark ? 'dark' : 'light'">
      {{ signer?.nationalNumber }}
    </p>
  </section>
  <section class="body">
    <span>
      <mat-icon inline>email</mat-icon>
      {{ signer?.emailAddress }}
    </span>
  </section>
</mat-card>
