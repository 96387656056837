import { Component, Input, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { ClientsApiService } from 'src/app/core/clients-api/clients-api.service';
import { EmployeesApiService } from 'src/app/core/employees-api/employees-api.service';
import Client from 'src/app/service/model/Client';
import Employee from 'src/app/service/model/Employee';
import MoralPerson from 'src/app/service/model/MoralPerson';
import NaturalPerson from 'src/app/service/model/NaturalPerson';
import Page from 'src/app/service/model/Page';
import PersonType from 'src/app/service/model/PersonType';

@Component({
  selector: 'mandates-employee-detail',
  templateUrl: './employee-detail.component.html',
  styleUrls: ['./employee-detail.component.scss'],
})
export class EmployeeDetailComponent implements OnInit {
  employeeId: string;
  @Input() employee: Employee;

  isLoading: boolean = true;

  paginatedClients: Page<Client> = {
    content: [],
    pageable: undefined,
    totalPages: 0,
    totalElements: 0,
    last: true,
    numberOfElements: 0,
    first: true,
    number: 0,
    size: 0,
    empty: true,
  };

  displayedColumns: string[] = ['clientType', 'clientName', 'number'];

  constructor(
    private clientApiService: ClientsApiService,
    private employeeApiService: EmployeesApiService,
    private route: ActivatedRoute
  ) {
    this.handlePageEvent({ pageIndex: 0, pageSize: 10, length: 0 });
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('employeeId');
    if (id) {
      this.employeeId = id;
      this.employeeApiService
        .getEmployeeById(this.employeeId)
        .subscribe((employee) => (this.employee = employee));
      this.handlePageEvent({
        pageIndex: 0,
        pageSize: 10,
        length: 0,
      });
    }
  }

  handlePageEvent(event?: PageEvent) {
    this.isLoading = true;
    this.clientApiService
      .getExclusiveClients(this.employeeId, event?.pageIndex, event?.pageSize)
      .subscribe((page) => {
        this.paginatedClients = page;
        this.isLoading = false;
      });
  }

  get employeeName(): string {
    return `${this.employee.firstName} ${this.employee.lastName}`;
  }

  get clients() {
    return this.paginatedClients.content.map((client) => {
      return {
        id: client.id,
        type: this.getPersonType(client.person),
        name: this.getScreenName(client.person),
        number: this.getScreenNumber(client.person),
      };
    });
  }

  private getPersonType(person: MoralPerson | NaturalPerson): PersonType {
    return (person as MoralPerson).companyName
      ? PersonType.MORAL_PERSON
      : PersonType.NATURAL_PERSON;
  }

  private getScreenName(person: MoralPerson | NaturalPerson): string {
    return this.getPersonType(person) == PersonType.MORAL_PERSON
      ? (person as MoralPerson).companyName
      : `${(person as NaturalPerson).firstName} ${(person as NaturalPerson).lastName}`;
  }
  private getScreenNumber(person: MoralPerson | NaturalPerson): string {
    return this.getPersonType(person) == PersonType.MORAL_PERSON
      ? (person as MoralPerson).companyNumber
      : (person as NaturalPerson).nationalNumber;
  }
}
