<div class="mat-elevation-z2 stepper-horizontal">
  <div class="horizontal-stepper-header-container">
    <ng-container *ngIf="mandate?.status !== 'ERROR'; else errorStep">
      <ng-container *ngFor="let step of steps; index as i">
        <div
          class="horizontal-stepper-header"
          [class.active]="step.status === mandate?.status"
          [class.passed]="i < activeIndex"
        >
          <div class="step-icon-state-number">
            <div class="step-icon-content">
              <span class="ng-star-inserted">
                <mat-icon style="scale: 0.8; margin-top: 4px">done</mat-icon>
              </span>
            </div>
          </div>
          <div
            class="step-label"
            matTooltip="{{
              'MANDATES.' + step.status + '.TOOLTIP'
                | translate: { date: mandate?.modifiedDate | date: 'dd-MM-yyyy' }
            }}"
          >
            <div>{{ 'MANDATES.' + step.status | translate }}</div>
          </div>
        </div>
        <div
          *ngIf="i !== steps.length - 1"
          class="stepper-horizontal-line"
          [class.passed]="i < activeIndex"
        ></div>
      </ng-container>
    </ng-container>
    <ng-template #errorStep>
      <div class="stepper-horizontal-line"></div>
      <div class="horizontal-stepper-header active error">
        <div class="step-icon-state-number">
          <div class="step-icon-content">
            <span class="ng-star-inserted">
              <mat-icon style="scale: 0.8; margin-top: 6px">close</mat-icon>
            </span>
          </div>
        </div>
        <div class="step-label" matTooltip="{{ 'MANDATES.ERROR.TOOLTIP' | translate }}">
          <div>{{ 'MANDATES.ERROR' | translate }}</div>
        </div>
      </div>
      <div class="stepper-horizontal-line"></div>
    </ng-template>
  </div>
</div>
