import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmployeesApiService } from 'src/app/core/employees-api/employees-api.service';
import Employee from 'src/app/service/model/Employee';

@Component({
  selector: 'mandates-add-employee-access-dialog',
  templateUrl: './add-employee-access-dialog.component.html',
  styleUrls: ['./add-employee-access-dialog.component.scss'],
})
export class AddEmployeeAccessDialog implements OnInit {
  employees: Employee[];
  employee: Employee;

  constructor(
    public dialogRef: MatDialogRef<AddEmployeeAccessDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public employeeApiService: EmployeesApiService
  ) {}

  ngOnInit(): void {
    this.employeeApiService.employees$.subscribe((employees) => {
      this.employees = employees;
    });
  }

  onCancelClick(): void {
    this.dialogRef.close({ confirm: false, employee: null });
  }

  onConfirmClick(): void {
    this.dialogRef.close({ confirm: true, employee: this.employee });
  }
}

interface DialogData {
  title: string;
  cancelText: string;
  confirmText: string;
  confirmColor: string;
}
