<div (outsideClick)="isOpen = false" class="mandate-signer-form-wrapper">
  <button
    mat-raised-button
    (click)="toggleIsOpen()"
    type="button"
    class="signer-button"
    [class.active]="isOpen"
  >
    <mat-icon>add_circle</mat-icon>
    {{ 'MANDATES.SIGNER' | translate }}
  </button>

  <article *ngIf="isOpen">
    <section class="signer-form">
      <h2>{{ 'MANDATES.DETAILS-MANDATE-SIGNER' | translate }}</h2>
      <mandates-input-field
        [parentForm]="form"
        control="nationalNumber"
        type="text"
        label="COMMON.NATIONAL-NUMBER"
        type="digit"
      ></mandates-input-field>
      <mandates-input-field
        [parentForm]="form"
        control="firstName"
        type="text"
        label="COMMON.FIRST-NAME"
      ></mandates-input-field>
      <mandates-input-field
        [parentForm]="form"
        control="lastName"
        type="text"
        label="COMMON.LAST-NAME"
      ></mandates-input-field>
      <mandates-input-field
        [parentForm]="form"
        control="emailAddress"
        type="text"
        label="COMMON.E-MAIL"
      ></mandates-input-field>
      <button
        mat-button
        class="save-btn"
        (click)="isLoading = !isLoading"
        [class.active-btn]="form.valid"
        (click)="save()"
      >
        {{ 'BUTTON.SAVE' | translate }}
      </button>
    </section>
    <section class="signer-list-container">
      <h2>{{ signerListTitle | translate }}</h2>
      <mat-spinner *ngIf="isLoading; else signerList"></mat-spinner>
    </section>
  </article>
</div>

<ng-template #signerList>
  <div
    class="signer-list"
    *ngIf="mandateSigners && mandateSigners.length > 0; else noSignersBanner"
  >
    <mandates-mandate-signer-card
      class="card"
      *ngFor="let signer of mandateSigners"
      [signer]="signer"
      (click)="onSignerCardClick(signer)"
    ></mandates-mandate-signer-card>
  </div>
</ng-template>

<ng-template #noSignersBanner>
  <div class="no-signers-banner">
    <span>{{ noMandateSigners | translate }}</span>
  </div>
</ng-template>
