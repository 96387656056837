import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { ClientsApiService } from '../core/clients-api/clients-api.service';
import Client from '../service/model/Client';
import Page from '../service/model/Page';
import PersonType from '../service/model/PersonType';
import { ToastService } from '../service/toast.service';
import { SelectionModel } from '@angular/cdk/collections';
import { TranslateService } from '@ngx-translate/core';
import { MatTableDataSource } from '@angular/material/table';
import { EmployeesApiService } from '../core/employees-api/employees-api.service';
import { Sort } from '@angular/material/sort';
import { MandatesApiService } from '../core/mandates-api/mandates-api.service';

@Component({
  selector: 'mandates-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
})
export class ClientsComponent implements OnInit {
  PersonType = PersonType;
  paginatedClients: Page<Client> = {
    content: [],
    pageable: undefined,
    totalPages: 0,
    totalElements: 0,
    last: true,
    numberOfElements: 0,
    first: true,
    number: 0,
    size: 0,
    empty: true,
  };
  isLoading: boolean = true;
  hasErrors: boolean;

  displayedColumns: string[] = ['select', 'clientType', 'clientName', 'clientNumber', 'actions'];

  clientData: ClientRowData[] = [];

  filters: {
    name: string;
    options: { name: string; value: any }[];
    controlName: string;
    isSearchFilter?: boolean;
  }[] = [
    {
      name: 'LIST.CLIENTTYPE',
      options: [
        {
          name: 'PERSON_TYPE.NATURAL_PERSON',
          value: PersonType.NATURAL_PERSON,
        },
        {
          name: 'PERSON_TYPE.MORAL_PERSON',
          value: PersonType.MORAL_PERSON,
        },
      ],
      controlName: 'personType',
    },
    { name: 'COMMON.EMPLOYEE', options: [], controlName: 'createdByEmployee' },
    {
      name: 'LIST.WITHOUT-MANDATE',
      options: [],
      controlName: 'mandateType',
    },
    {
      name: 'SEARCH.CLIENT-KEYWORD-PLACEHOLDER',
      options: [],
      controlName: 'keyword',
      isSearchFilter: true,
    },
  ];

  form: UntypedFormGroup = new UntypedFormGroup({});

  resultsLength: number;

  selectedClients: SelectionModel<Client> = new SelectionModel<Client>(true, []);

  totalAvailableClients: number = 0;

  isSelectingAllMatchingClients: boolean = false;
  unpaginatedClients: Page<Client> = {
    content: [],
    pageable: undefined,
    totalPages: 0,
    totalElements: 0,
    last: true,
    numberOfElements: 0,
    first: true,
    number: 0,
    size: 0,
    empty: true,
  };

  dataSource = new MatTableDataSource<Client>([]);

  sort: Sort | undefined = undefined;

  isUnpagedWithoutMandateType: boolean = false;

  readonly MAX_SELECTABLE_ROWS = 250;

  constructor(
    private clientsApiService: ClientsApiService,
    private toast: ToastService,
    private router: Router,
    private translateService: TranslateService,
    private employeesApiService: EmployeesApiService,
    private mandatesApiService: MandatesApiService
  ) {}

  ngOnInit(): void {
    this.handlePageEvent({ pageIndex: 0, pageSize: 10, length: 0 });

    this.employeesApiService.getEmployees().subscribe((response) => {
      const employees = response.map((employee) => ({
        name: `${employee.firstName} ${employee.lastName}`,
        value: employee.id,
      }));

      const index = this.getFilterIndex('createdByEmployee');
      this.filters[index].options = employees;
    });

    this.mandatesApiService.getMandateTypes().subscribe((response) => {
      const mandateTypes = response.map((mandateType) => ({
        name: `MANDATES.TYPE.${mandateType.typeName}`,
        value: mandateType.id,
      }));

      const index = this.getFilterIndex('mandateType');
      this.filters[index].options = mandateTypes;
    });

    this.form.valueChanges.subscribe(({ mandateType }) => {
      if (this.isSelectingAllMatchingClients && mandateType) {
        this.isSelectingAllMatchingClients = false;
        this.isUnpagedWithoutMandateType = false;
      }

      if (this.isSelectingAllMatchingClients) {
        this.handleALLClientsFilter();
      } else {
        this.fetchPaginatedClients({ pageIndex: 0, pageSize: 10, length: 0 });
      }
    });
  }

  private getFilterIndex(controlName: string) {
    return this.filters.findIndex((filter) => filter.controlName === controlName);
  }

  private handleALLClientsFilter() {
    if (this.keyword === '' && this.personType === null) {
      this.dataSource.data = this.unpaginatedClients.content;
      return;
    }

    const filteredData = this.unpaginatedClients.content.filter((client) => {
      if (this.keyword && this.keyword !== '') {
        const keyword = this.keyword?.toLowerCase();
        const clientName = client.person.getScreenName().toLowerCase();
        const clientNumber = client.person.getScreenNumber().toLowerCase();
        const keywordMatches = clientName.includes(keyword) || clientNumber.includes(keyword);

        if (this.personType) {
          return keywordMatches && client.person.type === this.personType;
        }

        return keywordMatches;
      }

      if (this.personType) {
        return client.person.type === this.personType;
      }

      if (this.createdByEmployee) {
        return client.createdBy === this.createdByEmployee;
      }

      return true;
    });
    this.dataSource.data = filteredData;
  }

  handlePageEvent(event?: PageEvent) {
    if (this.isSelectingAllMatchingClients || this.isUnpagedWithoutMandateType) return;
    this.fetchPaginatedClients(event);
  }

  private fetchPaginatedClients(pageEvent?: PageEvent) {
    this.clientsApiService
      .getClients(
        pageEvent?.pageIndex,
        pageEvent?.pageSize,
        this.keyword,
        this.personType,
        this.createdByEmployee,
        this.sort,
        this.mandateType
      )
      .subscribe({
        next: (page) => {
          page.content = this.mapClientDTOs(page.content);

          this.dataSource.data = page.content;
          this.paginatedClients = page;

          this.isLoading = false;

          this.totalAvailableClients =
            page.totalElements > this.totalAvailableClients
              ? page.totalElements
              : this.totalAvailableClients;

          this.isUnpagedWithoutMandateType = page.first && page.last && !!this.mandateType;
        },
        error: (error) => {
          this.toast.openErrorToast(`[${error.status}: ${error.name}] ${error.message}`);
          console.error(error);
          this.isLoading = false;
        },
      });
  }

  onCreateClick() {
    this.router.navigate(['create-client']);
  }

  onInfoClick(id: string) {
    this.router.navigate([`clients/${id}`]);
  }

  get keyword(): string | undefined {
    return this.form.get('keyword')?.value;
  }

  get personType(): PersonType | undefined {
    return this.form.get('personType')?.value;
  }

  get createdByEmployee(): string | undefined {
    return this.form.get('createdByEmployee')?.value;
  }

  get mandateType(): string | undefined {
    return this.form.get('mandateType')?.value;
  }

  displayClientType(row: Client): PersonType {
    return row.person.getPersonType();
  }

  displayClientName(row: Client) {
    return row.person.getScreenName();
  }

  displayClientNumber(row: Client) {
    return row.person.getScreenNumber();
  }

  fetchAllClients() {
    this.clientsApiService.getClients().subscribe((page) => {
      this.totalAvailableClients = page.totalElements;

      this.clientsApiService.getClients(0, this.totalAvailableClients).subscribe((page) => {
        page.content = this.mapClientDTOs(page.content);
        this.unpaginatedClients = page;
        this.dataSource.data = page.content;
        this.handleALLClientsFilter();
        this.isSelectingAllMatchingClients = true;
      });
    });
  }

  get page(): Page<Client> {
    return this.isSelectingAllMatchingClients ? this.unpaginatedClients : this.paginatedClients;
  }

  private mapClientDTOs(clients: Client[]): Client[] {
    return clients.map((client) => this.mapClientDTO(client));
  }

  private mapClientDTO(client: Client): Client {
    return new Client({
      id: client.id,
      person: client.person,
      engagementType: client.engagementType,
      createdBy: client.createdBy,
    });
  }

  hasSelectedAllOfCurrentPage(): boolean {
    if (this.isSelectingAllMatchingClients) return true;

    if (this.isUnpagedWithoutMandateType && this.dataSource.paginator) {
      console.log(this.dataSource);

      return this.selectedClients.selected.length >= this.dataSource.paginator.pageSize;
    }

    const selectedIds = this.selectedClients.selected.map((s: any) => s.id);
    const dataIds = this.dataSource.data.map((d: any) => d.id);
    return (
      dataIds.every((id: any) => selectedIds.includes(id)) &&
      this.selectedClients.hasValue() &&
      this.totalAvailableClients > this.selectedClients.selected.length
    );
  }

  handleSortChange(sort: Sort) {
    this.sort = sort.direction ? sort : undefined;

    if (this.isSelectingAllMatchingClients) {
      this.handleAllSort();
    } else {
      this.handlePageEvent({
        pageIndex: this.paginatedClients.number,
        pageSize: this.paginatedClients.size,
        length: this.paginatedClients.totalElements,
      });
    }
  }

  private handleAllSort() {
    if (!this.sort?.direction) {
      this.dataSource.data = this.dataSource.data.sort((a, b) => {
        const idA = a.id;
        const idB = b.id;
        return idA.localeCompare(idB) || 0;
      });
      return;
    }

    const { active, direction } = this.sort;

    switch (active) {
      case 'clientType': {
        this.dataSource.data = this.dataSource.data.sort((a, b) => {
          const typeA = a.person.type;
          const typeB = b.person.type;
          return direction === 'asc'
            ? typeA.localeCompare(typeB) || 0
            : typeB.localeCompare(typeA) || 0;
        });
        break;
      }
      case 'clientName': {
        this.dataSource.data = this.dataSource.data.sort((a, b) => {
          const nameA = a.person.getScreenName().toLowerCase().trim();
          const nameB = b.person.getScreenName().toLowerCase().trim();
          return direction === 'asc'
            ? nameA.localeCompare(nameB) || 0
            : nameB.localeCompare(nameA) || 0;
        });
        break;
      }
      case 'clientNumber': {
        this.dataSource.data = this.dataSource.data.sort((a, b) => {
          const numberA = a.person.getScreenNumber().toLowerCase().trim();
          const numberB = b.person.getScreenNumber().toLowerCase().trim();
          return direction === 'asc'
            ? numberA.localeCompare(numberB) || 0
            : numberB.localeCompare(numberA) || 0;
        });
        break;
      }
    }
  }

  createMandateClick() {
    this.clientsApiService.selectedClients$.next(this.selectedClients.selected);
    this.router.navigate(['create-mandate']);
  }

  clientLimitExceeded(): boolean {
    return this.selectedClients.selected.length > this.MAX_SELECTABLE_ROWS;
  }
}

interface ClientRowData {
  id: string;
  type: PersonType;
  name: string;
  number: string;
  mandates: string;
  actions: any;
}
