<article>
  <h1>{{ 'COMMON.MANDATES' | translate }}</h1>
  <mat-tab-group
    selectedIndex="0"
    class="custom-tabs"
    (selectedTabChange)="selectedIndex = $event.index"
  >
    <mat-tab [label]="'MANDATES.ALL' | translate">
      <mandates-base-filter [filters]="filters" [form]="filterForm">
        <button button mat-flat-button (click)="onExportClick()">
          <mat-icon>file_download</mat-icon>
          {{ 'BUTTON.EXPORT-MANDATES' | translate }}
        </button>
      </mandates-base-filter>
      <mandates-mandates-list
        [status]="status"
        [filterForm]="filterForm"
        [selection]="mandateSelection"
      ></mandates-mandates-list>
    </mat-tab>
    <mat-tab [label]="'COMMON.CONCEPTS' | translate">
      <mandates-mandates-concept-list
        [selection]="conceptSelection"
      ></mandates-mandates-concept-list>
    </mat-tab>
    <mat-tab disabled>
      <ng-template mat-tab-label>
        <ng-container *ngIf="!tabHasSelection(); else bulkActions">
          <button
            mat-raised-button
            color="secondary"
            class="rounded-button import-mandates-button"
            (click)="onImportClick()"
          >
            <mat-icon>upload</mat-icon>
            {{ 'MANDATES.IMPORT.TITLE' | translate }}
          </button>
          <button
            mat-raised-button
            color="primary"
            class="rounded-button create-mandate-btn"
            (click)="onCreateClick()"
          >
            <mat-icon>add_circle_outline</mat-icon>
            {{ 'MANDATES.NEW' | translate }}
          </button>
        </ng-container>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</article>

<ng-template #bulkActions>
  <div class="bulk-actions">
    <ng-container *ngIf="selectedIndex === 0">
      <button
        mat-raised-button
        color="warn"
        (click)="archiveMandates()"
        class="rounded-button archive-bulk-btn"
        [disabled]="!canArchiveSelectedMandates()"
      >
        <span class="button-wrapper">
          <mat-icon>archive</mat-icon>
          {{ 'BUTTON.ARCHIVE' | translate }}
        </span>
      </button>
    </ng-container>
    <ng-container *ngIf="selectedIndex === 1">
      <button
        mat-raised-button
        color="warn"
        (click)="terminateConcepts()"
        class="rounded-button delete-bulk-btn"
      >
        <span class="button-wrapper">
          <mat-icon>delete_forever</mat-icon>
          {{ 'BUTTON.DELETE-SELECTED' | translate }}
        </span>
      </button>
    </ng-container>
  </div>
</ng-template>
