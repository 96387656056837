import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/service/language.service';
import NaturalPerson from '../../../service/model/NaturalPerson';

@Component({
  selector: 'mandates-natural-person-detail',
  templateUrl: './natural-person-detail.component.html',
  styleUrls: ['./natural-person-detail.component.scss'],
})
export class NaturalPersonDetailComponent implements OnInit {
  @Input() person: NaturalPerson;

  constructor(
    private languageService: LanguageService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {}

  get country(): string | undefined {
    return this.person.address?.country
      ? this.languageService.getCountryName(this.person.address.country)
      : undefined;
  }

  get nationality(): string | undefined {
    return this.person.nationality
      ? this.languageService.getNationalityName(this.person.nationality)
      : undefined;
  }

  getFormatDate(date: Date): string {
    return formatDate(date, 'dd/MM/YYYY', 'en_GB');
  }

  get baseInfo(): { name: string; value: string }[] {
    const { nationality, nationalNumber, firstName, lastName, language } = this.person;

    return [
      { name: 'COMMON.NATIONALITY', value: nationality ? nationality : '-' },
      { name: 'COMMON.NATIONAL-NUMBER', value: nationalNumber },
      { name: 'COMMON.FIRST-NAME', value: firstName },
      { name: 'COMMON.LAST-NAME', value: lastName },
      {
        name: 'COMMON.LANGUAGE',
        value: this.translateService.instant('LANGUAGE.' + language),
      },
    ];
  }

  get address(): { name: string; value: string }[] {
    const { zipCode, street, city, streetNumber, box } = this.person.address || {};

    return [
      { name: 'COMMON.COUNTRY', value: this.country ? this.country : '-' },
      { name: 'COMMON.CITY', value: city ? city : '-' },
      { name: 'COMMON.POSTAL-CODE', value: zipCode ? zipCode : '-' },
      { name: 'COMMON.STREET', value: street ? street : '-' },
      { name: 'COMMON.STREET-NUMBER', value: streetNumber ? streetNumber : '-' },
      { name: 'COMMON.BUS-NUMBER', value: box ? box : '-' },
    ];
  }

  get vatInfo(): { name: string; value: string }[] {
    const { vatNumber, isVatObligated } = this.person;
    return [
      { name: 'COMMON.VAT-NUMBER', value: vatNumber ? vatNumber : '-' },
      {
        name: 'COMMON.VAT-LIABLE',
        value: isVatObligated ? 'FORM-FIELD.YES' : 'FORM-FIELD.NO',
      },
    ];
  }

  get sections() {
    return [
      { title: 'COMMON.GENERAL-INFO', list: this.baseInfo },
      { title: 'COMMON.ADDRESS', list: this.address },
      { title: 'COMMON.COMPANY-INFO', list: this.vatInfo },
    ];
  }
}
