import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthService } from '../authentication/auth.service';

@Injectable()
export class CustomHeaderInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes(environment.baseUrl)) {
      if (this.authService.userInfo) {
        req = req.clone({
          headers: req.headers
            .set('Employee-Id', this.setEmployeeIdHeader(req.headers))
            .set('Office-Id', this.authService.userInfo.officeId),
        });
      }
    }

    return next.handle(req);
  }

  private setEmployeeIdHeader(headers: HttpHeaders): string {
    // console.log(`id is ${headers.get('Employee-Id') ?? this.authService.userInfo!!.employeeId}`);

    return headers.get('Employee-Id') ?? this.authService.userInfo!!.employeeId;
  }
}
