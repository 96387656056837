import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PersonCommand } from '../../service/dto/PersonCommand';
import Language from '../../service/model/Language';
import NaturalPerson from '../../service/model/NaturalPerson';
import PersonType from '../../service/model/PersonType';
import { CustomValidator } from '../../shared/validators/custom.validator';
import { AddressForm } from './address-form';

export default class NaturalPersonForm {
  addressForm = new AddressForm();

  form = new UntypedFormGroup({
    person: new UntypedFormGroup({
      nationality: new UntypedFormControl('BE', [Validators.required]),
      nationalNumber: new UntypedFormControl('', [
        Validators.required,
        CustomValidator.notEmpty,
        CustomValidator.validBelgianNationalNumber,
      ]),
      firstName: new UntypedFormControl('', [Validators.required, CustomValidator.notEmpty]),
      lastName: new UntypedFormControl('', [Validators.required, CustomValidator.notEmpty]),
      language: new FormControl(Language.MULTI, Validators.required),
    }),
    address: this.addressForm.form,
    vat: new UntypedFormGroup({
      vatLiable: new UntypedFormControl(false, [Validators.required]),
      companyNumber: new UntypedFormControl('', []),
      vatNumber: new UntypedFormControl('', [CustomValidator.validVat]),
      inBelgium: new UntypedFormControl({ value: false, disabled: true }, [Validators.required]),
      multipleRegions: new UntypedFormControl({ value: [], disabled: true }),
    }),
  });

  initFromNaturalPerson(person: NaturalPerson) {
    this.form.patchValue({
      person: {
        nationality: person.nationality,
        nationalNumber: person.nationalNumber,
        firstName: person.firstName,
        lastName: person.lastName,
        language: person.language,
      },
      address: this.addressForm.initFromAddress(person.address),
      vat: {
        vatLiable: person.isVatObligated,
        companyNumber: person.companyNumber,
        vatNumber: person.vatNumber,
        inBelgium: person.hasPermanentEstablishment,
        multipleRegions: person.activeRegions,
      },
    });
  }

  toPersonDto(): PersonCommand {
    const { person, vat } = this.form.getRawValue();

    const { nationality, nationalNumber, firstName, lastName, birthdate, language } = person;
    const { vatLiable, companyNumber, vatNumber, inBelgium, multipleRegions } = vat;

    return {
      personType: PersonType.NATURAL_PERSON,
      naturalPerson: {
        nationality,
        nationalNumber,
        firstName,
        lastName,
        birthDate: birthdate,
        companyNumber,
      },
      moralPerson: undefined,
      isVatObligated: vatLiable,
      vatNumber: vatNumber?.trim()?.length ? vatNumber : '',
      hasPermanentEstablishment: inBelgium != undefined ? inBelgium : false,
      activeRegions: multipleRegions,
      address: this.addressForm.toAddress(),
      language: language,
    };
  }
}
