import { mapPerson } from '../../core/clients-api/clients-api-mapper';
import EngagementType from './EngagementType';
import MoralPerson from './MoralPerson';
import NaturalPerson from './NaturalPerson';

export default class Client {
  id: string;
  person: MoralPerson | NaturalPerson;
  engagementType: EngagementType;
  createdBy: string;

  constructor({
    id,
    person,
    engagementType,
    createdBy,
  }: {
    id: string;
    person: MoralPerson | NaturalPerson;
    engagementType: EngagementType;
    createdBy: string;
  }) {
    this.id = id;
    this.person = mapPerson(person);
    this.engagementType = engagementType;
    this.createdBy = createdBy;
  }
}
