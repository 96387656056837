import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { MandatesStatusFilterPipe } from '../core/pipes/mandates-status-filter.pipe';
import { MaterialModule } from '../material.module';
import { BaseDialog } from './components/base-dialog/base-dialog';
import { BaseFilterComponent } from './components/base-filter/base-filter.component';
import { ClientCardComponent } from './components/client-card/client-card.component';
import { ClientIconComponent } from './components/client-icon/client-icon.component';
import { InputFieldComponent } from './components/input-field/input-field.component';
import { MainToolbarComponent } from './components/main-toolbar/main-toolbar.component';
import { MandateSignerCardComponent } from './components/mandate-signer-card/mandate-signer-card.component';
import { ReturnButtonComponent } from './components/return-button/return-button.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { FilterButtonFormComponent } from './components/base-filter/filter-button-form/filter-button-form.component';
import { DropzoneUploadComponent } from './components/dropzone-upload/dropzone-upload.component';
import { OutsideClickDirective } from '../core/directives/outside-click.directive';
import { TableComponent } from './components/table/table.component';

@NgModule({
  declarations: [
    SidenavComponent,
    MainToolbarComponent,
    BaseDialog,
    InputFieldComponent,
    ClientIconComponent,
    ReturnButtonComponent,
    ClientCardComponent,
    MandatesStatusFilterPipe,
    MandateSignerCardComponent,
    BaseFilterComponent,
    FilterButtonFormComponent,
    DropzoneUploadComponent,
    OutsideClickDirective,
    TableComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    LayoutModule,
    RouterModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    DigitOnlyModule,
  ],
  exports: [
    MaterialModule,
    SidenavComponent,
    TranslateModule,
    InputFieldComponent,
    ClientIconComponent,
    ReturnButtonComponent,
    ClientCardComponent,
    MandatesStatusFilterPipe,
    FormsModule,
    ReactiveFormsModule,
    MandateSignerCardComponent,
    BaseFilterComponent,
    DropzoneUploadComponent,
    OutsideClickDirective,
    TableComponent,
  ],
})
export class SharedModule {}
