import MoralPerson from '../../service/model/MoralPerson';
import NaturalPerson from '../../service/model/NaturalPerson';
import PersonType from '../../service/model/PersonType';

export function mapPerson(person: any): MoralPerson | NaturalPerson {
  if (person.type === PersonType.NATURAL_PERSON) {
    return new NaturalPerson({ ...person });
  } else {
    return new MoralPerson({ ...person });
  }
}
