<section class="select-all-btn-container" *ngIf="!isSelectingAll && hasSelectedAllOfCurrentPage()">
  <span>{{
    'TABLE.ALL-COUNT-OF-PAGE-SELECTED'
      | translate
        : {
            count: selection.selected.length,
            items: 'COMMON.CONCEPTS' | translate | lowercase
          }
  }}</span>
  <span class="clickable" (click)="fetchAll()">
    {{
      'TABLE.SELECT-ALL-COUNT-THAT-MATCH'
        | translate
          : {
              count: page.totalElements,
              items: 'COMMON.CONCEPTS' | translate | lowercase
            }
    }}
  </span>
</section>
<mandates-table
  [displayedColumns]="displayedColumns"
  [pageSizeOptions]="[10, 20]"
  [dataSource]="dataSource"
  [selection]="selection"
  uuidRef="id"
  (pageChange)="handlePageEvent($event)"
  [totalAvailable]="totalAvailableRows"
  [elementDisplayName]="'COMMON.MANDATES' | translate"
  [page]="page"
  [color]="undefined"
  [showFirstLastButtons]="true"
  [isSelectingAll]="isSelectingAll"
  matSort
  (matSortChange)="handleSortChange($event)"
>
  <!-- ClientType Column -->
  <ng-container matColumnDef="clientType">
    <th mat-header-cell *matHeaderCellDef matTooltip="{{ 'LIST.CLIENTTYPE' | translate }}">
      {{ 'LIST.TYPE' | translate }}
    </th>
    <td mat-cell *matCellDef="let row">
      <mandates-client-icon [type]="row.concept?.clientType"></mandates-client-icon>
    </td>
  </ng-container>
  <ng-container matColumnDef="clientName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'LIST.NAME' | translate }}
    </th>
    <td mat-cell *matCellDef="let row">{{ getClientName(row) }}</td>
  </ng-container>

  <!-- Last Opened Column -->
  <ng-container matColumnDef="lastOpened">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="modifiedDate">
      {{ 'LIST.LAST-OPENED' | translate }}
    </th>
    <td mat-cell *matCellDef="let row">
      {{ row.modifiedDate | date : 'dd/MM/yyyy' }}
    </td>
  </ng-container>

  <!-- Status Column -->
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'LIST.STATUS' | translate }}
    </th>
    <td
      mat-cell
      *matCellDef="let row"
      class="status-row"
      [ngClass]="{
        sent: row.status === 'SENT',
        error: row.status === 'ERROR',
        active: row.status === 'ACTIVE',
        awaiting_approval: row.status === 'AWAITING_APPROVAL',
        terminated: row.status === 'TERMINATED',
        declined: row.status === 'DECLINED'
      }"
    >
      <span class="status-info">
        <mat-icon inline>radio_button_checked</mat-icon>
        {{ 'MANDATES.DRAFT' | translate }}
      </span>
    </td>
  </ng-container>

  <!-- Actions Column -->
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'LIST.ACTIONS' | translate }}
    </th>
    <td mat-cell *matCellDef="let row">
      <div class="action-buttons">
        <button mat-icon-button (click)="onEditClick(row)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button class="delete-btn" color="warn" (click)="onDeleteClick(row)">
          <mat-icon> delete_forever </mat-icon>
        </button>
      </div>
    </td>
  </ng-container>
</mandates-table>
