<header>
  <mandates-return-button (click)="navigateAway()"></mandates-return-button>
  <h1>
    {{
      !isEdit ? ('HEADING.CREATE-NEW-MANDATE' | translate) : ('HEADING.UPDATE-MANDATE' | translate)
    }}
  </h1>
  <p>
    {{ 'COMMON.FILL-IN' | translate }}
  </p>
</header>

<article class="create-article">
  <section class="article-body">
    <mat-tab-group
      *ngIf="!isSelectingBulkClients"
      [selectedIndex]="selectedTabNumber"
      (selectedTabChange)="onTabChange($event)"
      class="custom-tabs create-mandate-tabs"
    >
      <mat-tab [label]="'PERSON.NATURAL' | translate">
        <div class="tab-body">
          <ng-template
            ngFor
            [ngForOf]="naturalClientsFromBulkForm"
            [ngForTemplate]="selectedClients"
          ></ng-template>

          <mat-divider
            class="sub-divider"
            *ngIf="naturalClientsFromBulkForm.length > 0"
          ></mat-divider>

          <ng-container [ngTemplateOutlet]="add_bulk_client"></ng-container>

          <ng-container *ngIf="naturalClientsFromBulkForm.length > 0">
            <mat-divider class="sub-divider"></mat-divider>

            <section class="mandate-types">
              <h2>{{ 'MANDATES.SELECT' | translate }}</h2>
              <div>
                <mandates-mandate-type-card
                  *ngFor="let type of naturalMandateTypes"
                  [mandateType]="type"
                  (click)="onNaturalMandateTypeClick(type.id)"
                ></mandates-mandate-type-card>
              </div>
            </section>

            <mat-divider class="sub-divider"></mat-divider>

            <section
              class="form-section"
              *ngIf="
                naturalClientsFromBulkForm.length > 0 && naturalMandatesBulkForm.length > 0;
                else noBulkMandateTypesSelected
              "
            >
              <h2>
                {{ 'COMMON.FILL-IN' | translate }}
              </h2>
              <mat-tab-group class="custom-tabs">
                <mat-tab>
                  <ng-template mat-tab-label>
                    <div>
                      <span>{{ 'COMMON.ALL' | translate }}</span>
                    </div>
                  </ng-template>
                  <mandates-mandate-information-form
                    [parentForm]="allMandatesNaturalForm"
                  ></mandates-mandate-information-form>
                </mat-tab>
                <mat-tab *ngFor="let form of naturalMandatesBulkFormGroups">
                  <ng-template mat-tab-label *ngIf="form.get('mandateTypeName')?.value">
                    <div>
                      <span [class.isInvalid]="form.invalid && form.touched">
                        {{ 'MANDATES.TYPE.' + form.get('mandateTypeName')?.value | translate }}
                      </span>
                      <span class="isInvalid">*</span>
                    </div>
                  </ng-template>
                  <mandates-mandate-information-form
                    [parentForm]="form"
                  ></mandates-mandate-information-form>
                </mat-tab>
              </mat-tab-group>
            </section>
          </ng-container>
        </div>
      </mat-tab>
      <mat-tab [label]="'PERSON.MORAL' | translate">
        <div class="tab-body">
          <ng-template
            ngFor
            [ngForOf]="moralClientsFromBulkForm"
            [ngForTemplate]="selectedClients"
          ></ng-template>

          <mat-divider
            class="sub-divider"
            *ngIf="moralClientsFromBulkForm.length > 0"
          ></mat-divider>

          <ng-container [ngTemplateOutlet]="add_bulk_client"></ng-container>

          <ng-container *ngIf="moralClientsFromBulkForm.length > 0">
            <mat-divider class="sub-divider"></mat-divider>

            <section class="mandate-types" *ngIf="moralClientsFromBulkForm.length > 0">
              <h2>{{ 'MANDATES.SELECT' | translate }}</h2>
              <div>
                <mandates-mandate-type-card
                  *ngFor="let type of moralMandateTypes"
                  [mandateType]="type"
                  (click)="onMoralMandateTypeClick(type.id)"
                ></mandates-mandate-type-card>
              </div>
            </section>

            <mat-divider class="sub-divider"></mat-divider>

            <section
              class="form-section"
              *ngIf="
                moralClientsFromBulkForm.length > 0 && moralMandatesBulkForm.length > 0;
                else noBulkMandateTypesSelected
              "
            >
              <h2>
                {{ 'COMMON.FILL-IN' | translate }}
              </h2>
              <mat-tab-group class="custom-tabs">
                <mat-tab>
                  <ng-template mat-tab-label>
                    <div>
                      <span>{{ 'COMMON.ALL' | translate }}</span>
                    </div>
                  </ng-template>
                  <mandates-mandate-information-form
                    [parentForm]="allMandatesMoralForm"
                  ></mandates-mandate-information-form>
                </mat-tab>
                <mat-tab *ngFor="let form of moralMandatesBulkFormGroups">
                  <ng-template mat-tab-label>
                    <div>
                      <span [class.isInvalid]="form.invalid && form.touched">
                        {{ 'MANDATES.TYPE.' + form.get('mandateTypeName')?.value | translate }}
                      </span>
                      <span class="isInvalid">*</span>
                    </div>
                  </ng-template>
                  <mandates-mandate-information-form
                    [parentForm]="form"
                  ></mandates-mandate-information-form>
                </mat-tab>
              </mat-tab-group>
            </section>
          </ng-container>
        </div>
      </mat-tab>
    </mat-tab-group>

    <ng-container *ngIf="isSelectingBulkClients">
      <mandates-mandate-client-search
        (onClientSelected)="handleBulkClientSelected($event)"
        [defaultPersonType]="selectedTabPersonType"
      ></mandates-mandate-client-search>
    </ng-container>
  </section>

  <section class="submit-section">
    <button mat-raised-button (click)="cancel()" color="warn">
      <mat-icon>cancel</mat-icon>
      {{ 'BUTTON.CANCEL' | translate }}
    </button>
    <button
      mat-raised-button
      (click)="saveBulk()"
      color="accent"
      [disabled]="!canSaveBulk() || signerFormIsOpen"
    >
      {{ 'BUTTON.SAVE-AS-CONCEPT' | translate }}
      <mat-icon>edit</mat-icon>
    </button>

    <button
      mat-raised-button
      (click)="publishBulk()"
      [disabled]="!canPublishBulk() || signerFormIsOpen"
      [class.enable-publish-btn]="canPublishBulk()"
    >
      {{ 'BUTTON.SEND' | translate }}
      <mat-icon>send</mat-icon>
    </button>
  </section>
</article>

<ng-template #selectedClients let-form>
  <div class="selected-client-container">
    <button mat-mini-fab color="warn" (click)="removeBulkClientFormGroup(form)">
      <mat-icon>close</mat-icon>
    </button>
    <section class="client-section">
      <h2>{{ 'MANDATES.SELECTED-CLIENT' | translate }}</h2>
      <div>
        <mandates-client-card [client]="form.controls.client.value"></mandates-client-card>
      </div>
    </section>

    <section class="mandate-signer-section">
      <h2>{{ bulkMandateSignerHeading(form.controls.mandateSigner.value) | translate }}</h2>
      <div *ngIf="form.controls.mandateSigner.value" class="selected-mandate-signer">
        <button mat-mini-fab color="warn" (click)="removeMandateSignerFromBulkClient(form)">
          <mat-icon>close</mat-icon>
        </button>
        <mandates-mandate-signer-card
          [signer]="form.controls.mandateSigner.value"
          [isDark]="false"
        ></mandates-mandate-signer-card>
      </div>
      <mandates-mandate-signer-form
        [clientId]="form.controls.client.value.id"
        (signerSaved)="addMandateSignerToBulkClient(form, $event)"
        *ngIf="!form.controls.mandateSigner.value"
        [naturalPerson]="getNaturalPersonFromBulkClientForm(form)"
        (onToggle)="handleOnToggle($event)"
      ></mandates-mandate-signer-form>
    </section>
  </div>
</ng-template>

<ng-template #noBulkMandateTypesSelected>
  <section class="no-mandates-selected">
    {{ 'MANDATES.SELECT-MANDATE-FIRST' | translate }}
  </section>
</ng-template>

<ng-template #add_bulk_client>
  <section class="add-bulk-client">
    <button
      mat-button
      class="add-bulk-client-btn"
      color="accent"
      (click)="selectBulkClients()"
      [disabled]="clientLimitExceeded()"
      matTooltip="{{
        clientLimitExceeded() ? ('TOOLTIP.CLIENT-LIMIT-EXCEEDED' | translate) : null
      }}"
    >
      <mat-icon>add_circle</mat-icon>
      <span>{{ 'BUTTON.ADD-CLIENT' | translate }}</span>
    </button>
  </section>
</ng-template>
