import Address from './Address';
import Language from './Language';
import Person from './Person';
import PersonType from './PersonType';
import Region from './Region';

export default class NaturalPerson implements Person {
  id: string | undefined;
  type: PersonType;

  isVatObligated: boolean;
  vatNumber: string | null;
  hasPermanentEstablishment: boolean;
  activeRegions: Region[];
  address?: Address;

  nationality: string;
  nationalNumber: string;
  firstName: string;
  lastName: string;
  birthDate: Date | null;
  companyNumber: string;
  language: Language;

  constructor({
    id,
    type,
    isVatObligated,
    vatNumber,
    hasPermanentEstablishment,
    activeRegions,
    address,
    nationality,
    nationalNumber,
    firstName,
    lastName,
    birthDate,
    companyNumber,
    language,
  }: {
    id: string | undefined;
    type: PersonType;
    isVatObligated: boolean;
    vatNumber: string | null;
    hasPermanentEstablishment: boolean;
    activeRegions: Region[];
    address: Address;
    nationality: string;
    nationalNumber: string;
    firstName: string;
    lastName: string;
    birthDate: Date | null;
    companyNumber: string;
    language: Language;
  }) {
    this.id = id;
    this.type = type;
    this.isVatObligated = isVatObligated;
    this.vatNumber = vatNumber;
    this.hasPermanentEstablishment = hasPermanentEstablishment;
    this.activeRegions = activeRegions;
    this.address = new Address({ ...address });
    this.nationality = nationality;
    this.nationalNumber = nationalNumber;
    this.firstName = firstName;
    this.lastName = lastName;
    this.birthDate = birthDate;
    this.companyNumber = companyNumber;
    this.language = language;
  }

  getScreenName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  getScreenNumber(): string {
    return this.nationalNumber;
  }

  getPersonType(): PersonType {
    return PersonType.NATURAL_PERSON;
  }
}
