<h2 mat-dialog-title>{{ data.title | translate }}</h2>
<div mat-dialog-content>
  <p>{{ data.description | translate }}</p>
  <p>{{ data.paragraph | translate }}</p>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="onCancelClick()">{{ data.cancelText | translate }}</button>
  <button mat-raised-button [color]="data.confirmColor" (click)="onConfirmClick()">
    {{ data.confirmText | translate }}
  </button>
</div>
