import { Component, OnInit } from '@angular/core';
import { EmployeesApiService } from '../core/employees-api/employees-api.service';
import Employee from '../service/model/Employee';
import EmploymentRoleType from '../service/model/EmploymentRoleType';

@Component({
  selector: 'mandates-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss'],
})
export class EmployeesComponent implements OnInit {
  employees: Employee[];
  admins: Employee[];
  coworker: Employee[];

  constructor(private employeesApiService: EmployeesApiService) {
    this.employeesApiService.getEmployees().subscribe();
  }

  ngOnInit(): void {
    this.employeesApiService.employees$.subscribe((employees) => {
      this.employees = employees;
      this.admins = employees.filter((e) => e.role === EmploymentRoleType.ADMIN);
      this.coworker = employees.filter((e) => e.role === EmploymentRoleType.COWORKER);
    });
  }
}
