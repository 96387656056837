<div class="kanban-content-mandates">
  <div class="kanban-item">
    <div class="kanban-item-label">{{ 'LIST.TYPE' | translate }}</div>
    <div class="kanban-item-data">{{ 'MANDATES.TYPE.' + mandate?.mandateTypes | translate }}</div>
  </div>
  <div class="kanban-item">
    <div class="kanban-item-label">{{ 'COMMON.STATUS' | translate }}</div>
    <div
      class="kanban-item-data"
      [ngClass]="{
        sent: mandate?.status?.toString() === 'SENT',
        error: mandate?.status?.toString() === 'ERROR',
        active: mandate?.status?.toString() === 'ACTIVE',
        awaiting_approval: mandate?.status?.toString() === 'AWAITING_APPROVAL',
        terminated: mandate?.status?.toString() === 'TERMINATED',
        declined: mandate?.status?.toString() === 'DECLINED'
      }"
    >
      {{ 'MANDATES.' + mandate?.status?.toString() | translate }}
    </div>
  </div>
  <div class="kanban-item">
    <div class="kanban-item-label">{{ 'COMMON.EMPLOYEE' | translate }}</div>
    <div class="kanban-item-data">{{ mandate?.modifiedByName }}</div>
  </div>
  <div
    class="kanban-item"
    *ngIf="
      mandate?.status?.toString() === 'SENT' ||
      mandate?.status?.toString() === 'AWAITING_APPROVAL' ||
      mandate?.status?.toString() === 'ERROR'
    "
  >
    <div class="kanban-item-label">{{ 'COMMON.REQUEST-DATE' | translate }}</div>
    <div class="kanban-item-data">{{ mandate?.startDate | date: 'dd-MM-yyyy' }}</div>
  </div>
  <div class="kanban-item" *ngIf="mandate?.status?.toString() === 'DECLINED'">
    <div class="kanban-item-label">{{ 'COMMON.REJECTION-DATE' | translate }}</div>
    <div class="kanban-item-data">{{ mandate?.lastOpened | date: 'dd-MM-yyyy' }}</div>
  </div>
  <div class="kanban-item" *ngIf="mandate?.status?.toString() === 'ACTIVE'">
    <div class="kanban-item-label">{{ 'COMMON.ACTIVE-SINCE' | translate }}</div>
    <div class="kanban-item-data">{{ mandate?.startDate | date: 'dd-MM-yyyy' }}</div>
  </div>
  <div class="kanban-item" *ngIf="mandate?.status?.toString() === 'ACTIVE'">
    <div class="kanban-item-label">{{ 'COMMON.EXP-DATE' | translate }}</div>
    <div class="kanban-item-data">{{ mandate?.endDate | date: 'dd-MM-yyyy' }}</div>
  </div>
  <div class="kanban-item" *ngIf="mandate?.status?.toString() === 'TERMINATED'">
    <div class="kanban-item-label">{{ 'COMMON.END-DATE' | translate }}</div>
    <div class="kanban-item-data">{{ mandate?.endDate | date: 'dd-MM-yyyy' }}</div>
  </div>
</div>
<div class="kanban-action">
  <button mat-flat-button class="kanban-action-buttons" (click)="onInfoClick()">
    <mat-icon>info</mat-icon>
  </button>
  <span
    *ngIf="
      mandate?.status?.toString() === 'AWAITING_APPROVAL' ||
      mandate?.status?.toString() === 'ACTIVE'
    "
    [matTooltip]="getTooltip()"
    class="kanban-action-buttons"
  >
    <button
      class="kanban-action-buttons"
      [disabled]="!mandate?.mandateSigner"
      mat-flat-button
      (click)="onDeleteClick()"
    >
      <mat-icon>delete_forever</mat-icon>
    </button>
  </span>
  <button
    mat-flat-button
    class="kanban-action-buttons"
    (click)="onArchiveClick()"
    *ngIf="canArchive()"
  >
    <mat-icon>archive</mat-icon>
  </button>
</div>
