const baseUrl = 'https://acc.mandates.itaa.be';

export const environment = {
  production: false,
  baseUrl: baseUrl,
  backendUrl: `${baseUrl}/external-api`,
  itaaPortalUrl: 'https://portal-test.itaa.be',
  userInfoFixture: {
    employeeId: 'a0091832-ca1f-49fe-9a28-cf1ec8aea5ed',
    officeId: 'fce631c0-a135-44c9-9d1e-4817a6c0b467',
    role: 'ADMIN',
  },
  oidc: {
    clientId: 'royTjL4S4sYirsQBsNYhj3BjiJRkHP2P',
    issuer: 'https://sso-acc.itaa.be/',
    audience: 'https://acc.mandates.itaa.be/',
  },
  manual: {
    nl: 'https://itaa.be/documents/mandaten/Handleiding%20ITAA%20mandaat.pdf',
    fr: 'https://itaa.be/documents/mandats/Manuel%20mandat%20ITAA.pdf',
  },
  faq: {
    nl: 'https://itaa-servicedesk.freshdesk.com/nl/support/solutions/folders/80000705133',
    fr: 'https://itaa-servicedesk.freshdesk.com/fr/support/solutions/folders/80000705133',
  },
};
