import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentCanDeactivate } from 'src/app/core/guards/can-deactivate.guard';
import PersonType from '../../service/model/PersonType';

@Component({
  selector: 'mandates-create-client',
  templateUrl: './create-client.component.html',
  styleUrls: ['./create-client.component.scss'],
})
export class CreateClientComponent implements OnInit, ComponentCanDeactivate {
  _personType: PersonType;
  PersonType = PersonType;
  componentShouldDeactivate: boolean = false;
  constructor(private router: Router) {}

  canDeactivate() {
    return {
      shouldDeactivate: this.componentShouldDeactivate,
      data: {
        title: 'DIALOG.CANCEL-FORM.DESCRIPTION-CLIENT-CREATE',
        description: '',
        cancelText: 'DIALOG.NO-CONTINUE',
        confirmText: 'DIALOG.YES-CANCEL',
        confirmColor: 'accent',
      },
    };
  }

  ngOnInit(): void {}

  onPersonButton(person: PersonType) {
    this.selectedPerson = person;
  }

  get selectedPerson() {
    return this._personType;
  }

  set selectedPerson(person: PersonType) {
    this._personType = person;
  }

  onFormSaved(client: any) {
    this.componentShouldDeactivate = true;
    if (client.id) {
      this.router.navigate(['clients', client.id]);
    } else {
      this.router.navigate(['..']);
    }
  }
}
