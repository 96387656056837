import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'mandates-import-mandates-loading',
  templateUrl: './import-mandates-loading.component.html',
  styleUrls: ['./import-mandates-loading.component.scss'],
})
export class ImportMandatesLoadingComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ImportMandatesLoadingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {}
}

interface DialogData {}
