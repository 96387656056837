import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as i18nIsoCountries from 'i18n-iso-countries';
import * as i18nNationality from 'i18n-nationality';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private language: string | undefined;

  constructor(private translate: TranslateService) {
    translate.addLangs(['nl', 'fr']);
  }

  getBrowserLanguage(): string {
    this.language = this.translate.getBrowserLang();
    if (this.language !== 'nl' && this.language !== 'fr') {
      this.language = 'nl';
    }
    return this.language;
  }

  use(language: string): void {
    if (!language) {
      language = 'nl';
    }
    this.translate.setDefaultLang(language.toLowerCase());
    this.translate.use(language.toLowerCase());
  }

  getCountryFormat(language = 'NL', country = 'BE'): string {
    return `${language.toLowerCase()}-${country.toUpperCase()}`;
  }

  getCountryName(country: string): string {
    return i18nIsoCountries.getName(country.toUpperCase(), this.translate.getDefaultLang());
  }

  getNationalityName(country: string): string {
    return i18nNationality.getName(country.toUpperCase(), this.translate.getDefaultLang());
  }

  getLanguage(): string {
    return this.translate.getDefaultLang().toLowerCase();
  }

  getLocale(): string {
    return `${this.translate.getDefaultLang().toLowerCase()}-BE`;
  }

  getDeleteKeyword(): string {
    return this.translate.instant('DIALOG.TERMINATE.TERMINATE-VALUE');
  }
}
