<header>
  <div class="title-container">
    <mandates-return-button (click)="navigateBack()"></mandates-return-button>
    <h1>
      {{ 'MANDATES.IMPORT.TITLE' | translate }}
    </h1>
  </div>
</header>
<ng-container *ngIf="mandateImports$ | async as mandateImports; else upload">
  <article class="list">
    <ng-container *ngIf="validMandateImportRows.length > 0; else noValidImports">
      <p>{{ 'MANDATES.IMPORT.TITLE-3' | translate : { number: validMandateImportRows.length } }}</p>
      <mandates-import-mandates-list
        [rows]="validMandateImportRows"
      ></mandates-import-mandates-list>
    </ng-container>
    <ng-template #noValidImports>
      <h2>{{ 'MANDATES.IMPORT.EMPTY' | translate }}</h2>
    </ng-template>
  </article>
</ng-container>
<ng-template #upload>
  <article>
    <section class="card">
      <p>{{ 'MANDATES.IMPORT.PLATFORMS' | translate }}</p>
      <ng-container *ngFor="let platform of platformOptions">
        <button mat-flat-button class="platform-button" disabled>
          {{ platform | translate }}
        </button>
      </ng-container>
    </section>
    <section class="card">
      <p>{{ 'UPLOAD.TITLE' | translate }}</p>
      <p style="color: #909090">
        {{ 'UPLOAD.ALLOWED-EXTENSIONS' | translate }}{{ allowedExtensionsFormatted }}
      </p>
      <mandates-dropzone-upload
        [allowedExtensions]="allowedExtensions"
        (fileSelected)="setFile($event)"
      ></mandates-dropzone-upload>
    </section>
  </article>
</ng-template>

<section class="actions">
  <ng-container *ngIf="mandateImports$ | async; else nextButton">
    <button mat-raised-button color="accent" (click)="back()">
      <mat-icon>chevron_left</mat-icon>
      {{ 'BUTTON.BACK' | translate }}
    </button>
    <button
      mat-raised-button
      (click)="onImportClick()"
      [ngClass]="invalidMandateImportForms.length === 0 ? 'green' : 'orange'"
    >
      <mat-icon>upload</mat-icon>
      {{ 'BUTTON.IMPORT' | translate }}
    </button>
  </ng-container>
  <ng-template #nextButton>
    <button mat-raised-button color="warn" (click)="navigateBack()">
      <mat-icon>cancel</mat-icon>
      {{ 'BUTTON.CANCEL' | translate }}
    </button>
    <button mat-raised-button [disabled]="!file" (click)="next()" class="green">
      {{ 'BUTTON.NEXT' | translate }}
      <mat-icon>chevron_right</mat-icon>
    </button>
  </ng-template>
</section>
