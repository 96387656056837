import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { authConfig } from '../../config/oidc-config';
import { UserInfo } from './UserInfo';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  protected baseUrl: string = environment.backendUrl;

  isAuthenticated$ = new BehaviorSubject<boolean>(false);
  userInfo$ = new BehaviorSubject<UserInfo | undefined>(undefined);

  constructor(
    private oauthService: OAuthService,
    private http: HttpClient
  ) {}

  async login() {
    try {
      authConfig.redirectUri =
        authConfig.redirectUri + `?organizationId=${this.organizationId}&roles=${this.roles}`;
      this.oauthService.configure(authConfig);
      const result = await this.oauthService.loadDiscoveryDocumentAndLogin();
      this.isAuthenticated$.next(result);
      this.oauthService.setupAutomaticSilentRefresh();
      this.fetchUserInfo();
    } catch (error) {
      console.error(error);
      this.oauthService.logOut();
    }
  }

  logout() {
    sessionStorage.clear();
    this.oauthService.logOut();
  }

  fetchUserInfo() {
    const headers = new HttpHeaders({
      'Organisation-Id': this.organizationId,
      Roles: this.roles,
    });
    this.http
      .get<UserInfo>(`${this.baseUrl}/user-info`, { headers: headers })
      .subscribe(this.userInfo$);
  }

  get idToken(): string | null {
    return this.oauthService.getIdToken();
  }

  get userInfo() {
    return this.userInfo$.value;
  }

  get organizationId(): string {
    return sessionStorage.getItem('organizationId') || '';
  }

  get roles(): string {
    return sessionStorage.getItem('roles') || '';
  }

  set organizationId(organizationId: string) {
    sessionStorage.setItem('organizationId', organizationId);
  }

  set roles(roles: string) {
    sessionStorage.setItem('roles', roles);
  }
}
