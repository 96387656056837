<article>
  <h2 nat-dialog-title>{{ 'DIALOG.PUBLISH-BULK-MANDATE.REQUEST-MANDATES' | translate }}</h2>

  <section mat-dialog-content>
    <mat-tab-group
      class="custom-tabs"
      [selectedIndex]="focusTab"
      mat-align-tabs="center"
      (focusChange)="focusChangeHandler($event)"
    >
      <mat-tab [label]="'PERSON.NATURAL' | translate" [disabled]="!hasNaturalClients()">
        <div class="container">
          <ng-container
            [ngTemplateOutlet]="client_signer_list"
            [ngTemplateOutletContext]="{clients:naturalClientForms}"
          ></ng-container>
          <ng-container
            [ngTemplateOutlet]="mandates_list"
            [ngTemplateOutletContext]="{mandates:naturalMandateForms}"
          ></ng-container>
        </div>
      </mat-tab>

      <mat-tab [label]="'PERSON.MORAL' | translate" [disabled]="!hasMoralClients()">
        <div class="container">
          <ng-container
            [ngTemplateOutlet]="client_signer_list"
            [ngTemplateOutletContext]="{clients:moralClientForms}"
          ></ng-container>
          <ng-container
            [ngTemplateOutlet]="mandates_list"
            [ngTemplateOutletContext]="{mandates:moralMandateForms}"
          ></ng-container>
        </div>
      </mat-tab>
    </mat-tab-group>
  </section>

  <div class="moralInfo" *ngIf="hasMoralClients()">
    <span>{{ 'DIALOG.PUBLISH-BULK-MANDATE.MORAL-CONFIRM-INFO' | translate }}</span>
  </div>

  <section mat-dialog-actions>
    <button mat-flat-button [mat-dialog-close]="false">{{ 'DIALOG.NO' | translate }}</button>
    <button mat-flat-button [mat-dialog-close]="true" color="accent">
      {{ 'DIALOG.YES' | translate }}
    </button>
  </section>
</article>

<ng-template #client_signer_list let-clients="clients">
  <div class="client-signer-container">
    <p>{{ 'DIALOG.PUBLISH-BULK-MANDATE.CLIENT-SIGNERS' | translate }}</p>
    <div class="client-signer-card-list">
      <div class="client-signer-card" *ngFor="let client of clients">
        <span class="client-label">
          <mandates-client-icon
            [type]="getPersonType(client)"
            [inline]="true"
          ></mandates-client-icon>
          {{ getClientName(client) }}
        </span>
        <mat-divider></mat-divider>
        <span class="signer-label">
          <mat-icon>draw</mat-icon>
          {{ getSignerName(client) }}
        </span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #mandates_list let-mandates="mandates">
  <div class="mandates-container">
    <p>{{ 'DIALOG.PUBLISH-BULK-MANDATE.MANDATES' | translate }}</p>
    <div class="mandate-card-list">
      <div class="mandate-card" *ngFor="let mandate of mandates">
        <span>{{ 'MANDATES.TYPE.' + getMandateName(mandate) | translate }}</span>
        <span *ngIf="hasEndDate(mandate)" class="endDate-label">
          <mat-icon>hourglass_bottom</mat-icon>
          {{ getMandateEndDate(mandate) | date: 'dd-MM-yyyy' }}
        </span>
      </div>
    </div>
  </div>
</ng-template>
