<table
  mat-table
  [dataSource]="dataSource"
  class="accent-color"
  #table
  matSort
  (matSortChange)="handleSortChange($event)"
>
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'LIST.NAME' | translate }}</th>
    <td mat-cell *matCellDef="let row" [ngClass]="{ 'admin-bold': row.role === 'ADMIN' }">
      {{ row.firstName }} {{ row.lastName }}
    </td>
  </ng-container>
  <ng-container matColumnDef="rights">
    <th mat-header-cell *matHeaderCellDef>{{ 'LIST.RIGHTS' | translate }}</th>
    <td mat-cell *matCellDef="let row" class="column-rights" (click)="$event.stopPropagation()">
      <mat-select
        [placeholder]="'LIST.RIGHTS' | translate"
        [(ngModel)]="row.role"
        class="rights-select"
        [ngClass]="{ 'admin-bold': row.role === 'ADMIN' }"
        (selectionChange)="onSelectionChange(row, $event)"
        [disabled]="userInfo?.employeeId === row.id"
      >
        <mat-option *ngFor="let role of employeeRoles" [value]="role">
          {{ 'EMPLOYEES.' + role | translate }}
        </mat-option>
      </mat-select>
    </td>
  </ng-container>
  <ng-container matColumnDef="rightsNotEditable">
    <th mat-header-cell *matHeaderCellDef>{{ 'LIST.RIGHTS' | translate }}</th>
    <td mat-cell *matCellDef="let row" [ngClass]="{ 'admin-bold': row.role === 'ADMIN' }">
      {{ 'EMPLOYEES.' + row.role | translate }}
    </td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>{{ 'LIST.ACTIONS' | translate }}</th>
    <td mat-cell *matCellDef="let row">
      <div class="action-buttons">
        <button mat-icon-button [routerLink]="['/employees/' + row.id]">
          <mat-icon>info</mat-icon>
        </button>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="actionsEngagement">
    <th mat-header-cell *matHeaderCellDef>{{ 'LIST.ACTIONS' | translate }}</th>
    <td mat-cell *matCellDef="let row">
      <div class="action-buttons" *ngIf="row.role != 'ADMIN'">
        <button mat-icon-button (click)="deletelimitedAccess(row, $event)">
          <mat-icon>cancel</mat-icon>
        </button>
      </div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    [routerLink]="['/employees/' + row.id]"
  ></tr>
</table>
<mat-paginator #paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
