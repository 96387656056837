<article>
  <h2 nat-dialog-title>{{ "DIALOG.PUBLISH-MANDATE.REQUEST-MANDATE." + clientType | translate }}</h2>

  <section mat-dialog-content>
    <div class="client-container">
      <p>{{ "DIALOG.PUBLISH-MANDATE.SELECTED-CLIENT" | translate }}</p>

      <div class="person-info">
        <h3>{{ clientName }}</h3>

        <mat-divider></mat-divider>

        <span>
          <mandates-client-icon [type]="clientType" [inline]="true"></mandates-client-icon>
          {{ "PERSON_TYPE." + clientType | translate }}
        </span>

        <span>
          <img src="assets/icons/id-card.png" />
          {{ clientNumber }}
        </span>

        <span>
          <mat-icon>place</mat-icon>
          {{ address }}
        </span>
      </div>
    </div>
    <div class="signer-container">
      <p>{{ "DIALOG.PUBLISH-MANDATE.MANDATE-SIGNER" | translate }}</p>

      <div class="person-info">
        <h3>{{ signerName }}</h3>

        <mat-divider></mat-divider>

        <span>
          <img src="assets/icons/id-card.png" />
          {{signerNationalNumber}}
        </span>

        <span>
          <mat-icon>email</mat-icon>
          {{signerEmail}}
        </span>
      </div>
    </div>
    <div class="mandates-container">
      <p>{{mandateTitle |translate}}</p>

      <div class="mandate-list">
        <div class="mandate-type" *ngFor="let mandate of data.mandates">
          <p>{{ "MANDATES.TYPE." + mandate.mandateTypeName | translate }}</p>
          <div *ngIf="mandate.endDate" class="end-date-container">
            <mat-icon>hourglass_bottom</mat-icon>
            <span>{{ mandate.endDate | date: 'dd-MM-yyyy' }}</span>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section mat-dialog-actions>
    <button mat-flat-button [mat-dialog-close]="false">{{ "DIALOG.NO" | translate }}</button>

    <button mat-flat-button [mat-dialog-close]="true" color="accent">
      {{"DIALOG.YES"| translate}}
    </button>
  </section>
</article>
