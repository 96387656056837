import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MandateSignerForm } from 'src/app/clients/form/mandate-signer-form';
import { MandatesApiService } from 'src/app/core/mandates-api/mandates-api.service';
import MandateSignerCommand from 'src/app/service/dto/MandateSignerCommand';
import MandateSigner from 'src/app/service/model/MandateSigner';
import NaturalPerson from 'src/app/service/model/NaturalPerson';

@Component({
  selector: 'mandates-mandate-signer-form',
  templateUrl: './mandate-signer-form.component.html',
  styleUrls: ['./mandate-signer-form.component.scss'],
})
export class MandateSignerFormComponent implements OnInit {
  @Input() clientId: string;
  @Input() naturalPerson: NaturalPerson | null;

  @Output() signerSaved = new EventEmitter<MandateSigner>();
  @Output() onToggle = new EventEmitter();

  isOpen: boolean = false;
  isLoading: boolean = true;
  mandateSigners: MandateSigner[] = [];
  mandateSignerForm = new MandateSignerForm();

  constructor(private mandatesApiService: MandatesApiService) {}

  ngOnInit(): void {
    if (this.naturalPerson) {
      this.mandateSignerForm.initFromNaturalPerson(this.naturalPerson);
    }

    this.getMandateSigners();
  }

  get form(): UntypedFormGroup {
    return this.mandateSignerForm.form;
  }

  get signerListTitle(): string {
    if (this.mandateSigners && this.mandateSigners.length > 1) {
      return 'MANDATES.LAST-USED-SIGNERS';
    }

    return 'MANDATES.LAST-USED-SIGNER';
  }

  get noMandateSigners(): string {
    if (this.naturalPerson) return 'MANDATES.NO-MANDATE-SIGNER-SAVED';
    return 'MANDATES.NO-MANDATE-SIGNERS-SAVED';
  }

  toggleIsOpen(override?: boolean) {
    if (override != undefined) {
      this.isOpen = override;
    } else {
      this.isOpen = !this.isOpen;
    }

    this.onToggle.emit(this.isOpen);
  }

  onSignerCardClick(signer: MandateSigner) {
    this.signerSaved.emit(signer);
    this.toggleIsOpen(false);
  }

  save() {
    this.mandateSignerForm.form.markAllAsTouched();
    if (this.form.valid) {
      const signerCommand: MandateSignerCommand = this.mandateSignerForm.toMandateSigner();

      const existingSigner = this.mandateSigners.find(
        (signer) => signer.nationalNumber == signerCommand.nationalNumber
      );

      if (existingSigner) {
        this.mandatesApiService
          .updateMandateSigner(existingSigner.id, signerCommand)
          .subscribe((signer) => {
            this.signerSaved.emit(signer);
            this.toggleIsOpen(false);
          })
          .add(this.getMandateSigners());
      } else {
        this.mandatesApiService
          .createMandateSigner(this.clientId, signerCommand)
          .subscribe((signer) => {
            this.signerSaved.emit(signer);
            this.toggleIsOpen(false);
          })
          .add(this.getMandateSigners());
      }
    }
  }

  private getMandateSigners() {
    this.mandatesApiService.getMandateSignersByClient(this.clientId).subscribe((signers) => {
      this.mandateSigners = signers;
      this.isLoading = false;
    });
  }
}
