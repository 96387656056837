import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'mandates-invalid-import-mandates-list',
  templateUrl: './invalid-import-mandates-list.component.html',
  styleUrls: ['./invalid-import-mandates-list.component.scss'],
})
export class InvalidImportMandatesListComponent implements OnInit {
  @Input() forms: FormGroup[];
  displayedColumns: string[] = [
    'index',
    'externalReference',
    'mandateType',
    'mandator',
    'mandatorIdentifier',
    'mandatee',
    'mandateeIdentifier',
    'startDate',
    'endDate',
  ];
  datasource = new MatTableDataSource();

  constructor() {}

  ngOnInit(): void {
    this.datasource.data = this.forms;
  }

  getMandateType(formGroup: FormGroup): string {
    if (formGroup.controls['mandateType'].value) {
      if (formGroup.controls['mandateType'].valid) {
        return 'MANDATES.TYPE.' + formGroup.controls['mandateType'].value;
      } else {
        return formGroup.controls['mandateType'].value;
      }
    } else {
      return 'MANDATES.IMPORT.INVALID.MISSING';
    }
  }
}
