import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { LoadingService } from 'src/app/core/loading/loading.service';
import { MandatesApiService } from 'src/app/core/mandates-api/mandates-api.service';
import FeatureToggle, { LevelType } from 'src/app/service/model/FeatureToggle';

@Component({
  selector: 'mandates-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {
  defaultLang: string = 'nl';

  message$: string;
  feature$: FeatureToggle = {
    id: '',
    featureName: 'BANNER',
    isEnabled: false,
    messageEn: '',
    messageFr: '',
    messageNl: '',
    level: LevelType.INFO,
  };

  constructor(
    private translateService: TranslateService,
    private loadingService: LoadingService,
    private mandatesApiService: MandatesApiService,
    private authService: AuthService
  ) {}

  async ngOnInit() {
    this.translateService.onDefaultLangChange.subscribe(({ lang }) => {
      this.defaultLang = lang;
      this.message$ = this.processLanguage(lang);
    });

    this.mandatesApiService.getAllFeatureToggles().subscribe({
      next: (data) => {
        const result = data.find((item) => item.featureName === 'BANNER');
        if (result) {
          this.feature$ = result;
          this.message$ = this.processLanguage(this.defaultLang);
        }
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  get isAuthenticated$(): Observable<boolean> {
    return this.authService.isAuthenticated$;
  }

  get isLoading$(): Observable<boolean> {
    return this.loadingService.loading$;
  }

  get isEnabled$(): Observable<boolean> {
    return of(this.feature$.isEnabled);
  }

  processLanguage(lang: string) {
    if (!this.feature$) {
      return '';
    }

    switch (lang) {
      case 'fr':
        return this.feature$.messageFr;
      case 'nl':
        return this.feature$.messageNl;
      case 'en':
        return this.feature$.messageEn;
      default:
        return this.feature$.messageNl;
    }
  }
}
