import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientsApiService } from 'src/app/core/clients-api/clients-api.service';
import { MandatesApiService } from 'src/app/core/mandates-api/mandates-api.service';
import { LanguageService } from 'src/app/service/language.service';
import Client from 'src/app/service/model/Client';
import Employee from 'src/app/service/model/Employee';
import MandateStatus from 'src/app/service/model/MandateStatus';
import MoralPerson from 'src/app/service/model/MoralPerson';
import NaturalPerson from 'src/app/service/model/NaturalPerson';
import PersonType from 'src/app/service/model/PersonType';
import { ToastService } from 'src/app/service/toast.service';
import { AddEmployeeAccessDialog } from '../add-employee-access-dialog/add-employee-access-dialog.component';

@Component({
  selector: 'mandates-client-detail-info',
  templateUrl: './client-detail-info.component.html',
  styleUrls: ['./client-detail-info.component.scss'],
})
export class ClientDetailInfoComponent implements OnInit {
  id: string;
  client: Client;
  tabIndex: number = -1;
  isLoading: boolean = true;
  isEditing: boolean = false;
  isExclusive: boolean = false;
  employees: Employee[] = [];
  displayedColumns: string[] = ['name', 'rightsNotEditable', 'actionsEngagement'];

  mandateTableColumns = [
    'select',
    // 'mandateTypes',
    'lastOpened',
    'status',
    'actions',
  ];
  status = [
    MandateStatus.TO_SEND,
    MandateStatus.SENDING,
    MandateStatus.SENT,
    MandateStatus.AWAITING_APPROVAL,
    MandateStatus.ACTIVE,
    MandateStatus.ERROR,
    MandateStatus.DECLINED,
    MandateStatus.TERMINATED,
  ];

  constructor(
    private matDialog: MatDialog,
    private clientApiService: ClientsApiService,
    private mandatesApiService: MandatesApiService,
    private route: ActivatedRoute,
    private toast: ToastService,
    private languageService: LanguageService,
    private router: Router
  ) {}

  canDeactivate() {
    return {
      shouldDeactivate: !this.isEditing,
      data: {
        title: 'DIALOG.CANCEL-FORM.DESCRIPTION-PERSON-UPDATE',
        description: '',
        cancelText: 'DIALOG.NO-CONTINUE',
        confirmText: 'DIALOG.YES-CANCEL',
        confirmColor: 'accent',
      },
    };
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('clientId');
    const editClient = this.route.snapshot.queryParams.editClient as boolean;
    if (id) this.id = id;
    if (editClient) this.tabIndex = 2;
    this.isEditing = editClient;

    this.clientApiService.employeesWithAccess$.subscribe(
      (employees) => (this.employees = employees)
    );
    this.fetchClient();
    this.mandatesApiService.getMandates(0, 1000, this.status, this.id || undefined);
    this.mandatesApiService.getConcepts(0, 1000, this.id || undefined);
  }

  private setTabIndex() {
    this.clientApiService.isExclusive$.subscribe((data) => {
      if (this.tabIndex === -1) {
        this.tabIndex = 0;
      } else if (data && data != this.isExclusive) {
        this.tabIndex = 3;
      } else {
        this.tabIndex = this.tabIndex === 3 ? 0 : this.tabIndex;
      }
      this.isExclusive = data;
    });
  }

  onEditPersonClick() {
    this.isEditing = true;
  }

  onAddMandateClick() {
    this.router.navigate(['/create-mandate'], {
      queryParams: { clientId: this.client.id },
    });
  }

  onAddEmployeeClick() {
    const dialogRef = this.matDialog.open(AddEmployeeAccessDialog, {
      data: {
        title: 'DIALOG.ADD-EMPLOYEE',
        cancelText: 'DIALOG.CANCEL',
        confirmText: 'DIALOG.CONFIRM',
        confirmColor: 'accent',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.confirm)
        this.clientApiService
          .addEmployeesToAccessClient(this.client.id, result.employee.id)
          .subscribe();
    });
  }

  onFormCancel() {
    this.isEditing = false;
    this.fetchClient();
  }

  onFormSaved(client: Client) {
    this.client = client;
    this.clientApiService.selectedClient$.next(client);
    this.isEditing = false;
  }

  onTabIndexChange(event: number) {
    this.tabIndex = event;
  }

  private fetchClient() {
    if (this.id) {
      this.clientApiService.getClientById(this.id).subscribe(
        (response) => {
          this.client = response;
          this.setTabIndex();
          this.isLoading = false;
        },
        (error) => {
          this.toast.openErrorToast(`[${error.status}: ${error.name}] ${error.message}`);
          console.error(error);
        }
      );
    } else {
      this.toast.openErrorToast('Could not get Client Id');
    }
  }

  get clientType(): PersonType {
    return this.client.person.getPersonType();
  }

  get isMoralPerson(): boolean {
    return this.clientType === PersonType.MORAL_PERSON;
  }

  get isNaturalPerson(): boolean {
    return this.clientType === PersonType.NATURAL_PERSON;
  }

  get clientName(): string {
    return this.client.person.getScreenName();
  }

  get nationalNumber(): string {
    if ((this.client.person as NaturalPerson).nationalNumber) {
      return (this.client.person as NaturalPerson).nationalNumber;
    }
    return '-';
  }

  get vatNumber(): string {
    if (this.client.person.vatNumber) {
      return this.client.person.vatNumber;
    }
    return '-';
  }

  get address() {
    const { street, streetNumber, box, zipCode, city, country } = this.client.person.address || {};
    return street && streetNumber && zipCode && city && country
      ? `${street} ${streetNumber} ${
          box ? box : ''
        }, ${zipCode} ${city} (${this.languageService.getCountryName(country)})`
      : '-';
  }

  get clientInfo(): { name: string; value: any }[] {
    return [
      { name: 'COMMON.VAT-NUMBER', value: this.vatNumber },
      { name: 'COMMON.NATIONAL-NUMBER', value: this.nationalNumber },
      { name: 'COMMON.ADDRESS', value: this.address },
    ];
  }

  get moralPerson() {
    return this.client.person as MoralPerson;
  }

  get naturalPerson() {
    return this.client.person as NaturalPerson;
  }
}
