<article>
  <h2 nat-dialog-title>{{ "DIALOG.TERMINATE.CLIENT-TITLE" | translate }}</h2>
  <p mat-dialog-title class="subtitle">
    {{ "DIALOG.TERMINATE.DESCRIPTION-ACTIVE-MANDATES" | translate }}
  </p>
  <section mat-dialog-content class="content">
    <div class="client-container">
      <p>{{ "DIALOG.PUBLISH-MANDATE.SELECTED-CLIENT" | translate }}</p>

      <div class="client-info">
        <h3>{{ clientName }}</h3>

        <mat-divider></mat-divider>

        <p class="company-form">
          <mandates-client-icon [type]="clientType" [inline]="true"></mandates-client-icon>
          {{ "PERSON_TYPE." + clientType | translate }}
        </p>

        <p class="client-number">{{ clientNumber }}</p>

        <p class="client-address">{{ address }}</p>
      </div>
    </div>

    <div class="mandates-container" *ngIf="mandatesToTerminate.length > 0">
      <p>{{ "DIALOG.TERMINATE.MANDATES-TO-TERMINATE" | translate }}</p>

      <div class="mandate-list">
        <div class="mandate-type" *ngFor="let mandate of mandatesToTerminate">
          <p>{{ "MANDATES.TYPE." + mandate.mandateTypes | translate }}</p>
        </div>
      </div>
    </div>
  </section>

  <section mat-dialog-actions>
    <div class="delete-prompt">
      <p>{{"DIALOG.TERMINATE.TERMINATE-PROMPT" | translate: {value: deleteKeyword} }}</p>
      <mandates-input-field
        [parentForm]="form"
        control="keyword"
        type="text"
      ></mandates-input-field>
    </div>
    <div class="action-buttons">
      <button mat-flat-button [mat-dialog-close]="false">{{ "DIALOG.NO" | translate }}</button>

      <button mat-flat-button [mat-dialog-close]="true" color="accent" [disabled]="!canDelete()">
        {{ "DIALOG.TERMINATE.CLIENT-BUTTON" | translate }}
      </button>
    </div>
  </section>
</article>
