<div class="container">
  <div>
    <h2 mat-dialog-title>{{ data.title | translate }}</h2>
  </div>
  <div class="employee-select">
    <mat-select [placeholder]="'DIALOG.SELECT-EMPLOYEE' | translate" [(ngModel)]="employee">
      <mat-option *ngFor="let employee of employees" [value]="employee">
        {{ employee.firstName }} {{ employee.lastName }}
      </mat-option>
    </mat-select>
  </div>
  <div mat-dialog-actions class="action-buttons-container">
    <button mat-raised-button (click)="onCancelClick()" class="action-button">
      {{ data.cancelText | translate }}
    </button>
    <button
      mat-raised-button
      [color]="data.confirmColor"
      (click)="onConfirmClick()"
      class="action-button"
    >
      {{ data.confirmText | translate }}
    </button>
  </div>
</div>
