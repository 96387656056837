import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { EmployeeDetailComponent } from './employee-detail/employee-detail.component';
import { EmployeesListComponent } from './employees-list/employees-list.component';
import { EmployeesComponent } from './employees.component';

@NgModule({
  declarations: [EmployeesComponent, EmployeesListComponent, EmployeeDetailComponent],
  imports: [CommonModule, SharedModule, RouterModule],
  exports: [EmployeesListComponent],
})
export class EmployeesModule {}
