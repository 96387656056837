<form [formGroup]="moralPersonForm.form">
  <section formGroupName="base">
    <mandates-input-field
      [parentForm]="baseForm"
      [control]="'country'"
      [label]="'COMMON.COUNTRY'"
      [type]="'select'"
      [options]="countries"
      (selectionChange)="onCountryChange($event)"
    ></mandates-input-field>

    <mandates-input-field
      [parentForm]="baseForm"
      [control]="'companyNumber'"
      [label]="'COMMON.COMPANY-NUMBER'"
      [type]="'digit'"
      [icon]="'search'"
      [isLoading]="fetchCompanyStatus === Status.PENDING"
      [disabled]="!!person"
    >
    </mandates-input-field>

    <mat-card
      *ngIf="fetchCompanyMessage"
      [ngClass]="{
        'message--clear': Status.CLEAR === fetchCompanyStatus,
        'message--pending': Status.PENDING === fetchCompanyStatus,
        'message--success': Status.SUCCESS === fetchCompanyStatus,
        'message--error': Status.FAILED === fetchCompanyStatus
      }"
    >
      <mat-card-content>{{ fetchCompanyMessage }}</mat-card-content>
    </mat-card>

    <mandates-input-field
      [parentForm]="baseForm"
      [control]="'language'"
      [label]="'COMMON.LANGUAGE'"
      [type]="'select'"
      [options]="languages"
    ></mandates-input-field>
  </section>

  <mat-divider></mat-divider>

  <section formGroupName="company">
    <h2>{{ 'COMMON.GEN-COMP-INFO' | translate }}</h2>
    <mandates-input-field
      [parentForm]="companyForm"
      [control]="'name'"
      [label]="'COMMON.COMPANY-NAME'"
      [type]="'text'"
    ></mandates-input-field>

    <mandates-input-field
      [parentForm]="companyForm"
      [control]="'companyForm'"
      [label]="'COMMON.COMPANY-FORM'"
      [type]="'select'"
      [options]="companyForms"
    >
    </mandates-input-field>

    <mandates-input-field
      [parentForm]="companyForm"
      [control]="'status'"
      [label]="'COMMON.STATUS'"
      [type]="'select'"
      [options]="statusOptions"
    >
    </mandates-input-field>

    <mandates-input-field
      [parentForm]="companyForm"
      [control]="'employees'"
      [label]="'COMMON.EMPLOYEES'"
      [type]="'select'"
      [options]="booleanOptions"
    >
    </mandates-input-field>
  </section>

  <mat-divider></mat-divider>

  <section formGroupName="address">
    <h2>{{ 'COMMON.ADDRESS' | translate }}</h2>

    <mandates-input-field
      [parentForm]="addressForm"
      [control]="'country'"
      [label]="'COMMON.COUNTRY'"
      [type]="'select'"
      [options]="countries"
    >
    </mandates-input-field>

    <mandates-input-field
      [parentForm]="addressForm"
      [control]="'city'"
      [label]="'COMMON.CITY'"
      [type]="'text'"
    ></mandates-input-field>

    <mandates-input-field
      [parentForm]="addressForm"
      [control]="'zipCode'"
      [label]="'COMMON.POSTAL-CODE'"
      [type]="'text'"
    ></mandates-input-field>

    <mandates-input-field
      [parentForm]="addressForm"
      [control]="'street'"
      [label]="'COMMON.STREET'"
      [type]="'text'"
    ></mandates-input-field>

    <div class="container">
      <mandates-input-field
        [parentForm]="addressForm"
        [control]="'streetNumber'"
        [label]="'COMMON.STREET-NUMBER'"
        [type]="'text'"
      ></mandates-input-field>

      <mandates-input-field
        [parentForm]="addressForm"
        [control]="'box'"
        [label]="'COMMON.BUS-NUMBER'"
        [type]="'number'"
      ></mandates-input-field>
    </div>
  </section>

  <mat-divider></mat-divider>

  <section formGroupName="vat">
    <h2>{{ 'COMMON.VAT-INFORMATION' | translate }}</h2>

    <mandates-input-field
      [parentForm]="vatForm"
      [control]="'vatLiable'"
      [label]="'COMMON.VAT-LIABLE'"
      [type]="'select'"
      [options]="booleanOptions"
    ></mandates-input-field>

    <mandates-input-field
      [parentForm]="vatForm"
      [control]="'vatNumber'"
      [label]="'COMMON.VAT-NUMBER'"
      [type]="'text'"
    ></mandates-input-field>

    <mandates-input-field
      [parentForm]="vatForm"
      [control]="'vatUnit'"
      [label]="'COMMON.VAT-UNIT'"
      [type]="'select'"
      [options]="booleanOptions"
    ></mandates-input-field>

    <mandates-input-field
      [parentForm]="vatForm"
      [control]="'inBelgium'"
      [label]="'COMMON.PERM-IN-BE'"
      [type]="'select'"
      [options]="booleanOptions"
    ></mandates-input-field>

    <mandates-input-field
      [parentForm]="vatForm"
      [control]="'multipleRegions'"
      [label]="'COMMON.ACTIVE-IN-BELGIAN-REGIONS'"
      [type]="'select'"
      [options]="regionOptions"
      [multiple]="true"
    ></mandates-input-field>
  </section>
</form>

<mat-divider></mat-divider>

<section class="actions">
  <button mat-raised-button color="warn" (click)="openCancelDialog()">
    <mat-icon>cancel</mat-icon>
    {{ 'BUTTON.CANCEL' | translate }}
  </button>
  <button mat-raised-button (click)="save()">
    {{ (person ? 'BUTTON.SAVE' : 'BUTTON.CREATE') | translate }}
    <mat-icon>chevron_right</mat-icon>
  </button>
</section>
