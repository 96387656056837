import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import MandateSignerCommand from 'src/app/service/dto/MandateSignerCommand';
import MandateSigner from 'src/app/service/model/MandateSigner';
import NaturalPerson from 'src/app/service/model/NaturalPerson';
import { CustomValidator } from '../../shared/validators/custom.validator';

export class MandateSignerForm {
  form = new UntypedFormGroup({
    nationalNumber: new UntypedFormControl('', [
      Validators.required,
      CustomValidator.validBelgianNationalNumber,
    ]),
    firstName: new UntypedFormControl('', [Validators.required]),
    lastName: new UntypedFormControl('', [Validators.required]),
    emailAddress: new UntypedFormControl('', [Validators.required, Validators.email]),
  });

  initFromMandateSigner(signer: MandateSigner) {
    this.form.patchValue({ ...signer });
  }

  initFromNaturalPerson(person: NaturalPerson) {
    const { nationalNumber, firstName, lastName } = person;
    this.form.patchValue({ nationalNumber, firstName, lastName });
    this.form
      .get('nationalNumber')!
      .setValidators([Validators.required, CustomValidator.validBelgianNationalNumber]);
    this.form.get('nationalNumber')!.updateValueAndValidity();
    this.form.get('nationalNumber')!.disable();
    this.form.get('firstName')!.disable();
    this.form.get('lastName')!.disable();
  }

  toMandateSigner(): MandateSignerCommand {
    const { nationalNumber, firstName, lastName, emailAddress } = this.form.getRawValue();

    return {
      nationalNumber,
      firstName,
      lastName,
      emailAddress,
    };
  }
}
