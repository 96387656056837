<article>
  <h1>{{ 'COMMON.EMPLOYEES' | translate }}</h1>
  <mat-tab-group class="custom-tabs">
    <mat-tab [label]="'EMPLOYEES.ALL' | translate">
      <mandates-employees-list
        [employees]="employees"
        [mode]="'EDIT_ROLE'"
      ></mandates-employees-list>
    </mat-tab>
    <mat-tab [label]="'EMPLOYEES.ADMINISTRATORS' | translate">
      <mandates-employees-list [employees]="admins" [mode]="'EDIT_ROLE'"></mandates-employees-list>
    </mat-tab>
    <mat-tab [label]="'EMPLOYEES.EMPLOYEES' | translate">
      <mandates-employees-list
        [employees]="coworker"
        [mode]="'EDIT_ROLE'"
      ></mandates-employees-list>
    </mat-tab>
  </mat-tab-group>
</article>
