<div class="table-wrapper">
  <table
    mat-table
    [dataSource]="dataSource"
    [ngClass]="{ 'primary-color': color == 'primary', 'accent-color': color == 'accent' }"
  >
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select" sticky>
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          class="header-checkbox"
          [ngClass]="{
            'primary-header-checkbox': color == 'primary',
            'accent-header-checkbox': color == 'accent'
          }"
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()"
          color="primary"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
          [aria-label]="checkboxLabel(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-content></ng-content>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [ngClass]="{ 'selected-row': isRowChecked(row) }"
    ></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td
        class="mat-cell"
        [attr.colspan]="displayedColumns.length"
        [translate]="'TABLE.NO-DATA' | translate: { items: elementDisplayName }"
      ></td>
    </tr>
  </table>
  <section class="paginator-container">
    <div class="total-selected-container" *ngIf="selection.hasValue()">
      <span>{{
        'paginator.number-of-total-rows-selected'
          | translate
            : {
                number: selection.selected.length,
                total: totalAvailable,
                rows: elementDisplayName | lowercase
              }
      }}</span>
    </div>
    <mat-paginator
      [ngStyle]="{ display: isSelectingAll || useAngularPaginator ? 'none' : 'block' }"
      [pageSizeOptions]="pageSizeOptions"
      (page)="onPageChange($event)"
      [showFirstLastButtons]="showFirstLastButtons"
      [length]="page.totalElements"
      [pageIndex]="page.number"
      [pageSize]="page.size"
    ></mat-paginator>
    <mat-paginator
      [ngStyle]="{ display: isSelectingAll || useAngularPaginator ? 'block' : 'none' }"
      #AllPaginator
      [pageSizeOptions]="pageSizeOptions"
      (page)="onPageChange($event)"
      pageSize="10"
      [showFirstLastButtons]="showFirstLastButtons"
    ></mat-paginator>
  </section>
</div>
