import { Component, Input, OnInit } from '@angular/core';
import MandateType from '../../service/model/MandateType';

@Component({
  selector: 'mandates-mandate-type-card',
  templateUrl: './mandate-type-card.component.html',
  styleUrls: ['./mandate-type-card.component.scss'],
})
export class MandateTypeCardComponent implements OnInit {
  @Input() mandateType: MandateType;

  constructor() {}

  ngOnInit(): void {}
}
