import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private defaultTtl = 24 * 60 * 60 * 1000;

  constructor() {}

  public setItemWithExpiry(key: string, value: any, ttl: number = this.defaultTtl) {
    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + ttl, // ttl is in milliseconds
    };
    localStorage.setItem(key, JSON.stringify(item));
  }

  public getItemWithExpiry(key: string) {
    const itemString = localStorage.getItem(key);
    if (!itemString) {
      return null;
    }
    const item = JSON.parse(itemString);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      // Item has expired, remove it from localStorage
      localStorage.removeItem(key);
      return null;
    }

    return item.value;
  }
}
