import { Component, Input, OnInit } from '@angular/core';
import MandateSigner from 'src/app/service/model/MandateSigner';

@Component({
  selector: 'mandates-mandate-signer-card',
  templateUrl: './mandate-signer-card.component.html',
  styleUrls: ['./mandate-signer-card.component.scss'],
})
export class MandateSignerCardComponent implements OnInit {
  @Input() signer?: MandateSigner;
  @Input() isDark: boolean = true;

  constructor() {}

  ngOnInit(): void {}

  get name(): string {
    const { firstName, lastName } = this.signer || {};
    return `${firstName} ${lastName}`;
  }
}
