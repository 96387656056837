import { DatePipe, formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import * as saveAs from 'file-saver';
import { AuthService } from '../core/authentication/auth.service';
import { UserInfo } from '../core/authentication/UserInfo';
import { MandatesApiService } from '../core/mandates-api/mandates-api.service';
import MandateStatus from '../service/model/MandateStatus';
import MandateType from '../service/model/MandateType';
import PersonType from '../service/model/PersonType';
import Filter from '../shared/components/base-filter/filter';
import { SelectionModel } from '@angular/cdk/collections';
import MandateListItem from '../service/dto/MandateListItem';
import { ToastService } from '../service/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { BaseFilterComponent } from '../shared/components/base-filter/base-filter.component';
import { ClientsApiService } from '../core/clients-api/clients-api.service';
import MandateConceptListItem from '../service/dto/MandateConceptListItem';

@Component({
  selector: 'mandates-mandates',
  templateUrl: './mandates.component.html',
  styleUrls: ['./mandates.component.scss'],
})
export class MandatesComponent implements OnInit {
  status = [
    MandateStatus.ACTIVE,
    MandateStatus.TO_SEND,
    MandateStatus.SENDING,
    MandateStatus.SENT,
    MandateStatus.AWAITING_APPROVAL,
    MandateStatus.ERROR,
    MandateStatus.DECLINED,
    MandateStatus.TERMINATED,
  ];

  filterForm: UntypedFormGroup = new UntypedFormGroup({});

  filters: Filter[] = [];

  mandateTypes: MandateType[] = [];

  userInfo: UserInfo | undefined;

  selectedIndex = 0;

  mandateSelection: SelectionModel<MandateListItem> = new SelectionModel<MandateListItem>(true, []);
  conceptSelection: SelectionModel<MandateConceptListItem> =
    new SelectionModel<MandateConceptListItem>(true, []);

  @ViewChild(BaseFilterComponent) mandateFilterComponent: BaseFilterComponent;

  constructor(
    private router: Router,
    private mandatesApiService: MandatesApiService,
    private authService: AuthService,
    private translateService: TranslateService,
    private toast: ToastService,
    private clientsApiService: ClientsApiService
  ) {}

  ngOnInit(): void {
    this.mandatesApiService.getConcepts(0, 10);
    this.mandatesApiService.getMandates(0, 10, this.status);
    this.mandatesApiService.getMandateTypes().subscribe((types) => {
      this.initFilters(types);
    });
    this.authService.userInfo$.subscribe((userInfo) => (this.userInfo = userInfo));
  }

  onCreateClick() {
    this.router.navigate(['/create-mandate']);
  }

  onImportClick() {
    this.router.navigate(['/mandates/import']);
  }

  private initFilters(types: MandateType[]) {
    this.filters = [
      {
        name: 'LIST.CLIENTTYPE',
        options: [
          {
            name: 'PERSON_TYPE.NATURAL_PERSON',
            value: PersonType.NATURAL_PERSON,
          },
          {
            name: 'PERSON_TYPE.MORAL_PERSON',
            value: PersonType.MORAL_PERSON,
          },
        ],
        controlName: 'clientType',
      },
      {
        name: 'LIST.MANDATE-TYPE',
        options: types.map((type) => ({ name: `MANDATES.TYPE.${type.typeName}`, value: type })),
        controlName: 'mandateType',
      },
      {
        name: 'LIST.STATUS',
        options: [
          {
            name: `MANDATES.TO_SEND`,
            value: MandateStatus.TO_SEND,
          },
          {
            name: `MANDATES.SENDING`,
            value: MandateStatus.SENDING,
          },
          {
            name: `MANDATES.ACTIVE`,
            value: MandateStatus.ACTIVE,
          },
          {
            name: `MANDATES.AWAITING_APPROVAL`,
            value: MandateStatus.AWAITING_APPROVAL,
          },
          {
            name: `MANDATES.DECLINED`,
            value: MandateStatus.DECLINED,
          },
          {
            name: `MANDATES.ERROR`,
            value: MandateStatus.ERROR,
          },
          {
            name: `MANDATES.TERMINATED`,
            value: MandateStatus.TERMINATED,
          },
          {
            name: `MANDATES.ARCHIVED`,
            value: MandateStatus.ARCHIVED,
          },
        ],
        controlName: 'mandateStatus',
      },
      {
        name: 'SEARCH.ON-CLIENT-NAME',
        options: [],
        controlName: 'clientName',
        isSearchFilter: true,
      },
    ];
  }

  onExportClick() {
    const { clientType, mandateType, mandateStatus, clientName } = this.filterForm.value;
    const datepipe = new DatePipe('en');
    const fileName = `ITAA Mandates Export - ${this.userInfo?.officeName} - ${datepipe.transform(
      new Date(),
      'yyyy/MM/dd'
    )}`;
    this.mandatesApiService
      .generateMandatesExport(
        mandateStatus ? [mandateStatus] : undefined,
        undefined,
        mandateType && mandateType.id,
        clientType,
        clientName
      )
      .subscribe((blob: Blob) => saveAs(blob, `${fileName}.csv`));
  }

  canArchiveSelectedMandates(): boolean {
    return (
      this.mandateSelection.hasValue() &&
      this.mandateSelection.selected.every((mandate) => {
        switch (mandate.status) {
          case MandateStatus.DECLINED:
          case MandateStatus.ERROR:
          case MandateStatus.TERMINATED:
            return true;
          default:
            return false;
        }
      })
    );
  }

  archiveMandates() {
    const command = {
      mandateReferences: this.mandateSelection.selected.map((mandate) => mandate.id),
    };
    this.mandatesApiService.archiveMandates(command).subscribe({
      next: () => {
        this.mandateFilterComponent.resetFilters();
        this.mandateSelection.clear();
        this.toast.openSuccessToast(
          this.translateService.instant('TOAST.MANDATES-ARCHIVED.SUCCESS')
        );
      },
      error: (error) => {
        this.toast.openErrorToast(this.translateService.instant('TOAST.MANDATES-ARCHIVED.ERROR'));
        console.error(error);
      },
    });
  }

  terminateConcepts() {
    const command = {
      conceptReferences: this.conceptSelection.selected.reduce((acc: string[], mandate) => {
        if (mandate.concept) {
          acc.push(mandate.concept.id);
        } else {
          acc.push(...mandate.bulkConcept.concepts.map((c) => c.id));
        }
        return acc;
      }, []),
    };
    this.mandatesApiService.terminateConceptsInBulk(command).subscribe({
      next: () => {
        command.conceptReferences.length > 1
          ? this.toast.openSuccessToast(
              this.translateService.instant('TOAST.CONCEPTS-DELETED.SUCCESS')
            )
          : this.toast.openSuccessToast(
              this.translateService.instant('TOAST.CONCEPT-DELETED.SUCCESS')
            );
        this.conceptSelection.clear();
        this.mandatesApiService.getConcepts();
      },
      error: (error) => {
        command.conceptReferences.length > 1
          ? this.toast.openErrorToast(this.translateService.instant('TOAST.CONCEPTS-DELETED.ERROR'))
          : this.toast.openErrorToast(this.translateService.instant('TOAST.CONCEPT-DELETED.ERROR'));
        console.error(error);
      },
    });
  }

  tabHasSelection(): boolean {
    if (this.selectedIndex == 0) {
      return this.mandateSelection.hasValue() && this.mandateSelection.selected.length > 0;
    }

    if (this.selectedIndex == 1) {
      return this.conceptSelection.hasValue() && this.conceptSelection.selected.length > 0;
    }

    return false;
  }
}
