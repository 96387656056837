import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ClientsApiService } from 'src/app/core/clients-api/clients-api.service';
import { EmployeesApiService } from 'src/app/core/employees-api/employees-api.service';
import Client from 'src/app/service/model/Client';
import Employee from 'src/app/service/model/Employee';
import EmploymentRoleType from 'src/app/service/model/EmploymentRoleType';
import { BaseDialog } from 'src/app/shared/components/base-dialog/base-dialog';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../core/authentication/auth.service';
import { UserInfo } from '../../core/authentication/UserInfo';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'mandates-employees-list',
  templateUrl: './employees-list.component.html',
  styleUrls: ['./employees-list.component.scss'],
})
export class EmployeesListComponent implements OnInit, OnChanges {
  @Input() employees: Employee[];
  @Input() client?: Client;

  private _mode: EmployeeListMode;
  @Input()
  get mode() {
    return this._mode;
  }
  set mode(value: string) {
    this._mode = value as EmployeeListMode;
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  userInfo: UserInfo | undefined;
  employeeRoles = Object.values(EmploymentRoleType);
  dataSource: MatTableDataSource<Employee>;
  displayedColumns: string[];

  constructor(
    private authService: AuthService,
    private clientsApiService: ClientsApiService,
    private employeesApiService: EmployeesApiService,
    private translateService: TranslateService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.userInfo = this.authService.userInfo;
    switch (this.mode) {
      case EmployeeListMode.VIEW:
        this.displayedColumns = ['name', 'rightsNotEditable', 'actions'];
        break;
      case EmployeeListMode.EDIT_ROLE:
        this.displayedColumns = ['name', 'rights', 'actions'];
        break;
      case EmployeeListMode.EDIT_ACCESS:
        this.displayedColumns = ['name', 'rightsNotEditable', 'actionsEngagement'];
        break;
    }
  }

  ngOnChanges(): void {
    this.dataSource = new MatTableDataSource<Employee>(this.employees);
    this.dataSource.paginator = this.paginator;
  }

  onSelectionChange(employee: Employee, event: any) {
    employee.role = event.value;
    this.updateEmployementRole(employee, event.value);
  }

  public updateEmployementRole(employee: Employee, role: EmploymentRoleType) {
    const name = `${employee.firstName} ${employee.lastName}`;
    const description =
      role == EmploymentRoleType.ADMIN
        ? this.translateService.instant('DIALOG.UPDATE-EMPLOYMENTROLE.ADMIN-DESCRIPTION', {
            name: name,
          })
        : this.translateService.instant('DIALOG.UPDATE-EMPLOYMENTROLE.COWORKER-DESCRIPTION', {
            name: name,
          });
    const paragraph =
      role == EmploymentRoleType.ADMIN
        ? 'DIALOG.UPDATE-EMPLOYMENTROLE.ADMIN-PARAGRAPH'
        : 'DIALOG.UPDATE-EMPLOYMENTROLE.COWORKER-PARAGRAPH';

    const dialogRef = this.dialog.open(BaseDialog, {
      data: {
        title: description,
        description: '',
        paragraph: paragraph,
        cancelText: 'DIALOG.NO',
        confirmText: 'DIALOG.YES',
        confirmColor: 'accent',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.employeesApiService
          .updateRoleEmployee(this.userInfo!.officeId, employee.id, role)
          .subscribe();
      } else {
        employee.role =
          employee.role == EmploymentRoleType.ADMIN
            ? EmploymentRoleType.COWORKER
            : EmploymentRoleType.ADMIN;
      }
    });
  }

  deletelimitedAccess(employee: Employee, event: Event) {
    event.stopPropagation();
    if (this.client == undefined) return;
    this.clientsApiService.deleteEmployeeToLimitedAccessDialog(this.client, employee);
  }

  handleSortChange(sort: Sort) {
    if (sort.active === 'name') {
      switch (sort.direction) {
        case 'asc':
          this.dataSource.data = this.employees.sort((a, b) =>
            a.firstName > b.firstName ? 1 : -1
          );
          break;
        case 'desc':
          this.dataSource.data = this.employees.sort((a, b) =>
            a.firstName > b.firstName ? -1 : 1
          );
          break;
        case '':
          this.dataSource.data = this.employees;
          break;
      }
    } else {
      this.dataSource.data = this.employees;
    }
  }
}

export enum EmployeeListMode {
  VIEW = 'VIEW',
  EDIT_ROLE = 'EDIT_ROLE',
  EDIT_ACCESS = 'EDIT_ACCESS',
}
