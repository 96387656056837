import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as i18nIsoCountries from 'i18n-iso-countries';
import * as i18nNationality from 'i18n-nationality';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));
i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/nl.json'));
i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/fr.json'));

// i18nNationality.registerLocale(require('i18n-nationality/langs/en.json'));
i18nNationality.registerLocale(require('src/assets/i18n/nationality/nl.json'));
i18nNationality.registerLocale(require('i18n-nationality/langs/fr.json'));

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
