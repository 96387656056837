import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import Employee from 'src/app/service/model/Employee';
import EmploymentRoleType from 'src/app/service/model/EmploymentRoleType';
import { environment } from 'src/environments/environment';

export class EmployeeRoleCommand {
  officeId: string;
  employeeId: string;
  employmentRoleType: EmploymentRoleType;
}

@Injectable({
  providedIn: 'root',
})
export class EmployeesApiService {
  private baseUrl: string = environment.backendUrl;
  private officeId = environment.userInfoFixture.officeId;
  private employeeId = environment.userInfoFixture.employeeId;

  employees$: BehaviorSubject<Employee[]> = new BehaviorSubject<Employee[]>([]);

  constructor(private http: HttpClient) {}

  public getEmployees(): Observable<Employee[]> {
    return this.http
      .get<Employee[]>(`${this.baseUrl}/employees`)
      .pipe(tap((employees) => this.employees$.next(employees)));
  }

  public getEmployeeById(id: string): Observable<Employee> {
    return this.http.get<Employee>(`${this.baseUrl}/employees/${id}`);
  }

  public updateRoleEmployee(officeId: string, employeeId: string, role: EmploymentRoleType) {
    const command = {
      officeId: officeId,
      employeeId: employeeId,
      employmentRoleType: role,
    } as EmployeeRoleCommand;

    return this.http
      .put<any>(`${this.baseUrl}/employees/${employeeId}/employment-roles`, command)
      .pipe(tap(() => this.getEmployees()));
  }
}
