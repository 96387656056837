import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const authConfig: AuthConfig = {
  clientId: environment.oidc.clientId,
  issuer: environment.oidc.issuer,
  scope: 'openid profile email offline_access',
  responseType: 'code',
  useSilentRefresh: true, // Needed for Code Flow to suggest using iframe-based refreshes
  silentRefreshTimeout: 5000, // For faster testing
  timeoutFactor: 0.9, // For faster testing
  //sessionChecksEnabled: true,
  showDebugInformation: true, // Also requires enabling "Verbose" level in devtools
  //clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
  //nonceStateSeparator : 'semicolon', // Real semicolon gets mangled by IdentityServer's URI encoding
  redirectUri: window.location.origin + '/',
  customQueryParams: {
    audience: environment.oidc.audience,
  },
};
