<mat-card
  *ngIf="mandateType"
  class="mandate-type-card"
  [ngClass]="{ 'selected mat-elevation-z5': mandateType.isSelected }"
>
  <mat-card-title><mat-icon>history_edu</mat-icon></mat-card-title>
  <mat-card-content
    >{{ 'MANDATES.TYPE.' + mandateType.typeName | translate }}
    <div class="unique-mandate" *ngIf="mandateType.isUnique">
      <mat-icon [inline]="true">check_circle</mat-icon>{{ 'MANDATES.UNIQUE' | translate }}
    </div>
  </mat-card-content>
</mat-card>
