import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import AddressCommand from '../../service/dto/AddressCommand';
import Address from '../../service/model/Address';
import { CustomValidator } from '../../shared/validators/custom.validator';

export class AddressForm {
  form = new UntypedFormGroup({
    country: new UntypedFormControl('BE', [Validators.required]),
    city: new UntypedFormControl('', [Validators.required, CustomValidator.notEmpty]),
    zipCode: new UntypedFormControl('', [Validators.required, CustomValidator.notEmpty]),
    street: new UntypedFormControl('', [Validators.required, CustomValidator.notEmpty]),
    streetNumber: new UntypedFormControl('', [Validators.required, CustomValidator.notEmpty]),
    box: new UntypedFormControl('', []),
  });

  initFromAddress(address?: Address) {
    this.form.patchValue({ ...address });
  }

  toAddress(): AddressCommand {
    const { street, streetNumber, box, zipCode, city, country } = this.form.value;

    return {
      country: country,
      city: city,
      zipCode: zipCode,
      street: street,
      streetNumber: streetNumber,
      box: box?.trim()?.length ? box : null,
    };
  }
}
