<mandates-base-filter
  class="client-filter"
  [filters]="filters"
  [form]="form"
></mandates-base-filter>

<section *ngIf="isAboveLimit" class="info-container">
  <mat-icon>info</mat-icon>
  <p>
    {{
      'SEARCH.SHOWING-AMOUNT-OF-TOTAL' | translate: { amount: clients.length, total: totalClients }
    }}
  </p>
</section>

<section *ngIf="clients.length <= 0" class="info-container">
  <mat-icon>info</mat-icon>
  <p>
    {{ 'SEARCH.NO-RESULTS-FOUND' | translate }}
  </p>
</section>

<section *ngIf="isLoading; else resultSection" class="loading-container">
  <mat-spinner></mat-spinner>
</section>

<ng-template #resultSection>
  <section *ngIf="clients.length > 0" class="filtered-clients-container">
    <mandates-client-card
      [client]="client"
      *ngFor="let client of filteredClients"
      (click)="handleClientClick(client)"
    >
    </mandates-client-card>
  </section>
</ng-template>
