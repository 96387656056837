import { Location } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import MandateListItem from 'src/app/service/dto/MandateListItem';
import MandateSignerCommand from 'src/app/service/dto/MandateSignerCommand';
import Mandate from 'src/app/service/model/Mandate';
import MandatesConcept from 'src/app/service/model/MandateConcept';
import MandateImportRows from 'src/app/service/model/MandateImportRows';
import MandateSigner from 'src/app/service/model/MandateSigner';
import MandateStatus from 'src/app/service/model/MandateStatus';
import MandateType from 'src/app/service/model/MandateType';
import Page from 'src/app/service/model/Page';
import PersonType from 'src/app/service/model/PersonType';
import { ToastService } from 'src/app/service/toast.service';
import { BaseDialog } from 'src/app/shared/components/base-dialog/base-dialog';
import { environment } from 'src/environments/environment';
import { UpdateConceptCommand, CreateConceptCommand } from '../../service/dto/ConceptCommand';
import MandateImportRow from '../../service/model/MandateImportRow';
import { ClientsApiService } from '../clients-api/clients-api.service';
import { Sort } from '@angular/material/sort';
import MandateConceptListItem from 'src/app/service/dto/MandateConceptListItem';
import BulkConcept from 'src/app/service/dto/BulkConcept';
import FeatureToggle from 'src/app/service/model/FeatureToggle';

export class TerminateMandateCommand {
  id: string;
  pageNumber: number;
  pageSize: number;
  redirect: boolean;
  clientName?: string;
  clientId: string;
  status?: MandateStatus[];
}
export class TerminateMandatesCommand {
  conceptReferences: string[] = [];
  pageNumber: number;
  pageSize: number;
  redirect: boolean;
  clientName?: string;
  clientId: string;
  status?: MandateStatus[];
}

@Injectable({
  providedIn: 'root',
})
export class MandatesApiService {
  protected baseUrl: string = environment.backendUrl;
  userInfo = environment.userInfoFixture;

  page: Page<MandateListItem> = {
    content: [],
    pageable: undefined,
    totalPages: 0,
    totalElements: 0,
    last: true,
    numberOfElements: 0,
    first: true,
    number: 0,
    size: 0,
    empty: true,
  };

  conceptPage: Page<MandateConceptListItem> = {
    content: [],
    pageable: undefined,
    totalPages: 0,
    totalElements: 0,
    last: true,
    numberOfElements: 0,
    first: true,
    number: 0,
    size: 0,
    empty: true,
  };

  mandates$ = new BehaviorSubject<Page<MandateListItem> | undefined>(this.page);
  totalAvailableMandates$ = new BehaviorSubject<number>(0);
  concepts$ = new BehaviorSubject<Page<MandateConceptListItem> | undefined>(this.conceptPage);
  totalAvailableConcepts$ = new BehaviorSubject<number>(0);

  constructor(
    private http: HttpClient,
    private clientsApiService: ClientsApiService,
    private toast: ToastService,
    private translateService: TranslateService,
    public dialog: MatDialog,
    private location: Location
  ) {}

  public getMandateTypes(): Observable<MandateType[]> {
    return this.http.get<MandateType[]>(`${this.baseUrl}/mandate-types`);
  }

  public createConcept(command: CreateConceptCommand): Observable<MandatesConcept[]> {
    return this.http.post<MandatesConcept[]>(`${this.baseUrl}/concepts`, command);
  }

  public updateConcept(
    conceptId: string,
    clientId: string,
    command: UpdateConceptCommand
  ): Observable<MandatesConcept> {
    let headers = new HttpHeaders({
      'Client-Id': clientId,
    });

    return this.http.put<MandatesConcept>(`${this.baseUrl}/concepts/${conceptId}`, command, {
      headers,
    });
  }

  public updateConcepts(
    conceptId: string,
    command: CreateConceptCommand
  ): Observable<MandatesConcept[]> {
    return this.http.put<MandatesConcept[]>(`${this.baseUrl}/concepts/${conceptId}`, command);
  }

  public async getConcepts(
    page?: number,
    size?: number,
    clientReference?: string,
    sort: Sort = { active: 'modifiedDate', direction: 'desc' }
  ): Promise<Page<MandateConceptListItem> | undefined> {
    let headers = new HttpHeaders();
    if (clientReference) headers = headers.append('Client-Id', clientReference);

    let queryParams = this.getQueryParam(page, size);

    if (sort && sort.direction)
      queryParams = queryParams.append('sort', `${sort.active},${sort.direction}`);

    const conceptPage = await this.http
      .get<Page<MandateConceptListItem>>(`${this.baseUrl}/concepts`, {
        headers: headers,
        params: queryParams,
      })
      .toPromise();

    // conceptPage?.content.forEach(
    //   (concept) =>
    //     (concept.mandateTypes = this.getTranslatedMandateTypesForConcept(concept.mandates))
    // );

    this.concepts$.next(conceptPage);

    if (conceptPage && conceptPage?.totalElements > this.totalAvailableConcepts$.value) {
      this.totalAvailableConcepts$.next(conceptPage.totalElements);
    }

    return conceptPage;
  }

  public getConceptById(id: string, clientReference: string): Observable<MandatesConcept> {
    const headers = new HttpHeaders({
      'Client-Id': clientReference,
    });
    return this.http.get<MandatesConcept>(`${this.baseUrl}/concepts/${id}`, {
      headers: headers,
    });
  }

  public getConceptByBulkId(id: string): Observable<BulkConcept> {
    return this.http.get<BulkConcept>(`${this.baseUrl}/concepts/bulk/${id}`);
  }

  public terminateConcept(command: TerminateMandateCommand) {
    const dialogRef = this.dialog.open(BaseDialog, {
      data: {
        title: this.translateService.instant('DIALOG.DELETE.CONCEPT-DESCRIPTION', {
          name: command.clientName,
        }),
        description: '',
        cancelText: 'DIALOG.NO-CANCEL',
        confirmText: 'DIALOG.YES-DELETE',
        confirmColor: 'accent',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteConcept(command.id, command.clientId).subscribe({
          next: (result) => {
            this.getConcepts(command.pageNumber, command.pageSize, command.clientId);
          },
          error: (error) => {
            this.toast.openErrorToast(`[${error.status}: ${error.name}] ${error.message}`);
            console.error(error);
          },
        });
      }
    });
  }

  public terminateBulkConcept(command: TerminateMandatesCommand) {
    const dialogRef = this.dialog.open(BaseDialog, {
      data: {
        title: this.translateService.instant('DIALOG.DELETE.BULK-CONCEPT-DESCRIPTION'),
        description: '',
        cancelText: 'DIALOG.NO-CANCEL',
        confirmText: 'DIALOG.YES-DELETE',
        confirmColor: 'accent',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteConceptsInBulk({ conceptReferences: command.conceptReferences }).subscribe({
          next: (result) => {
            this.getConcepts(command.pageNumber, command.pageSize, command.clientId);
          },
          error: (error) => {
            this.toast.openErrorToast(`[${error.status}: ${error.name}] ${error.message}`);
            console.error(error);
          },
        });
      }
    });
  }

  public archiveMandates(command: { mandateReferences: string[] }) {
    return this.http.delete(`${this.baseUrl}/mandates`, {
      body: command,
    });
  }

  public archiveMandate(id: string) {
    return this.archiveMandates({ mandateReferences: [id] });
  }

  public terminateConceptsInBulk(command: { conceptReferences: string[] }) {
    return this.deleteConceptsInBulk(command);
  }

  public deleteConcept(id: string, clientId: string) {
    const headers = new HttpHeaders({
      'Client-Id': clientId,
    });
    return this.http.delete(`${this.baseUrl}/concepts/${id}`, {
      headers: headers,
    });
  }

  public deleteConceptsInBulk(command: { conceptReferences: string[] }) {
    return this.http.delete(`${this.baseUrl}/concepts`, {
      body: command,
    });
  }

  public publishConcept(conceptId: string, clientId: string, mandateSigner: MandateSignerCommand) {
    const headers = new HttpHeaders({
      'Client-Id': clientId,
    });
    return this.http.post(
      `${this.baseUrl}/concepts/${conceptId}/publish`,
      { mandateSigner },
      {
        headers: headers,
      }
    );
  }

  public getMandateById(id: string, clientId: string): Observable<Mandate> {
    const headers = new HttpHeaders({
      'Client-Id': clientId,
    });
    return this.http.get<Mandate>(`${this.baseUrl}/mandates/${id}`, {
      headers: headers,
    });
  }

  public async getMandates(
    page?: number,
    size?: number,
    status?: MandateStatus[],
    clientReference?: string,
    mandateType?: MandateType,
    clientType?: PersonType,
    clientName?: string,
    sort: Sort = { active: 'modifiedDate', direction: 'desc' }
  ): Promise<Page<MandateListItem> | undefined> {
    let headers = new HttpHeaders();
    if (clientReference) headers = headers.append('Client-Id', clientReference);

    let queryParams = this.getQueryParam(page, size, status, mandateType, clientType, clientName);

    if (sort && sort.direction)
      queryParams = queryParams.append('sort', `${sort.active},${sort.direction}`);

    const mandatePage = await this.http
      .get<Page<MandateListItem>>(`${this.baseUrl}/mandates`, {
        headers: headers,
        params: queryParams,
      })
      .toPromise();

    this.mandates$.next(mandatePage);

    if (mandatePage && mandatePage?.totalElements > this.totalAvailableMandates$.value) {
      this.totalAvailableMandates$.next(mandatePage.totalElements);
    }

    return mandatePage;
  }

  public terminateMandate(command: TerminateMandateCommand) {
    const dialogRef = this.dialog.open(BaseDialog, {
      data: {
        title: this.translateService.instant('DIALOG.DELETE.MANDATE-DESCRIPTION', {
          name: command.clientName,
        }),
        description: '',
        cancelText: 'DIALOG.NO-CANCEL',
        confirmText: 'DIALOG.YES-DELETE',
        confirmColor: 'accent',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteMandateById(command.id, command.clientId).subscribe(
          (result) => {
            this.getMandates(
              command.pageNumber,
              command.pageSize,
              command.status,
              command.clientId
            );
            if (command.redirect) this.location.back();
          },
          catchError((error) => {
            this.toast.openErrorToast(`[${error.status}: ${error.name}] ${error.message}`);
            console.error(error);
            return of(null);
          })
        );
      }
    });
  }

  public deleteMandateById(id: string, clientId: string) {
    const headers = new HttpHeaders({
      'Client-Id': clientId,
    });
    return this.http.delete(`${this.baseUrl}/mandates/${id}`, {
      headers: headers,
    });
  }

  public createMandateSigner(
    clientId: string,
    signerCommand: MandateSignerCommand
  ): Observable<MandateSigner> {
    return this.http.post<MandateSigner>(`${this.baseUrl}/mandate-signer`, signerCommand, {
      headers: new HttpHeaders({
        'Client-Id': clientId,
      }),
    });
  }

  public updateMandateSigner(
    id: string,
    signerCommand: MandateSignerCommand
  ): Observable<MandateSigner> {
    return this.http.put<MandateSigner>(`${this.baseUrl}/mandate-signer/${id}`, signerCommand);
  }

  public deleteMandateSigner(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/mandate-signer/${id}`);
  }

  public getMandateSignersByClient(clientId: string): Observable<MandateSigner[]> {
    return this.http.get<MandateSigner[]>(`${this.baseUrl}/mandate-signer`, {
      headers: new HttpHeaders({
        'Client-Id': clientId,
      }),
    });
  }

  public updateMandateMandateSigner(
    mandateId: string,
    mandateSignerId: string
  ): Observable<Mandate> {
    return this.http.put<Mandate>(
      `${this.baseUrl}/mandates/${mandateId}/mandate-signer/${mandateSignerId}`,
      null
    );
  }

  private getTranslatedMandateTypesForConcept(mandates: Mandate[]): string {
    return mandates
      .map((mandate) =>
        this.translateService.instant(`MANDATES.TYPE.${mandate.mandateType.typeName}`)
      )
      .join(', ');
  }

  private getQueryParam(
    page?: number,
    size?: number,
    status?: MandateStatus[],
    mandateType?: MandateType,
    clientType?: PersonType,
    clientName?: string
  ): HttpParams {
    let queryParams = new HttpParams();
    if (page) queryParams = queryParams.set('page', page);
    if (size) queryParams = queryParams.set('size', size);
    if (status) {
      const statusString = status.map((x) => MandateStatus[x]).join(', ');
      queryParams = queryParams.set('status', statusString);
    }
    if (mandateType) queryParams = queryParams.set('mandateTypeReference', mandateType.id);
    if (clientType) queryParams = queryParams.set('personType', clientType);
    if (clientName) queryParams = queryParams.set('clientName', clientName);

    return queryParams;
  }

  private getQueryParams(object: any): HttpParams {
    let params = Object.keys(object)
      .filter((key) => object[key] != null)
      .reduce((a, k) => ({ ...a, [k]: object[k] }), {});
    return new HttpParams({ fromObject: params });
  }

  public publishExistingConcept() {
    throw new Error('Implement callback to api');
  }

  public publishNewConcept() {
    throw new Error('Implement callback to api');
  }

  public generateMandatesExport(
    status?: MandateStatus[],
    clientReference?: string,
    mandateType?: MandateType,
    clientType?: PersonType,
    clientName?: string
  ): Observable<Blob> {
    let headers = new HttpHeaders();
    if (clientReference) headers = headers.append('Client-Id', clientReference);

    const queryParams = this.getQueryParams({
      status,
      mandateType,
      clientType,
      clientName,
    });
    return this.http.get(`${this.baseUrl}/mandates/export`, {
      headers: headers,
      params: queryParams,
      responseType: 'blob',
    });
  }

  public parseMandatesImport(file: File): Observable<MandateImportRow[]> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<MandateImportRow[]>(`${this.baseUrl}/mandates/parse-import`, formData);
  }

  public importMandates(list: MandateImportRow[], lang = 'MULTI'): Observable<MandateImportRows> {
    return this.http.post<MandateImportRows>(`${this.baseUrl}/mandates/import?lang=${lang}`, list);
  }

  public sendReminderEmail(id: string, engagementReference: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/mandates/notifications/reminder`, {
      id,
      engagementReference,
    });
  }

  public getAllFeatureToggles(): Observable<FeatureToggle[]> {
    return this.http.get<FeatureToggle[]>(`${this.baseUrl}/mandates/feature-toggles`);
  }
}
