import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { ClientDetailInfoComponent } from './clients/client-detail/client-detail-info/client-detail-info.component';
import { ClientDetailComponent } from './clients/client-detail/client-detail.component';
import { ClientsComponent } from './clients/clients.component';
import { CreateClientComponent } from './clients/create-client/create-client.component';
import { LogoutPageComponent } from './core/components/logout-page/logout-page.component';
import { CanDeactivateGuard } from './core/guards/can-deactivate.guard';
import { HasRoleAdminGuard } from './core/guards/has-role-admin.guard';
import { EmployeeDetailComponent } from './employees/employee-detail/employee-detail.component';
import { EmployeesComponent } from './employees/employees.component';
import { CreateMandateComponent } from './mandates/create-mandate/create-mandate.component';
import { DetailMandateComponent } from './mandates/detail-mandate/detail-mandate.component';
import { ImportMandatesComponent } from './mandates/import-mandates/import-mandates.component';
import { MandatesComponent } from './mandates/mandates.component';
import { SidenavComponent } from './shared/components/sidenav/sidenav.component';

const routes: Routes = [
  {
    path: 'logout',
    component: LogoutPageComponent,
  },
  {
    path: '',
    component: SidenavComponent,
    children: [
      {
        path: 'clients',
        component: ClientsComponent,
      },
      {
        path: 'create-client',
        component: CreateClientComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: 'clients/:clientId',
        component: ClientDetailComponent,
        canDeactivate: [CanDeactivateGuard],
        children: [
          {
            path: 'detail-mandate/:mandateId',
            component: DetailMandateComponent,
            canDeactivate: [CanDeactivateGuard],
          },
          { path: 'detail-mandate', redirectTo: '', pathMatch: 'full' },
          {
            path: '',
            component: ClientDetailInfoComponent,
            canDeactivate: [CanDeactivateGuard],
          },
        ],
      },
      {
        path: 'mandates',
        component: MandatesComponent,
      },
      {
        path: 'create-mandate',
        component: CreateMandateComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: 'update-mandate',
        component: CreateMandateComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: 'mandates/import',
        component: ImportMandatesComponent,
      },
      {
        path: 'employees',
        component: EmployeesComponent,
        canActivate: [HasRoleAdminGuard],
      },
      { path: 'employees/:employeeId', component: EmployeeDetailComponent },
      { path: '', redirectTo: 'clients', pathMatch: 'full' },
    ],
  },
];

const routingConfig: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routingConfig)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
