import { Component, DoCheck, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { AuthService } from './core/authentication/auth.service';
import { UserInfo } from './core/authentication/UserInfo';
import { CanDeactivateState } from './core/guards/can-deactivate.guard';
import { LanguageService } from './service/language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, DoCheck {
  isAuthenticated: boolean;
  userInfo: UserInfo | undefined;

  constructor(
    private authService: AuthService,
    private languageService: LanguageService,
    readonly router: Router
  ) {
    window.onpopstate = () => (CanDeactivateState.defendAgainstBrowserBackButton = true);

    router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        tap(() => (CanDeactivateState.defendAgainstBrowserBackButton = false))
      )
      .subscribe();
  }

  ngDoCheck() {
    const userContainerWidth = document.getElementById('userContainer')?.offsetWidth as number;
    const elements = document.querySelectorAll('.mat-menu-panel,.userMenu');
    elements.forEach((element) => {
      if (element instanceof HTMLElement) element.style.width = userContainerWidth - 4 + 'px';
    });
  }

  async ngOnInit() {
    this.languageService.use(this.languageService.getBrowserLanguage());
    this.authService.isAuthenticated$.subscribe((value) => (this.isAuthenticated = value));
    this.authService.userInfo$.subscribe((value) => (this.userInfo = value));
  }
}
