import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'mandates-mandate-information-form',
  templateUrl: './mandate-information-form.component.html',
  styleUrls: ['./mandate-information-form.component.scss'],
})
export class MandateInformationFormComponent implements OnInit {
  @Input() parentForm: UntypedFormGroup;

  constructor() {}

  ngOnInit(): void {}

  get today(): Date {
    return new Date();
  }
}
