import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PersonCommand } from '../../service/dto/PersonCommand';
import CompanyStatus from '../../service/model/CompanyStatus';
import Language from '../../service/model/Language';
import MoralPerson from '../../service/model/MoralPerson';
import PersonType from '../../service/model/PersonType';
import { CustomValidator } from '../../shared/validators/custom.validator';
import { AddressForm } from './address-form';

export default class MoralPersonForm {
  addressForm = new AddressForm();

  form = new UntypedFormGroup({
    base: new UntypedFormGroup({
      country: new UntypedFormControl('BE', [Validators.required]),
      companyNumber: new UntypedFormControl('', [Validators.required, CustomValidator.notEmpty]),
      language: new FormControl(Language.MULTI, Validators.required),
    }),
    vat: new UntypedFormGroup({
      vatNumber: new UntypedFormControl('', [
        CustomValidator.validVat,
        Validators.required,
        CustomValidator.notEmpty,
      ]),
      vatLiable: new UntypedFormControl(true, [Validators.required, CustomValidator.notEmpty]),
      vatUnit: new UntypedFormControl(false, [Validators.required, CustomValidator.notEmpty]),
      inBelgium: new UntypedFormControl(false, [Validators.required]),
      multipleRegions: new UntypedFormControl(null),
    }),
    company: new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required, CustomValidator.notEmpty]),
      companyForm: new UntypedFormControl('610', [Validators.required, CustomValidator.notEmpty]),
      status: new UntypedFormControl({ value: CompanyStatus.ACTIVE, disabled: true }, [
        Validators.required,
      ]),
      employees: new UntypedFormControl(false, [Validators.required]),
    }),
    address: this.addressForm.form,
  });

  initFromMoralPerson(person: MoralPerson) {
    this.form.patchValue({
      base: {
        country: person.country,
        companyNumber: person.companyNumber,
        language: person.language,
      },
      vat: {
        vatNumber: person.vatNumber,
        vatLiable: person.isVatObligated,
        vatUnit: person.isVatUnityMember,
        inBelgium: person.hasPermanentEstablishment,
        multipleRegions: person.activeRegions,
      },
      company: {
        name: person.companyName,
        companyForm: person.companyForm,
        status: CompanyStatus.ACTIVE,
        employees: person.hasEmployees,
      },
      address: this.addressForm.initFromAddress(person.address),
    });
  }

  toPersonDto(): PersonCommand {
    const { base, vat, company } = this.form.getRawValue();

    const { country, companyNumber, language } = base;
    const { vatNumber, vatLiable, vatUnit, inBelgium, multipleRegions } = vat;
    const { name, companyForm, status, employees } = company;

    return {
      personType: PersonType.MORAL_PERSON,
      naturalPerson: undefined,
      moralPerson: {
        country,
        companyNumber,
        companyName: name,
        companyForm,
        companyStatus: status,
        hasEmployees: employees,
        isVatUnityMember: vatUnit,
      },
      isVatObligated: vatLiable,
      vatNumber,
      hasPermanentEstablishment: inBelgium,
      activeRegions: multipleRegions,
      address: this.addressForm.toAddress(),
      language: language,
    };
  }
}
