<form [formGroup]="naturalPersonForm.form">
  <section formGroupName="person">
    <h2>{{ 'COMMON.PERSON-INFO' | translate }}</h2>

    <mandates-input-field
      [parentForm]="personForm"
      [control]="'nationality'"
      [label]="'COMMON.NATIONALITY'"
      [type]="'select'"
      [options]="nationalities"
    ></mandates-input-field>

    <mandates-input-field
      [parentForm]="personForm"
      [control]="'nationalNumber'"
      [label]="'COMMON.NATIONAL-NUMBER'"
      [type]="'digit'"
      [disabled]="!!person"
    ></mandates-input-field>

    <mandates-input-field
      [parentForm]="personForm"
      [control]="'firstName'"
      [label]="'COMMON.FIRST-NAME'"
      [type]="'text'"
    ></mandates-input-field>

    <mandates-input-field
      [parentForm]="personForm"
      [control]="'lastName'"
      [label]="'COMMON.LAST-NAME'"
      [type]="'text'"
    ></mandates-input-field>

    <mandates-input-field
      [parentForm]="personForm"
      [control]="'language'"
      [label]="'COMMON.LANGUAGE'"
      [type]="'select'"
      [options]="languages"
    ></mandates-input-field>
  </section>

  <mat-divider></mat-divider>

  <section>
    <h2>{{ 'COMMON.ADDRESS' | translate }}</h2>

    <mandates-input-field
      [parentForm]="addressForm"
      [control]="'country'"
      [label]="'COMMON.COUNTRY'"
      [type]="'select'"
      [options]="countries"
    ></mandates-input-field>

    <mandates-input-field
      [parentForm]="addressForm"
      [control]="'city'"
      [label]="'COMMON.CITY'"
      [type]="'text'"
    ></mandates-input-field>

    <mandates-input-field
      [parentForm]="addressForm"
      [control]="'zipCode'"
      [label]="'COMMON.POSTAL-CODE'"
      [type]="'text'"
    ></mandates-input-field>

    <mandates-input-field
      [parentForm]="addressForm"
      [control]="'street'"
      [label]="'COMMON.STREET'"
      [type]="'text'"
    ></mandates-input-field>

    <div class="container">
      <mandates-input-field
        [parentForm]="addressForm"
        [control]="'streetNumber'"
        [label]="'COMMON.STREET-NUMBER'"
        [type]="'text'"
      ></mandates-input-field>

      <mandates-input-field
        [parentForm]="addressForm"
        [control]="'box'"
        [label]="'COMMON.BUS-NUMBER'"
        [type]="'text'"
      ></mandates-input-field>
    </div>
  </section>

  <mat-divider></mat-divider>

  <section formGroupName="vat">
    <h2>{{ 'COMMON.VAT-INFORMATION' | translate }}</h2>

    <mandates-input-field
      [parentForm]="vatForm"
      [control]="'vatLiable'"
      [label]="'COMMON.VAT-LIABLE'"
      [type]="'select'"
      [options]="booleanOptions"
    ></mandates-input-field>

    <mandates-input-field
      [parentForm]="vatForm"
      [control]="'companyNumber'"
      [label]="'COMMON.COMPANY-NUMBER'"
      [type]="'digit'"
    ></mandates-input-field>

    <mandates-input-field
      [parentForm]="vatForm"
      [control]="'vatNumber'"
      [label]="'COMMON.VAT-NUMBER'"
      [type]="'text'"
    ></mandates-input-field>

    <mandates-input-field
      [parentForm]="vatForm"
      [control]="'inBelgium'"
      [label]="'COMMON.PERM-IN-BE'"
      [type]="'select'"
      [options]="booleanOptions"
    ></mandates-input-field>

    <mandates-input-field
      [parentForm]="vatForm"
      [control]="'multipleRegions'"
      [label]="'COMMON.ACTIVE-IN-BELGIAN-REGIONS'"
      [type]="'select'"
      [options]="regionOptions"
      [multiple]="true"
    ></mandates-input-field>
  </section>
</form>

<mat-divider></mat-divider>

<section class="actions">
  <button mat-raised-button color="warn" (click)="openCancelDialog()">
    <mat-icon>cancel</mat-icon>
    {{ 'BUTTON.CANCEL' | translate }}
  </button>
  <button mat-raised-button (click)="save()">
    {{ (person ? 'BUTTON.SAVE' : 'BUTTON.CREATE') | translate }}
    <mat-icon>chevron_right</mat-icon>
  </button>
</section>
