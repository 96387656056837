<article>
  <h2 nat-dialog-title>
    {{ 'MANDATES.IMPORT.INVALID.TITLE' | translate: { number: data.length } }}
  </h2>

  <section mat-dialog-content>
    <mandates-invalid-import-mandates-list [forms]="data"></mandates-invalid-import-mandates-list>
    <div class="notice">
      <span>
        <b>{{ 'MANDATES.IMPORT.INVALID.WARNING' | translate }}</b>
      </span>
      <span>{{ 'MANDATES.IMPORT.INVALID.WARNING-SUB' | translate }}</span>
    </div>
  </section>

  <section mat-dialog-actions>
    <button mat-flat-button [mat-dialog-close]="false" color="accent">
      <mat-icon>keyboard_backspace</mat-icon>
      {{ 'BUTTON.BACK' | translate }}
    </button>
    <button mat-raised-button class="orange" [mat-dialog-close]="true">
      <mat-icon>upload</mat-icon>
      {{ 'BUTTON.IMPORT' | translate }}
    </button>
  </section>
</article>
