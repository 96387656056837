import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BaseDialog } from '../../shared/components/base-dialog/base-dialog';

export interface ComponentCanDeactivate {
  canDeactivate(): {
    shouldDeactivate: boolean;
    data: {
      title: string;
      description: string;
      cancelText: string;
      confirmText: string;
      confirmColor: string;
    };
    maxWidth?: number | string;
  };
}

export const CanDeactivateState = {
  defendAgainstBrowserBackButton: false,
};

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivate> {
  constructor(readonly matDialog: MatDialog) {}

  canDeactivate(component: ComponentCanDeactivate) {
    const { shouldDeactivate: canDeactivate, data, maxWidth } = component.canDeactivate();
    return (
      canDeactivate ||
      this.matDialog
        .open(BaseDialog, {
          data,
          disableClose: true,
          maxWidth: maxWidth || '80vw',
        })
        .afterClosed()
        .pipe(
          tap((confirmed) => {
            if (!confirmed && CanDeactivateState.defendAgainstBrowserBackButton) {
              history.pushState(null, '', '');
            }
          })
        )
    );
  }
}
