import { Component, Input, OnInit } from '@angular/core';
import Mandate from 'src/app/service/model/Mandate';
import MandateStatus from '../../../service/model/MandateStatus';

@Component({
  selector: 'mandates-mandate-status-stepper',
  templateUrl: './mandate-status-stepper.component.html',
  styleUrls: ['./mandate-status-stepper.component.scss'],
})
export class MandateStatusStepperComponent implements OnInit {
  @Input() mandate?: Mandate;

  steps: { status: MandateStatus }[];
  activeIndex: number;

  constructor() {}

  ngOnInit(): void {
    if (this.mandate?.status === MandateStatus.DECLINED) {
      this.steps = [
        { status: MandateStatus.TO_SEND },
        { status: MandateStatus.SENDING },
        { status: MandateStatus.SENT },
        { status: MandateStatus.AWAITING_APPROVAL },
        { status: MandateStatus.DECLINED },
        { status: MandateStatus.TERMINATED },
      ];
    } else {
      this.steps = [
        { status: MandateStatus.TO_SEND },
        { status: MandateStatus.SENDING },
        { status: MandateStatus.SENT },
        { status: MandateStatus.AWAITING_APPROVAL },
        { status: MandateStatus.ACTIVE },
        { status: MandateStatus.TERMINATED },
      ];
    }
    this.activeIndex = this.steps.findIndex((step) => step.status === this.mandate?.status);
  }
}
