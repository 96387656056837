import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ClientsApiService } from 'src/app/core/clients-api/clients-api.service';
import { EmployeesApiService } from 'src/app/core/employees-api/employees-api.service';
import { MandatesApiService } from 'src/app/core/mandates-api/mandates-api.service';
import Client from 'src/app/service/model/Client';
import PersonType from 'src/app/service/model/PersonType';

@Component({
  selector: 'mandates-mandate-client-search',
  templateUrl: './mandate-client-search.component.html',
  styleUrls: ['./mandate-client-search.component.scss'],
})
export class MandateClientSearchComponent implements OnInit, AfterViewInit {
  @Input() defaultPersonType?: PersonType;
  @Output() onClientSelected = new EventEmitter<Client>();

  clients: Client[] = [];
  totalClients: number = 0;
  isLoading: boolean = false;
  searchTimeout: NodeJS.Timeout;

  readonly MAX_VISIBLE_CLIENTS = 32;

  filters: {
    name: string;
    options: { name: string; value: string }[];
    controlName: string;
    isSearchFilter?: boolean;
  }[] = [
    {
      name: 'LIST.CLIENTTYPE',
      options: [
        {
          name: 'PERSON_TYPE.NATURAL_PERSON',
          value: PersonType.NATURAL_PERSON,
        },
        {
          name: 'PERSON_TYPE.MORAL_PERSON',
          value: PersonType.MORAL_PERSON,
        },
      ],
      controlName: 'personType',
    },
    { name: 'COMMON.EMPLOYEE', options: [], controlName: 'createdByEmployee' },
    {
      name: 'LIST.WITHOUT-MANDATE',
      options: [],
      controlName: 'mandateType',
    },
    {
      name: 'SEARCH.CLIENT-KEYWORD-PLACEHOLDER',
      options: [],
      controlName: 'keyword',
      isSearchFilter: true,
    },
  ];

  form: UntypedFormGroup = new UntypedFormGroup({});

  constructor(
    private clientsApiService: ClientsApiService,
    private employeeApiService: EmployeesApiService,
    private mandateApiService: MandatesApiService
  ) {}

  ngOnInit(): void {
    this.form.valueChanges.subscribe((response) => {
      this.handleValueChanges(response);
    });

    this.employeeApiService.getEmployees().subscribe((response) => {
      const employees = response.map((employee) => ({
        name: `${employee.firstName} ${employee.lastName}`,
        value: employee.id,
      }));

      const index = this.getFilterIndex('createdByEmployee');
      this.filters[index].options = employees;
    });

    this.mandateApiService.getMandateTypes().subscribe((response) => {
      const mandateTypes = response.map((type) => ({
        name: `MANDATES.TYPE.${type.typeName}`,
        value: type.id,
      }));

      const index = this.getFilterIndex('mandateType');
      this.filters[index].options = mandateTypes;
    });
  }

  ngAfterViewInit(): void {
    if (this.defaultPersonType) {
      switch (this.defaultPersonType) {
        case PersonType.NATURAL_PERSON:
          this.form.setControl('personType', new UntypedFormControl(PersonType.NATURAL_PERSON));
          break;
        case PersonType.MORAL_PERSON:
          this.form.setControl('personType', new UntypedFormControl(PersonType.MORAL_PERSON));
          break;
        default:
          this.form.updateValueAndValidity();
          break;
      }
    }
  }

  handleClientClick(client: Client) {
    this.onClientSelected.emit(client);
  }

  private search(
    searchKeyword?: string,
    personType?: PersonType,
    employee?: string,
    mandateType?: string
  ) {
    this.isLoading = true;
    this.clientsApiService
      .getClients(
        0,
        this.MAX_VISIBLE_CLIENTS,
        searchKeyword,
        personType,
        employee,
        undefined,
        mandateType
      )
      .subscribe((response) => {
        this.clients = response.content.slice(0, this.MAX_VISIBLE_CLIENTS);
        this.totalClients = response.totalElements;
        this.isLoading = false;
      });
  }

  private handleValueChanges(event: {
    keyword: string | undefined;
    personType: any;
    createdByEmployee: any;
    mandateType: any;
  }) {
    if (event.keyword && event.keyword.length <= 2) return;

    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = setTimeout(() => {
      this.search(event.keyword, event.personType, event.createdByEmployee, event.mandateType);
    }, 350);
  }

  get filteredClients(): Client[] {
    return this.clients.map(
      (client) =>
        new Client({
          id: client.id,
          person: client.person,
          engagementType: client.engagementType,
          createdBy: client.createdBy,
        })
    );
  }

  get isAboveLimit(): boolean {
    return this.totalClients > this.clients.length;
  }

  private getFilterIndex(controlName: string): number {
    return this.filters.findIndex((filter) => filter.controlName === controlName);
  }
}
