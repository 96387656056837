import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

export class PaginatorI18n {
  constructor(private readonly translate: TranslateService) {}

  public getPaginatorIntl(): MatPaginatorIntl {
    const paginatorIntl = new MatPaginatorIntl();

    this.translate.stream('paginator.paginatorIntl').subscribe((dict) => {
      Object.assign(paginatorIntl, dict);
      paginatorIntl.changes.next();
    });

    const originalGetRangeLabel = paginatorIntl.getRangeLabel;
    paginatorIntl.getRangeLabel = (page: number, size: number, len: number) => {
      let pageNumber;
      if (len === 0) {
        return `${this.translate.instant('paginator.page-of-total', {
          number: 1,
          total: 1,
        })} (${originalGetRangeLabel(page, size, len).replace(
          'of',
          this.translate.instant('paginator.of')
        )})`;
      }
      const totalPages = Math.ceil(len / size);
      return `${this.translate.instant('paginator.page-of-total', {
        number: page + 1,
        total: totalPages,
      })} (${originalGetRangeLabel(page, size, len).replace(
        'of',
        this.translate.instant('paginator.of')
      )})`;
    };

    return paginatorIntl;
  }
}
