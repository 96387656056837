<div class="page">
  <img class="logo" src="assets/logo/itaa-logo.png" alt="ITAA" />
  <div class="card">
    <h2>{{ 'LOGOUT-PAGE.LOGGED-OUT' | translate }}</h2>
    <p>{{ 'LOGOUT-PAGE.PARAGRAPH-1' | translate }}</p>
    <p>
      {{ 'LOGOUT-PAGE.PARAGRAPH-2' | translate }}
    </p>
    <a mat-raised-button color="primary" [href]="itaaPortalUrl">{{
      'LOGOUT-PAGE.SIGN-IN' | translate
    }}</a>
  </div>
</div>
