<section class="select-all-btn-container" *ngIf="!isSelectingAll && hasSelectedAllOfCurrentPage()">
  <span>{{
    'TABLE.ALL-COUNT-OF-PAGE-SELECTED'
      | translate
        : {
            count: selection.selected.length,
            items: 'COMMON.MANDATES' | translate | lowercase
          }
  }}</span>
  <span class="clickable" (click)="fetchAll()">
    {{
      'TABLE.SELECT-ALL-COUNT-THAT-MATCH'
        | translate
          : {
              count: page.totalElements,
              items: 'COMMON.MANDATES' | translate | lowercase
            }
    }}
  </span>
</section>
<mandates-table
  [displayedColumns]="displayedColumns"
  [pageSizeOptions]="[10, 20]"
  [dataSource]="dataSource"
  [selection]="selection"
  uuidRef="id"
  (pageChange)="handlePageEvent($event)"
  [totalAvailable]="totalAvailableRows"
  [elementDisplayName]="'COMMON.MANDATES' | translate"
  [page]="page"
  [color]="'primary'"
  [showFirstLastButtons]="true"
  [isSelectingAll]="isSelectingAll"
  matSort
  (matSortChange)="handleSortChange($event)"
>
  <!-- ClientType Column -->
  <ng-container matColumnDef="clientType">
    <th mat-header-cell *matHeaderCellDef matTooltip="{{ 'LIST.CLIENTTYPE' | translate }}">
      {{ 'LIST.TYPE' | translate }}
    </th>
    <td mat-cell *matCellDef="let row">
      <mandates-client-icon [type]="row.clientType"></mandates-client-icon>
    </td>
  </ng-container>
  <ng-container matColumnDef="clientName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'LIST.NAME' | translate }}
    </th>
    <td mat-cell *matCellDef="let row">{{ row.clientName }}</td>
  </ng-container>

  <!-- MandateType Column -->
  <ng-container matColumnDef="mandateTypes">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="mandateType.typeName">
      {{ 'LIST.MANDATE-TYPE' | translate }}
    </th>
    <td mat-cell *matCellDef="let row">
      {{ 'MANDATES.TYPE.' + row.mandateTypes | translate }}
    </td>
  </ng-container>

  <!-- Last Opened Column -->
  <ng-container matColumnDef="lastOpened">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="modifiedDate">
      {{ 'LIST.LAST-OPENED' | translate }}
    </th>
    <td mat-cell *matCellDef="let row">
      {{ row.lastOpened | date : 'dd/MM/yyyy' }}
    </td>
  </ng-container>

  <!-- Status Column -->
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'LIST.STATUS' | translate }}
    </th>
    <td
      mat-cell
      *matCellDef="let row"
      class="status-row"
      [ngClass]="{
        sent: row.status === 'SENT',
        error: row.status === 'ERROR',
        active: row.status === 'ACTIVE',
        awaiting_approval: row.status === 'AWAITING_APPROVAL',
        terminated: row.status === 'TERMINATED',
        declined: row.status === 'DECLINED'
      }"
    >
      <span class="status-info">
        <mat-icon *ngIf="row.status === 'DRAFT'" inline>radio_button_checked</mat-icon>
        {{ 'MANDATES.' + row.status.toString() | translate }}
      </span>
    </td>
  </ng-container>

  <!-- Actions Column -->
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'LIST.ACTIONS' | translate }}
    </th>
    <td mat-cell *matCellDef="let row">
      <div class="action-buttons">
        <button *ngIf="row.status != 'DRAFT'" mat-icon-button (click)="onInfoClick(row)">
          <mat-icon>info</mat-icon>
        </button>
        <button
          [disabled]="canSendReminder(row) || !row?.mandateSigner"
          *ngIf="row.status == 'AWAITING_APPROVAL'"
          mat-icon-button
          class="reminder-btn"
          color="info"
          (click)="onSendReminder(row)"
        >
          <mat-icon matTooltip="{{ 'MANDATES.HOVER.REMINDER' | translate }}">
            forward_to_inbox
          </mat-icon>
        </button>
        <button
          [disabled]="!row?.mandateSigner"
          *ngIf="
            row.status == 'DRAFT' || row.status == 'AWAITING_APPROVAL' || row.status == 'ACTIVE'
          "
          mat-icon-button
          class="delete-btn"
          color="warn"
          (click)="onDeleteClick(row)"
        >
          <mat-icon matTooltip="{{ 'MANDATES.HOVER.DISABLED' | translate }}">
            delete_forever
          </mat-icon>
        </button>

        <button mat-icon-button *ngIf="canArchiveRow(row)" (click)="onArchiveClick(row)">
          <mat-icon matTooltip="{{ 'TOOLTIP.ARCHIVE-MANDATE' | translate }}">archive</mat-icon>
        </button>
      </div>
    </td>
  </ng-container>
</mandates-table>
