import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { BulkClientFormGroupType, MandatesBulkForm } from 'src/app/clients/form/bulk-concept-form';
import Person from 'src/app/service/model/Person';
import PersonType from 'src/app/service/model/PersonType';

@Component({
  selector: 'mandates-publish-bulk-mandate-dialog',
  templateUrl: './publish-bulk-mandate-dialog.html',
  styleUrls: ['./publish-bulk-mandate-dialog.scss'],
})
export class PublishBulkMandateDialog implements OnInit {
  focusTab: number = 0;

  constructor(
    public dialogRef: MatDialogRef<PublishBulkMandateDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
    this.focusTab = this.hasNaturalClients() ? 0 : 1;
  }

  hasNaturalClients(): boolean {
    return this.data.naturalClientForms.length > 0;
  }

  hasMoralClients(): boolean {
    return this.data.moralClientForms.length > 0;
  }

  get naturalClientForms(): FormGroup<BulkClientFormGroupType>[] {
    return this.data.naturalClientForms;
  }

  get moralClientForms(): FormGroup<BulkClientFormGroupType>[] {
    return this.data.moralClientForms;
  }

  get naturalMandateForms(): FormGroup<MandatesBulkForm>[] {
    return this.data.naturalMandateForms;
  }

  get moralMandateForms(): FormGroup<MandatesBulkForm>[] {
    return this.data.moralMandateForms;
  }

  getClientName(clientForm: FormGroup<BulkClientFormGroupType>): string {
    return clientForm.controls.client.value?.person.getScreenName();
  }

  getPersonType(clientForm: FormGroup<BulkClientFormGroupType>): PersonType {
    return clientForm.controls.client.value?.person.getPersonType();
  }

  getSignerName(clientForm: FormGroup<BulkClientFormGroupType>): string {
    const { firstName, lastName } = clientForm.controls.mandateSigner.value || {};
    return `${firstName} ${lastName}`;
  }

  getMandateName(mandateForm: FormGroup<MandatesBulkForm>): string {
    return mandateForm.controls.mandateTypeName.value;
  }

  getMandateEndDate(mandateForm: FormGroup<MandatesBulkForm>): string {
    return mandateForm.controls.endDate.value;
  }

  hasEndDate(mandateForm: FormGroup<MandatesBulkForm>): boolean {
    return mandateForm.controls.endDate.value !== null;
  }

  focusChangeHandler(event: MatTabChangeEvent) {
    this.focusTab = event.index;
  }
}

interface DialogData {
  naturalClientForms: FormGroup<BulkClientFormGroupType>[];
  moralClientForms: FormGroup<BulkClientFormGroupType>[];
  naturalMandateForms: FormGroup<MandatesBulkForm>[];
  moralMandateForms: FormGroup<MandatesBulkForm>[];
}
