import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {}

  public openErrorToast(
    error: string,
    title: string = 'TOAST.ERROR.TITLE',
    override?: Partial<IndividualConfig>
  ) {
    this.toastr.error(error, title ? this.translateService.instant(title) : '', override);
  }

  public openWarningToast(
    warning: string,
    title: string | undefined = 'TOAST.WARNING',
    override?: Partial<IndividualConfig>
  ) {
    this.toastr.warning(warning, title ? this.translateService.instant(title) : '', override);
  }

  public openSuccessToast(
    success: string,
    title: string | undefined = 'TOAST.SUCCESS',
    override?: Partial<IndividualConfig>
  ) {
    this.toastr.success(success, title ? this.translateService.instant(title) : '', override);
  }

  public openValidationToast() {
    this.openWarningToast('', this.translateService.instant('TOAST.WARNING-INVALID-INPUT'));
  }

  public openClientCreatedToast(name: string) {
    this.openSuccessToast(
      this.translateService.instant('TOAST.CLIENT-CREATED-INFO', {
        value: name,
      }),
      '',
      { timeOut: 10000 }
    );
  }

  public openClientUpdatedToast(name: string) {
    this.openSuccessToast(
      this.translateService.instant('TOAST.CLIENT-UPDATED-INFO', {
        value: name,
      }),
      '',
      { timeOut: 10000 }
    );
  }

  public openConceptCreatedToast(clientName: string) {
    this.openSuccessToast(
      this.translateService.instant('TOAST.CONCEPT-CREATED-INFO', {
        value: clientName,
      }),
      '',
      { timeOut: 10000 }
    );
  }

  public openConceptsCreatedToast() {
    this.openSuccessToast(this.translateService.instant('TOAST.CONCEPTS-CREATED-INFO'), '', {
      timeOut: 10000,
    });
  }

  public openConceptUpdatedToast(clientName: string) {
    this.openSuccessToast(
      this.translateService.instant('TOAST.CONCEPT-UPDATED-INFO', {
        value: clientName,
      }),
      '',
      { timeOut: 10000 }
    );
  }

  public openMandatePublishedToast(isMultiple: boolean) {
    this.openSuccessToast(
      this.translateService.instant(
        isMultiple
          ? 'TOAST.MANDATES-REQUESTED-SUCCESSFULLY'
          : 'TOAST.MANDATE-REQUESTED-SUCCESSFULLY'
      ),
      '',
      { timeOut: 10000 }
    );
  }
}
