import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../service/language.service';

@Injectable()
export class LanguageHeaderInterceptor implements HttpInterceptor {
  constructor(private languageService: LanguageService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes(environment.baseUrl)) {
      request = request.clone({
        headers: request.headers.set('Accept-Language', this.languageService.getLanguage()),
      });
    }
    return next.handle(request);
  }
}
