import { Component, Input, OnInit } from '@angular/core';
import {
  MandatesApiService,
  TerminateMandateCommand,
} from '../../core/mandates-api/mandates-api.service';
import MandateListItem from '../../service/dto/MandateListItem';
import Client from '../../service/model/Client';
import MandateStatus from '../../service/model/MandateStatus';
import { ToastService } from 'src/app/service/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mandates-mandates-kanban',
  templateUrl: './mandates-kanban.component.html',
  styleUrls: ['./mandates-kanban.component.scss'],
})
export class MandatesKanbanComponent implements OnInit {
  @Input() client?: Client;
  @Input() status: MandateStatus[];

  statusPending: MandateStatus[] = [
    MandateStatus.SENDING,
    MandateStatus.TO_SEND,
    MandateStatus.SENT,
    MandateStatus.AWAITING_APPROVAL,
  ];
  statusDeclined: MandateStatus[] = [MandateStatus.DECLINED, MandateStatus.ERROR];
  statusActive: MandateStatus[] = [MandateStatus.ACTIVE];
  statusTerminated: MandateStatus[] = [MandateStatus.TERMINATED];
  mandates: MandateListItem[] = [];

  kanbanColums = [
    { filter: this.statusPending, title: 'MANDATES.AWAITING_APPROVAL_HEADER' },
    { filter: this.statusDeclined, title: 'MANDATES.DECLINED' },
    { filter: this.statusActive, title: 'MANDATES.ACTIVE' },
    { filter: this.statusTerminated, title: 'MANDATES.TERMINATED' },
  ];

  constructor(
    private mandatesApiService: MandatesApiService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.mandatesApiService.mandates$.subscribe((result) => {
      this.mandates = result?.content || [];
    });
  }

  terminateMandate(mandate: MandateListItem) {
    let terminateMandateCommand: TerminateMandateCommand = new TerminateMandateCommand();
    terminateMandateCommand.id = mandate.id;
    terminateMandateCommand.pageNumber = 0;
    terminateMandateCommand.pageSize = 10000;
    terminateMandateCommand.redirect = false;
    terminateMandateCommand.clientName = mandate.clientName || undefined;
    terminateMandateCommand.clientId = mandate.clientReference;
    terminateMandateCommand.status = this.status;

    this.mandatesApiService.terminateMandate(terminateMandateCommand);
  }

  archiveMandate(mandate: MandateListItem) {
    this.mandatesApiService.archiveMandate(mandate.id).subscribe({
      next: () => {
        this.toastService.openSuccessToast(
          this.translateService.instant('TOAST.MANDATE-ARCHIVED.SUCCESS')
        );
        this.mandatesApiService.getMandates(0, 10000, this.status, mandate.clientReference);
      },
      error: (error) => {
        console.error(error);
        this.toastService.openErrorToast(
          this.translateService.instant('TOAST.MANDATE-ARCHIVED.ERROR')
        );
      },
    });
  }
}
