<section *ngIf="!selectedValue; else selectedValueSection" class="main">
  <mat-form-field
    *ngIf="filter.isSearchFilter; else menuFilter"
    appearance="outline"
    class="filter-item search-item"
  >
    <mat-label>
      {{ filter.name | translate }}
    </mat-label>
    <input
      type="search"
      matInput
      [(ngModel)]="searchValue"
      [placeholder]="filter.name | translate"
      (keyup.enter)="onSearchClick()"
    />
    <button mat-icon-button matSuffix (click)="onSearchClick()" [disabled]="!isValidSearchValue">
      <mat-icon>search</mat-icon>
    </button>
  </mat-form-field>

  <ng-template #menuFilter>
    <button
      class="filter-item"
      mat-raised-button
      [class.active]="isOpen"
      [matMenuTriggerFor]="menu"
      #menuOption="matMenuTrigger"
      (menuOpened)="(menuOption.menuOpen)"
      (menuClosed)="(menuOption.menuOpen)"
      [class.active]="menuOption.menuOpen"
    >
      <span>
        {{ filter.name | translate }}
      </span>
      <mat-icon>{{ menuOption.menuOpen ? 'expand_more' : 'expand_less' }}</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <button mat-menu-item *ngFor="let option of filter.options" (click)="saveSelection(option)">
        {{ option.name | translate }}
      </button>
    </mat-menu>
  </ng-template>
</section>

<ng-template #selectedValueSection>
  <section>
    <button class="result-item active" mat-button (click)="saveSelection()">
      <span>
        {{ selectedValue.name | translate }}
      </span>
      <mat-icon>cancel</mat-icon>
    </button>
  </section>
</ng-template>
