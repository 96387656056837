<mat-toolbar id="header" class="toolbar" *ngIf="userInfo">
  <div class="office-container">
    <mat-icon>corporate_fare</mat-icon>
    <div class="office-text-container">
      <span>{{ userInfo.officeName }}</span>
      <span class="role">{{ 'EMPLOYEES.' + userInfo.role | translate }}</span>
    </div>
  </div>

  <div [matMenuTriggerFor]="menu" class="user-container" id="userContainer">
    <span>{{ userInfo.firstName }} {{ userInfo.lastName }}</span>
    <button mat-icon-button>
      <mat-icon>expand_more</mat-icon>
    </button>
  </div>
  <mat-menu #menu="matMenu" class="user-menu" xPosition="before" yPosition="below">
    <button mat-menu-item (click)="logout()" class="user-menu-item">
      <mat-icon>power_settings_new</mat-icon>
      <span>{{ 'AUTHENTICATION.LOGOUT' | translate }}</span>
    </button>
  </mat-menu>
</mat-toolbar>
