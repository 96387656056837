import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import MandateListItem from '../../../service/dto/MandateListItem';
import { TranslateService } from '@ngx-translate/core';
import MandateStatus from 'src/app/service/model/MandateStatus';

@Component({
  selector: 'mandates-kanban-mandate-item',
  templateUrl: './kanban-mandate-item.component.html',
  styleUrls: ['./kanban-mandate-item.component.scss'],
})
export class KanbanMandateItemComponent implements OnInit {
  @Input() mandate?: MandateListItem;
  @Output() terminateMandate = new EventEmitter<MandateListItem>();
  @Output() archiveMandate = new EventEmitter<MandateListItem>();

  constructor(
    private router: Router,
    private translateService: TranslateService
  ) {}
  ngOnInit(): void {}

  onInfoClick(): void {
    this.router.navigate([
      'clients',
      this.mandate?.clientReference,
      'detail-mandate',
      this.mandate?.id,
    ]);
  }

  onDeleteClick(): void {
    this.terminateMandate.emit(this.mandate);
  }

  onArchiveClick(): void {
    this.archiveMandate.emit(this.mandate);
  }

  getTooltip(): string {
    if (!this.mandate?.mandateSigner) {
      return this.translateService.instant('MANDATES.HOVER.DISABLED');
    }
    return '';
  }

  canArchive(): boolean {
    return (
      this.mandate?.status === MandateStatus.TERMINATED ||
      this.mandate?.status === MandateStatus.ERROR ||
      this.mandate?.status === MandateStatus.DECLINED
    );
  }
}
