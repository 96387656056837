<mat-card *ngIf="client" class="client-card">
  <mat-card-title>
    {{ client.person.getScreenName() }}
    <mandates-client-icon
      [type]="client.person.getPersonType()"
      [inline]="true"
    ></mandates-client-icon>
  </mat-card-title>
  <mat-card-content>
    <div class="client-card-content-client-info">
      <div>
        {{ 'PERSON_TYPE.' + client.person.getPersonType() | translate }}
      </div>
      <div>{{ street }}</div>
    </div>
    <div class="client-card-content-client-info">
      <div>{{ client.person.getScreenNumber() }}</div>
      <div>{{ city }}</div>
    </div>
    <div class="client-card-content-client-info-country">
      <div>{{ '(' + countryName + ')' }}</div>
    </div>
  </mat-card-content>
</mat-card>
