import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import EmploymentRoleType from '../../service/model/EmploymentRoleType';
import { AuthService } from '../authentication/auth.service';

@Injectable({
  providedIn: 'root',
})
export class HasRoleAdminGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.userInfo?.role === EmploymentRoleType.ADMIN;
  }
}
