<section>
  <h2 nat-dialog-title>{{ 'DIALOG.IMPORT.TITLE' | translate }}</h2>

  <section mat-dialog-content class="content">
    <p class="paragraph">
      {{ 'DIALOG.IMPORT.PARAGRAPH' | translate }}
    </p>
    <div class="actions">
      <mat-spinner></mat-spinner>
    </div>
  </section>
</section>
