<header class="header mat-elevation-z2">
  <mandates-return-button routerLink="/employees"></mandates-return-button>
  <div class="employee-container">
    <div class="employee-name" *ngIf="employee">
      <mat-icon>group</mat-icon>
      <h1>
        {{ employeeName }}
      </h1>
    </div>
  </div>
</header>

<section class="">
  <mat-tab-group class="custom-tabs">
    <mat-tab [label]="'COMMON.CLIENTS' | translate">
      <div class="table-container" *ngIf="!isLoading">
        <table mat-table [dataSource]="clients">
          <ng-container matColumnDef="clientType">
            <th mat-header-cell *matHeaderCellDef matTooltip="{{ 'LIST.CLIENTTYPE' | translate }}">
              {{ 'LIST.TYPE' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">
              <mandates-client-icon [type]="row.type"></mandates-client-icon>
            </td>
          </ng-container>
          <ng-container matColumnDef="clientName">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'LIST.NAME' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">{{ row.name }}</td>
          </ng-container>
          <ng-container matColumnDef="number">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'LIST.CLIENT-NUMBER' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">{{ row.number }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            [routerLink]="['/clients/' + row.id]"
          ></tr>
        </table>
      </div>

      <mat-paginator
        [length]="paginatedClients.totalElements"
        [pageIndex]="paginatedClients.number"
        [pageSize]="paginatedClients.size"
        [pageSizeOptions]="[10, 20]"
        (page)="handlePageEvent($event)"
      ></mat-paginator>
    </mat-tab>
  </mat-tab-group>
</section>
