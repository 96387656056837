export default class Address {
  country: string;
  city: string;
  zipCode: string;
  street: string;
  streetNumber: string;
  box: string | null;

  constructor({
    country,
    city,
    zipCode,
    street,
    streetNumber,
    box,
  }: {
    country: string;
    city: string;
    zipCode: string;
    street: string;
    streetNumber: string;
    box: string | null;
  }) {
    this.country = country;
    this.city = city;
    this.zipCode = zipCode;
    this.street = street;
    this.streetNumber = streetNumber;
    this.box = box;
  }
}
