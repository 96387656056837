import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MandatesApiService,
  TerminateMandateCommand,
} from '../../core/mandates-api/mandates-api.service';
import Mandate from 'src/app/service/model/Mandate';
import Client from 'src/app/service/model/Client';
import { ClientsApiService } from 'src/app/core/clients-api/clients-api.service';
import MandateSigner from '../../service/model/MandateSigner';
import NaturalPerson from '../../service/model/NaturalPerson';
import PersonType from '../../service/model/PersonType';
import { ComponentCanDeactivate } from '../../core/guards/can-deactivate.guard';
import MandateStatus from 'src/app/service/model/MandateStatus';
import { ToastService } from 'src/app/service/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { error } from 'console';
import { StorageService } from 'src/app/service/storage.service';

@Component({
  selector: 'mandates-detail-mandate',
  templateUrl: './detail-mandate.component.html',
  styleUrls: ['./detail-mandate.component.scss'],
})
export class DetailMandateComponent implements OnInit, ComponentCanDeactivate {
  isLinear = false;
  mandate?: Mandate;
  clientId: string;
  client: Client;
  didRemoveSigner = false;

  form: UntypedFormGroup = this.fb.group({
    mandateType: [{ value: null, disabled: true }],
    mandateTypeName: [{ value: null, disabled: true }],
    endDate: [{ value: null, disabled: true }, Validators.required],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private location: Location,
    private mandatesApiService: MandatesApiService,
    private clientApiService: ClientsApiService,
    private toast: ToastService,
    private translateService: TranslateService,
    private toastService: ToastService,
    private storageService: StorageService
  ) {}

  ngOnInit(): void {
    const clientId = this.route.snapshot.paramMap.get('clientId');
    const mandateId = this.route.snapshot.paramMap.get('mandateId');

    if (clientId && mandateId) {
      this.mandatesApiService.getMandateById(mandateId, clientId).subscribe((result) => {
        this.mandate = result;
        this.mandate.mandateType.isSelected = true;
        this.clientApiService.getClientById(clientId).subscribe((client) => (this.client = client));
        this.form.patchValue(this.mandate);
      });
    }
  }

  get mandateSignerHeading(): string {
    return this.mandate?.mandateSigner
      ? 'MANDATES.SELECTED-MANDATE-SIGNER'
      : 'MANDATES.FILL-MANDATE-SIGNER';
  }

  get naturalPerson(): NaturalPerson | null {
    if (this.client!.person.getPersonType() === PersonType.NATURAL_PERSON) {
      return this.client!.person as NaturalPerson;
    }
    return null;
  }

  canDeactivate() {
    return {
      shouldDeactivate: !this.didRemoveSigner,
      data: {
        title: '',
        description: 'DIALOG.CANCEL-FORM.IMPORT',
        cancelText: 'DIALOG.NO-CANCEL',
        confirmText: 'DIALOG.YES-CONTINUE',
        confirmColor: 'accent',
      },
      maxWidth: '20vw',
    };
  }

  returnPreviousPage() {
    this.location.back();
  }

  terminateMandate() {
    if (this.mandate && this.mandate.mandateSigner) {
      let terminateMandateCommand: TerminateMandateCommand = new TerminateMandateCommand();
      terminateMandateCommand.id = this.mandate.id;
      terminateMandateCommand.pageNumber = 0;
      terminateMandateCommand.pageSize = 10000;
      terminateMandateCommand.redirect = true;
      terminateMandateCommand.clientName = this.client.person.getScreenName() || undefined;
      terminateMandateCommand.clientId = this.client.id;

      this.mandatesApiService.terminateMandate(terminateMandateCommand);
    }
  }

  canSendReminder() {
    return (
      !!this.storageService.getItemWithExpiry(this.mandate?.id || '') && this.mandate?.mandateSigner
    );
  }

  onSendReminder() {
    if (this.mandate && this.mandate.mandateSigner) {
      this.mandatesApiService
        .sendReminderEmail(this.mandate.id, this.mandate.mandateSigner.engagementReference)
        .subscribe({
          next: () => {
            this.toastService.openSuccessToast(
              this.translateService.instant('TOAST.REMINDER.SUCCESS')
            );
            this.storageService.setItemWithExpiry(this.mandate!!.id, true);
          },
          error: (error) => {
            console.error(error);
            this.toastService.openErrorToast(this.translateService.instant('TOAST.REMINDER.ERROR'));
          },
        });
    }
  }

  handleOnToggle(isOpen: boolean) {}

  removeMandateSigner() {
    if (this.mandate) {
      this.didRemoveSigner = true;
      this.mandate.mandateSigner = undefined;
    } else {
      console.error('No mandate available');
    }
  }

  onSignerSaved(signer: MandateSigner) {
    if (this.mandate) {
      this.mandate.mandateSigner = signer;
      this.mandatesApiService
        .updateMandateMandateSigner(this.mandate.id, signer.id)
        .subscribe((mandate) => (this.mandate = mandate));
    } else {
      console.error('No mandate available');
    }
  }

  canDelete(): boolean {
    if (this.mandate) {
      return (
        this.mandate.status == MandateStatus.AWAITING_APPROVAL ||
        this.mandate.status == MandateStatus.ACTIVE
      );
    }
    return false;
  }

  canArchive(): boolean {
    if (this.mandate) {
      return (
        this.mandate.status == MandateStatus.TERMINATED ||
        this.mandate.status == MandateStatus.ERROR ||
        this.mandate.status == MandateStatus.DECLINED
      );
    }
    return false;
  }

  archiveMandate() {
    if (this.mandate) {
      this.mandatesApiService.archiveMandate(this.mandate.id).subscribe({
        next: () => {
          this.mandate = undefined;
          this.toast.openSuccessToast(
            this.translateService.instant('TOAST.MANDATE-ARCHIVED.SUCCESS')
          );
          this.returnPreviousPage();
        },

        error: (error) => {
          this.toast.openErrorToast(this.translateService.instant('TOAST.MANDATE-ARCHIVED.ERROR'));
          console.error(error);
        },
      });
    }
  }
}
