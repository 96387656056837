import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import MandateListItem from 'src/app/service/dto/MandateListItem';
import { LanguageService } from 'src/app/service/language.service';
import Client from 'src/app/service/model/Client';
import PersonType from 'src/app/service/model/PersonType';
import MandateStatus from '../../../service/model/MandateStatus';

@Component({
  selector: 'mandates-terminate-client',
  templateUrl: './terminate-client-dialog.html',
  styleUrls: ['./terminate-client-dialog.scss'],
})
export class TerminateClientDialog implements OnInit {
  form: UntypedFormGroup = new UntypedFormGroup({
    keyword: new UntypedFormControl(),
  });
  constructor(
    private languageService: LanguageService,
    public dialogRef: MatDialogRef<TerminateClientDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {}

  canDelete(): boolean {
    return this.form.get('keyword')!.value === this.deleteKeyword;
  }

  get clientType(): PersonType {
    return this.data.client.person.getPersonType();
  }

  get clientName(): string {
    return this.data.client.person.getScreenName();
  }

  get clientNumber(): string {
    return this.data.client.person.getScreenNumber();
  }

  get address() {
    const { street, streetNumber, box, zipCode, city, country } =
      this.data.client.person.address || {};
    return street && streetNumber && zipCode && city && country
      ? `${street} ${streetNumber} ${
          box ? box : ''
        }, ${zipCode} ${city} (${this.languageService.getCountryName(country)})`
      : '-';
  }

  get deleteKeyword(): string {
    return this.languageService.getDeleteKeyword();
  }

  get mandates(): MandateListItem[] {
    return this.data.mandates;
  }

  get mandatesToTerminate(): MandateListItem[] {
    return this.data.mandates.filter((mandate) =>
      [MandateStatus.AWAITING_APPROVAL, MandateStatus.ACTIVE].includes(mandate.status)
    );
  }
}

interface DialogData {
  client: Client;
  mandates: MandateListItem[];
}
