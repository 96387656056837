<mat-form-field
  *ngIf="parentForm && type != 'date'"
  class="form-field"
  [formGroup]="parentForm"
  [appearance]="appearance"
>
  <mat-label>{{ label | translate }}</mat-label>
  <input
    *ngIf="!options && type != 'digit'"
    matInput
    [type]="type"
    formControlName="{{ control }}"
    [required]="isRequired"
  />

  <input
    *ngIf="!options && type === 'digit'"
    matInput
    type="text"
    formControlName="{{ control }}"
    [required]="isRequired"
    inputmode="numeric"
    pattern="[0-9]*"
    digitOnly
  />
  <mat-icon *ngIf="icon && !isLoading && !options && type === 'digit'" matSuffix>{{
    icon
  }}</mat-icon>
  <mat-spinner
    *ngIf="icon && isLoading && !options && type === 'digit'"
    matSuffix
    [diameter]="18"
    style="float: right; margin-left: 8px"
  ></mat-spinner>

  <ng-container *ngIf="options">
    <mat-select
      formControlName="{{ control }}"
      [required]="isRequired"
      [multiple]="multiple"
      (selectionChange)="onSelectChange($event)"
    >
      <mat-option *ngFor="let option of options" [value]="option.value">
        {{ option.name | translate }}
      </mat-option>
    </mat-select>
  </ng-container>
  <mat-error *ngIf="showErrorMessage">{{ errorMessage | translate }}</mat-error>
</mat-form-field>

<mat-form-field
  *ngIf="parentForm && type === 'date'"
  class="form-field"
  [formGroup]="parentForm"
  [appearance]="appearance"
>
  <mat-label>{{ label | translate }}</mat-label>
  <input
    matInput
    formControlName="{{ control }}"
    [required]="isRequired"
    [matDatepicker]="picker"
    [min]="minDate"
    [max]="maxDate"
    [readonly]="isReadonly"
  />
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
  <mat-error *ngIf="showErrorMessage">{{ errorMessage | translate }}</mat-error>
</mat-form-field>
