import { Component, Input, OnInit } from '@angular/core';
import { LanguageService } from '../../../service/language.service';
import Client from '../../../service/model/Client';

@Component({
  selector: 'mandates-client-card',
  templateUrl: './client-card.component.html',
  styleUrls: ['./client-card.component.scss'],
})
export class ClientCardComponent implements OnInit {
  @Input() client: Client;

  constructor(private languageService: LanguageService) {}

  ngOnInit(): void {}

  get countryName(): string {
    return this.client.person.address?.country
      ? this.languageService.getCountryName(this.client.person.address.country)
      : '-';
  }

  get city(): string {
    const { zipCode, city } = this.client.person.address || {};
    return zipCode && city ? `${zipCode} ${city}` : '-';
  }

  get street(): string {
    const { street, streetNumber, box } = this.client.person.address || {};
    return street && streetNumber
      ? `${street} ${streetNumber}${box ? ' ' : ''}${box || ''}, `
      : '-';
  }
}
