<section class="detail-container" *ngIf="!isLoading && !isEditing">
  <mat-tab-group
    [selectedIndex]="tabIndex"
    (selectedIndexChange)="onTabIndexChange($event)"
    class="custom-tabs"
  >
    <mat-tab [label]="'COMMON.MANDATES' | translate">
      <mandates-mandates-kanban
        *ngIf="client"
        [client]="client"
        [status]="status"
      ></mandates-mandates-kanban>
    </mat-tab>

    <mat-tab [label]="'COMMON.CONCEPTS' | translate">
      <mandates-mandates-concept-list
        *ngIf="client"
        [client]="client"
        [displayedColumns]="mandateTableColumns"
      ></mandates-mandates-concept-list>
    </mat-tab>

    <mat-tab [label]="'COMMON.CLIENT-INFO' | translate">
      <mandates-moral-person-detail
        *ngIf="isMoralPerson"
        [person]="moralPerson"
      ></mandates-moral-person-detail>
      <mandates-natural-person-detail
        *ngIf="isNaturalPerson"
        [person]="naturalPerson"
      ></mandates-natural-person-detail>
    </mat-tab>

    <mat-tab [label]="'EMPLOYEES.EMPLOYEES' | translate" *ngIf="isExclusive">
      <mandates-employees-list
        [employees]="employees"
        [client]="client"
        [mode]="'EDIT_ACCESS'"
      ></mandates-employees-list>
    </mat-tab>

    <mat-tab disabled>
      <ng-template mat-tab-label>
        <button
          *ngIf="tabIndex === 0 || tabIndex === 1"
          mat-flat-button
          color="primary"
          class="rounded add-btn"
          (click)="onAddMandateClick()"
        >
          <mat-icon>add</mat-icon>
          <span>
            {{ 'BUTTON.NEW-MANDATE' | translate }}
          </span>
        </button>
        <button
          *ngIf="tabIndex === 2"
          mat-stroked-button
          color="primary"
          class="rounded edit-client-btn"
          (click)="onEditPersonClick()"
        >
          <mat-icon>edit</mat-icon>
          <span>
            {{ 'BUTTON.EDIT-INFO' | translate }}
          </span>
        </button>
        <button
          *ngIf="tabIndex === 3"
          mat-flat-button
          color="primary"
          class="rounded add-btn"
          (click)="onAddEmployeeClick()"
        >
          <mat-icon>add_circle_outline</mat-icon>
          <span>
            {{ 'BUTTON.ADD-EMPLOYEE' | translate }}
          </span>
        </button>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</section>

<section *ngIf="!isLoading && isEditing" class="form">
  <h1>{{ 'COMMON.EDIT-CLIENT-INFO' | translate }}</h1>
  <mat-divider></mat-divider>
  <mandates-create-moral-person
    *ngIf="isMoralPerson"
    [clientId]="id"
    [person]="moralPerson"
    (formCancelled)="onFormCancel()"
    (formSaved)="onFormSaved($event)"
  ></mandates-create-moral-person>
  <mandates-create-natural-person
    *ngIf="isNaturalPerson"
    [clientId]="id"
    [person]="naturalPerson"
    (formCancelled)="onFormCancel()"
    (formSaved)="onFormSaved($event)"
  ></mandates-create-natural-person>
</section>
