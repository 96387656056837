<article>
  <section class="header mat-elevation-z2">
    <mandates-return-button routerLink="/"></mandates-return-button>

    <div class="client-header">
      <div class="client-container" *ngIf="selectedClient$ | async as client">
        <div class="client-name-container" *ngIf="!isLoading">
          <mandates-client-icon [type]="clientType"></mandates-client-icon>
          <h1>
            {{ client.person.getScreenName() }}
          </h1>
          <div
            *ngIf="!isComplete"
            class="client-incomplete"
            (click)="editClient()"
            matTooltip="{{ 'CLIENT.INCOMPLETE.TOOLTIP' | translate }}"
          >
            {{ 'CLIENT.INCOMPLETE.TITLE' | translate }}
          </div>
        </div>

        <div class="client-info-container" *ngIf="!isLoading">
          <div class="client-info-item" *ngFor="let info of clientInfo">
            <h3>{{ info.name | translate }}</h3>
            <p>{{ info.value | translate }}</p>
          </div>
        </div>
      </div>
      <div class="action-container">
        <div class="btn-header-container">
          <button
            mat-stroked-button
            color="warn"
            (click)="terminateClient()"
            #terminateClientButton
          >
            <mat-icon>delete_forever</mat-icon>
            <span>
              {{ 'BUTTON.TERMINATE-CLIENT' | translate }}
            </span>
          </button>
        </div>
        <div class="access-container" (click)="onAccessClick()">
          <h3>{{ 'COMMON.ACCESS' | translate }}:</h3>
          <div class="access-type">
            {{ (isExclusive ? 'COMMON.LIMITED' : 'COMMON.PUBLIC') | translate }}
          </div>
          <div class="access-toggle">
            <div>
              <mat-icon class="access-toggle-color">lock_open</mat-icon>
            </div>
            <div class="access-toggle-outlining">
              <mat-slide-toggle
                disabled
                #accessToggle
                [(ngModel)]="isExclusive"
                class="access-toggle-color"
              ></mat-slide-toggle>
            </div>
            <div><mat-icon class="access-toggle-color">lock</mat-icon></div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section>
    <router-outlet></router-outlet>
  </section>
</article>
