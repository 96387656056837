<article>
  <section class="header">
    <h1>{{ 'COMMON.CLIENTS' | translate }}</h1>

    <ng-container *ngIf="selectedClients.isEmpty(); else bulkActions">
      <button
        mat-raised-button
        color="primary"
        class="header-btn create-client-btn"
        (click)="onCreateClick()"
      >
        <mat-icon>add_circle_outline</mat-icon>
        {{ 'COMMON.NEW-CLIENT' | translate }}
      </button>
    </ng-container>
    <ng-template #bulkActions>
      <button
        mat-raised-button
        color="primary"
        class="header-btn create-mandate-btn"
        (click)="createMandateClick()"
        [disabled]="clientLimitExceeded()"
        matTooltip="{{
          clientLimitExceeded() ? ('TOOLTIP.CLIENT-LIMIT-EXCEEDED' | translate) : null
        }}"
      >
        <mat-icon>add_circle_outline</mat-icon>
        {{ 'MANDATES.NEW' | translate }}
      </button>
    </ng-template>
  </section>

  <section class="container">
    <mandates-base-filter [filters]="filters" [form]="form"></mandates-base-filter>
    <div class="loading-shade" *ngIf="hasErrors">
      <div class="error-container" *ngIf="hasErrors">
        <h2>{{ 'SERVER-ERROR.COULD-NOT-RETRIEVE' | translate }}</h2>
      </div>
    </div>

    <section
      class="select-all-btn-container"
      *ngIf="!isSelectingAllMatchingClients && hasSelectedAllOfCurrentPage()"
    >
      <span>{{
        'TABLE.ALL-COUNT-OF-PAGE-SELECTED'
          | translate
            : {
                count: selectedClients.selected.length,
                items: 'COMMON.CLIENTS' | translate | lowercase
              }
      }}</span>
      <span class="clickable" (click)="fetchAllClients()">
        {{
          'TABLE.SELECT-ALL-COUNT-THAT-MATCH'
            | translate
              : {
                  count: page.totalElements,
                  items: 'COMMON.CLIENTS' | translate | lowercase
                }
        }}
      </span>
    </section>
    <mandates-table
      class="clients-overview-table"
      [displayedColumns]="displayedColumns"
      [dataSource]="dataSource"
      [selection]="selectedClients"
      [pageSizeOptions]="[10, 20]"
      uuidRef="id"
      (pageChange)="handlePageEvent($event)"
      [selectAll]="isSelectingAllMatchingClients"
      [totalAvailable]="totalAvailableClients"
      [elementDisplayName]="'COMMON.CLIENTS' | translate"
      [page]="paginatedClients"
      color="primary"
      [showFirstLastButtons]="true"
      [isSelectingAll]="isSelectingAllMatchingClients"
      [useAngularPaginator]="isUnpagedWithoutMandateType"
      matSort
      (matSortChange)="handleSortChange($event)"
    >
      <ng-container matColumnDef="clientType">
        <th mat-header-cell *matHeaderCellDef matTooltip="{{ 'LIST.CLIENTTYPE' | translate }}">
          {{ 'LIST.TYPE' | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          <mandates-client-icon [type]="displayClientType(row)"></mandates-client-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="clientName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'LIST.NAME' | translate }}
        </th>
        <td mat-cell *matCellDef="let row">{{ displayClientName(row) }}</td>
      </ng-container>

      <ng-container matColumnDef="clientNumber">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'LIST.CLIENT-NUMBER' | translate }}
        </th>
        <td mat-cell *matCellDef="let row">{{ displayClientNumber(row) }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'LIST.ACTIONS' | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          <div class="action-buttons">
            <button mat-icon-button (click)="onInfoClick(row.id)">
              <mat-icon>info</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>
    </mandates-table>
  </section>
</article>
