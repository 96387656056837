<mat-table [dataSource]="datasource">
  <ng-container matColumnDef="index">
    <mat-header-cell *matHeaderCellDef>#</mat-header-cell>
    <mat-cell *matCellDef="let row; let i = index"> {{ i + 1 }} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="externalReference">
    <mat-header-cell *matHeaderCellDef>
      {{ 'MANDATES.IMPORT.MANDATE-ID' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let row" [class.invalid]="row.controls['externalReference'].invalid">
      {{
        row.controls['externalReference'].value || ('MANDATES.IMPORT.INVALID.MISSING' | translate)
      }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="mandateType">
    <mat-header-cell *matHeaderCellDef>
      {{ 'LIST.MANDATE-TYPE' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let row" [class.invalid]="row.controls['mandateType'].invalid">
      {{ getMandateType(row) | translate }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="mandator">
    <mat-header-cell *matHeaderCellDef>
      {{ 'MANDATES.IMPORT.MANDATOR' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let row" [class.invalid]="row.controls['mandator'].invalid">
      {{ row.controls['mandator'].value || ('MANDATES.IMPORT.INVALID.MISSING' | translate) }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="mandatorIdentifier">
    <mat-header-cell *matHeaderCellDef>
      {{ 'MANDATES.IMPORT.MANDATOR-ID' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let row" [class.invalid]="row.controls['mandatorIdentifier'].invalid">
      {{
        row.controls['mandatorIdentifier'].value || ('MANDATES.IMPORT.INVALID.MISSING' | translate)
      }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="mandatee">
    <mat-header-cell *matHeaderCellDef>
      {{ 'MANDATES.IMPORT.MANDATEE' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let row" [class.invalid]="row.controls['mandatee'].invalid">
      {{ row.controls['mandatee'].value || ('MANDATES.IMPORT.INVALID.MISSING' | translate) }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="mandateeIdentifier">
    <mat-header-cell *matHeaderCellDef>
      {{ 'MANDATES.IMPORT.MANDATEE-ID' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let row" [class.invalid]="row.controls['mandateeIdentifier'].invalid">
      {{
        row.controls['mandateeIdentifier'].value || ('MANDATES.IMPORT.INVALID.MISSING' | translate)
      }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="startDate">
    <mat-header-cell *matHeaderCellDef>
      {{ 'MANDATES.startDate' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let row" [class.invalid]="row.controls['startDate'].invalid">
      {{ row.controls['startDate'].value || ('MANDATES.IMPORT.INVALID.MISSING' | translate) }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="endDate">
    <mat-header-cell *matHeaderCellDef>
      {{ 'MANDATES.endDate' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let row" [class.invalid]="row.controls['endDate'].invalid">
      {{ row.controls['endDate'].value || '-' }}
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"> </mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
