import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import Filter from './filter';

@Component({
  selector: 'mandates-base-filter',
  templateUrl: './base-filter.component.html',
  styleUrls: ['./base-filter.component.scss'],
})
export class BaseFilterComponent implements OnInit {
  @Input() filters: Filter[];
  @Input() form: UntypedFormGroup;

  constructor() {}
  ngOnInit(): void {}

  onSelectionChange(value: any, controlName: string) {
    value != null
      ? this.form.setControl(controlName, new UntypedFormControl(value))
      : this.form.removeControl(controlName);
  }

  resetFilters() {
    this.filters.forEach((filter) => {
      this.form.removeControl(filter.controlName);
    });
  }

  get hasFilters(): boolean {
    let hasFilters = false;
    this.filters.forEach((filter) => {
      if (this.form.get(filter.controlName)?.value) {
        hasFilters = true;
      }
    });
    return hasFilters;
  }
}
