import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mandates-return-button',
  templateUrl: './return-button.component.html',
  styleUrls: ['./return-button.component.scss'],
})
export class ReturnButtonComponent implements OnInit {
  @Input() label: string = 'BUTTON.BACK-TO-OVERVIEW';

  constructor() {}

  ngOnInit(): void {}
}
