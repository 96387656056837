import { Component, Input, OnInit } from '@angular/core';
import PersonType from 'src/app/service/model/PersonType';

@Component({
  selector: 'mandates-client-icon',
  templateUrl: './client-icon.component.html',
  styleUrls: ['./client-icon.component.scss'],
})
export class ClientIconComponent implements OnInit {
  @Input() type: PersonType;
  @Input() inline: boolean = false;
  PersonType = PersonType;

  constructor() {}

  ngOnInit(): void {
    if (this.type === undefined) {
      // console.error(new Error("ClientIconComponent does not have a value for property 'type'"));
    }
  }
}
