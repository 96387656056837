import { AuthService } from './core/authentication/auth.service';

export function appInitializer(authService: AuthService) {
  return () =>
    new Promise<void>((resolve) => {
      const url = new URL(window.location.href);
      const organizationId = url.searchParams.get('organizationId');
      const roles = url.searchParams.get('roles');
      if (organizationId) authService.organizationId = organizationId;
      if (roles) authService.roles = roles;
      resolve();
    });
}
