import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'mandates-base-dialog',
  templateUrl: './base-dialog.html',
  styleUrls: ['./base-dialog.scss'],
})
export class BaseDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<BaseDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {}

  onCancelClick(): void {
    this.dialogRef.close(false);
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }
}

interface DialogData {
  title: string;
  description: string;
  paragraph: string;
  cancelText: string;
  confirmText: string;
  confirmColor: string;
}
