<mat-table [dataSource]="datasource" matSort>
  <!-- <ng-container matColumnDef="externalReference">
    <mat-header-cell *matHeaderCellDef> Mandaat ID </mat-header-cell>
    <mat-cell *matCellDef="let row"> {{row.externalReference}} </mat-cell>
  </ng-container> -->
  <ng-container matColumnDef="type">
    <mat-header-cell *matHeaderCellDef>
      {{ 'LIST.TYPE' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let row">
      <mandates-client-icon [type]="row.type"></mandates-client-icon>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'LIST.NAME' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let row"> {{ row.name }} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="identifier">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'LIST.CLIENT-NUMBER' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let row"> {{ row.identifier }} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="mandateTypes">
    <mat-header-cell *matHeaderCellDef>
      {{ 'LIST.MANDATE-TYPES' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let row"> {{ row.mandateTypes }} </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"> </mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
