<div class="container">
  <mandates-mandate-status-stepper
    *ngIf="mandate"
    [mandate]="mandate"
  ></mandates-mandate-status-stepper>

  <mat-card class="error" *ngIf="mandate && mandate.status === 'ERROR' && mandate.latestError">
    <mat-icon>warning</mat-icon>
    <p [innerHTML]="'MANDATES.ERROR_CODES.' + mandate.latestError | translate"></p>
  </mat-card>

  <mat-divider class="sub-divider"></mat-divider>

  <div class="container-row">
    <mat-card>
      <section class="mandate-types">
        <h2>{{ 'COMMON.PUBLISHED-MANDATE' | translate }}</h2>
        <div *ngIf="mandate">
          <mandates-mandate-type-card
            [mandateType]="mandate.mandateType"
          ></mandates-mandate-type-card>
        </div>
      </section>
    </mat-card>
    <mat-card>
      <section class="mandate-signer-section">
        <h2>{{ mandateSignerHeading | translate }}</h2>
        <div *ngIf="mandate?.mandateSigner" class="selected-mandate-signer">
          <button mat-mini-fab color="warn" (click)="removeMandateSigner()">
            <mat-icon>close</mat-icon>
          </button>
          <mandates-mandate-signer-card
            [signer]="mandate?.mandateSigner"
            [isDark]="false"
          ></mandates-mandate-signer-card>
        </div>

        <mandates-mandate-signer-form
          *ngIf="!mandate?.mandateSigner && client?.id"
          [clientId]="client.id"
          (signerSaved)="onSignerSaved($event)"
          [naturalPerson]="naturalPerson"
          (onToggle)="handleOnToggle($event)"
        ></mandates-mandate-signer-form>
      </section>
    </mat-card>
  </div>

  <mat-divider class="sub-divider"></mat-divider>

  <mat-card>
    <section class="form-section">
      <h2>{{ 'COMMON.FILLED-IN-REQUIRED-INFORMATION' | translate }}</h2>

      <mat-tab-group class="custom-tabs" [disablePagination]="true">
        <mat-tab disabled>
          <ng-template mat-tab-label>
            <div>
              <span class="tab-label-color">
                {{ 'MANDATES.TYPE.' + mandate?.mandateType?.typeName | translate }}
              </span>
              <span class="tab-label-color">*</span>
            </div>
          </ng-template>
          <mandates-mandate-information-form
            [parentForm]="form"
          ></mandates-mandate-information-form>
        </mat-tab>
      </mat-tab-group>
    </section>
  </mat-card>

  <mat-divider class="sub-divider"></mat-divider>

  <section *ngIf="mandate" class="button-section">
    <button mat-raised-button (click)="returnPreviousPage()" class="button-style button-return">
      <div class="button-position-items">
        <mat-icon class="button-margin">keyboard_backspace</mat-icon>
        <span class="button-label">{{ 'BUTTON.BACK' | translate }}</span>
      </div>
    </button>

    <div>
      <button
        style="margin-right: 6px"
        mat-raised-button
        [disabled]="canSendReminder() || !mandate.mandateSigner"
        (click)="onSendReminder()"
        class="button-style button-return"
        color="info"
        matTooltip="{{ 'MANDATES.HOVER.REMINDER' | translate }}"
      >
        <div class="button-position-items">
          <span class="button-label button-margin">{{ 'BUTTON.REMINDER' | translate }}</span>
          <mat-icon>forward_to_inbox</mat-icon>
        </div>
      </button>

      <button
        *ngIf="canDelete(); else archiveButton"
        mat-raised-button
        [disabled]="!mandate.mandateSigner"
        (click)="terminateMandate()"
        class="button-style"
        color="warn"
      >
        <div class="button-position-items">
          <span class="button-label button-margin">{{ 'BUTTON.TERMINATE' | translate }}</span>
          <mat-icon>delete_forever</mat-icon>
        </div>
      </button>
    </div>
  </section>
</div>

<ng-template #archiveButton>
  <button
    *ngIf="canArchive()"
    mat-raised-button
    class="button-style"
    color="warn"
    (click)="archiveMandate()"
    [disabled]="!canArchive()"
  >
    <div class="button-position-items">
      <span class="button-label button-margin">{{ 'BUTTON.ARCHIVE' | translate }}</span>
      <mat-icon>archive</mat-icon>
    </div>
  </button>
</ng-template>
