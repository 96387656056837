import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatColumnDef, MatTable, MatTableDataSource } from '@angular/material/table';
import Page from 'src/app/service/model/Page';

@Component({
  selector: 'mandates-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent<T> implements OnInit, OnChanges, AfterContentInit, OnDestroy {
  @Input() displayedColumns: string[];
  @Input() dataSource: MatTableDataSource<T>;
  @Input() selection: SelectionModel<T>;
  @Input() uuidRef: string;
  @Input() pageSizeOptions: number[];
  @Input() showFirstLastButtons: boolean = false;
  @Input() selectAll: boolean = false;
  @Input() elementDisplayName: string;

  @Input() color?: 'primary' | 'accent';

  @Input() page: Page<T>;

  @Input() totalAvailable: number;
  @Input() isSelectingAll: boolean = false;

  @Output() pageChange;

  @ViewChild(MatTable, { static: true }) table: MatTable<T>;
  @ViewChild('AllPaginator') allPaginator: MatPaginator;
  @ContentChildren(MatColumnDef) columnDefs!: QueryList<MatColumnDef>;

  @Input() useAngularPaginator: boolean = false;

  constructor() {
    this.displayedColumns = [];
    this.dataSource = new MatTableDataSource();
    this.selection = new SelectionModel();
    this.uuidRef = '';
    this.pageSizeOptions = [5, 10, 25, 100];
    this.pageChange = new EventEmitter<PageEvent>();
    this.totalAvailable = 0;
    this.elementDisplayName = '';
  }

  ngOnInit(): void {
    this.selection.isSelected = this.isRowSelected.bind(this);
    this.selection.toggle = this.toggleRowSelection.bind(this);
  }

  ngOnDestroy(): void {
    this.pageChange.unsubscribe();
    this.dataSource.disconnect();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('this.dataSource');
    // console.log(this.dataSource);
    // console.log('this.selection');
    // console.log(this.selection);

    if (changes.isSelectingAll && changes.isSelectingAll.currentValue === true) {
      this.masterToggle();
      this.dataSource.paginator = this.allPaginator;
    }

    if (changes.useAngularPaginator && changes.useAngularPaginator.currentValue === true) {
      this.dataSource.paginator = this.allPaginator;
    }
  }

  ngAfterContentInit(): void {
    this.columnDefs.forEach((columnDef: MatColumnDef) => {
      this.table.addColumnDef(columnDef);
    });
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      if (this.useAngularPaginator && !this.isSelectingAll) {
        // If the data source contains all elements
        const start = this.allPaginator.pageIndex * this.allPaginator.pageSize;
        const end = start + this.allPaginator.pageSize;
        const dataOnCurrentPage = this.dataSource.data.slice(start, end);

        // Select all rows on the current page
        dataOnCurrentPage.forEach((row) => this.selection.select(row));
      } else {
        // If the data source only contains the current page
        this.dataSource.data.forEach((row) => this.selection.select(row));
      }
    }
  }

  isAllSelected() {
    let dataIds;
    if (this.useAngularPaginator && !this.isSelectingAll) {
      const start = this.allPaginator.pageIndex * this.allPaginator.pageSize;
      const end = start + this.allPaginator.pageSize;
      dataIds = this.dataSource.data.slice(start, end).map((d: any) => d[this.uuidRef]);
    } else {
      dataIds = this.dataSource.data.map((d: any) => d[this.uuidRef]);
    }

    const selectedIds = this.selection.selected.map((s: any) => s[this.uuidRef]);
    return dataIds.every((id: any) => selectedIds.includes(id));
  }

  isMasterChecked() {
    return this.selection.hasValue() && this.isAllSelected();
  }

  isMasterIndeterminate(): boolean {
    return this.selection.hasValue() && !this.isAllSelected();
  }

  rowToggle(row: T) {
    this.selection.toggle(row);
  }

  isRowChecked(row: T) {
    return this.selection.isSelected(row);
  }

  private isRowSelected(row: any): boolean {
    return this.selection.selected.find(
      (selectedRow: any) => selectedRow[this.uuidRef] === row[this.uuidRef]
    )
      ? true
      : false;
  }

  private toggleRowSelection(row: any) {
    if (this.selection.isSelected(row)) {
      this.selection.setSelection(
        ...this.selection.selected.filter(
          (selectedRow: any) => selectedRow[this.uuidRef] !== row[this.uuidRef]
        )
      );
    } else {
      this.selection.select(row);
    }
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isMasterChecked() ? 'select' : 'deselect'} all`;
    }
    return `${this.isRowSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  onPageChange(event: PageEvent) {
    this.pageChange.emit(event);
  }

  get totalSelectedLabel(): string {
    const count = this.selection.selected.length;
    return `paginator.number-of-total-rows-selected ${count} COMMON.MANDATES`;
  }

  get source() {
    return this.isSelectingAll ? this.dataSource : this.page?.content ?? [];
  }
}
