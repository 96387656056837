<article>
  <button
    #tooltip="matTooltip"
    [matTooltip]="'TOOLTIP.REMOVE-FILTERS' | translate"
    matTooltipPosition="above"
    mat-icon-button
    class="filter-btn"
    (click)="resetFilters()"
    [disabled]="!hasFilters"
  >
    <mat-icon>{{ hasFilters ? 'filter_alt_off' : 'filter_alt' }}</mat-icon>
  </button>
  <section class="filter-section" *ngIf="filters">
    <mandates-filter-button-form
      class="filter-menu-btn"
      *ngFor="let filter of filters"
      [filter]="filter"
      [form]="form"
      (selectionChange)="onSelectionChange($event, filter.controlName)"
    ></mandates-filter-button-form>
  </section>
  <section class="btn-section">
    <ng-content select="[button]"></ng-content>
  </section>
</article>
