export default interface FeatureToggle {
  id: string;
  featureName: string;
  isEnabled: boolean;
  messageNl: string;
  messageFr: string;
  messageEn: string;
  level: LevelType;
}

enum LevelType {
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export { LevelType };
