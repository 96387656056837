import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MandatesModule } from '../mandates/mandates.module';
import { PaginatorI18n } from '../service/paginator-i18n';
import { SharedModule } from '../shared/shared.module';
import { ClientDetailComponent } from './client-detail/client-detail.component';
import { MoralPersonDetailComponent } from './client-detail/moral-person-detail/moral-person-detail.component';
import { NaturalPersonDetailComponent } from './client-detail/natural-person-detail/natural-person-detail.component';
import { ClientsComponent } from './clients.component';
import { CreateClientComponent } from './create-client/create-client.component';
import { CreateMoralPersonComponent } from './create-client/create-moral-person/create-moral-person.component';
import { CreateNaturalPersonComponent } from './create-client/create-natural-person/create-natural-person.component';
import { ClientDetailInfoComponent } from './client-detail/client-detail-info/client-detail-info.component';
import { TerminateClientDialog } from './client-detail/terminate-client-dialog/terminate-client-dialog';
import { EmployeesModule } from '../employees/employees.module';
import { AddEmployeeAccessDialog } from './client-detail/add-employee-access-dialog/add-employee-access-dialog.component';

@NgModule({
  declarations: [
    CreateClientComponent,
    CreateNaturalPersonComponent,
    CreateMoralPersonComponent,
    ClientsComponent,
    ClientDetailComponent,
    NaturalPersonDetailComponent,
    MoralPersonDetailComponent,
    ClientDetailInfoComponent,
    TerminateClientDialog,
    AddEmployeeAccessDialog,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    RouterModule,
    MandatesModule,
    EmployeesModule,
  ],
  exports: [CreateClientComponent],
  providers: [
    {
      provide: MatPaginatorIntl,
      deps: [TranslateService],
      useFactory: (translateService: TranslateService) =>
        new PaginatorI18n(translateService).getPaginatorIntl(),
    },
  ],
})
export class ClientsModule {}
