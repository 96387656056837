import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ClientCommand } from 'src/app/service/dto/ClientCommand';
import Employee from 'src/app/service/model/Employee';
import EngagementType from 'src/app/service/model/EngagementType';
import OrganisationKBO from 'src/app/service/model/OrganisationKBO';
import PersonType from 'src/app/service/model/PersonType';
import { BaseDialog } from 'src/app/shared/components/base-dialog/base-dialog';
import { environment } from '../../../environments/environment';
import Client from '../../service/model/Client';
import Page from '../../service/model/Page';
import { Sort } from '@angular/material/sort';

export class EngagementCommand {
  engagementType: EngagementType;
}

export class EmployeeEngagementCommand {
  employeeId: string;
}

@Injectable({
  providedIn: 'root',
})
export class ClientsApiService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    responseType: 'json',
  };

  protected baseUrl: string = environment.backendUrl;
  private userInfo = environment.userInfoFixture;

  isExclusive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  employeesWithAccess$: BehaviorSubject<Employee[]> = new BehaviorSubject<Employee[]>([]);

  selectedClient$ = new BehaviorSubject<Client | undefined>(undefined);
  selectedClients$ = new BehaviorSubject<Client[]>([]);

  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private translateService: TranslateService
  ) {}

  public addClient(client: ClientCommand): Observable<Client> {
    return this.http
      .post<Client>(`${this.baseUrl}/clients`, client)
      .pipe(switchMap((client) => of(new Client({ ...client }))));
  }

  public updateClient(client: ClientCommand, id: string): Observable<Client> {
    return this.http
      .put<Client>(`${this.baseUrl}/clients/${id}`, client)
      .pipe(switchMap((client) => of(new Client({ ...client }))));
  }

  public getClients(
    page: number = 0,
    size: number = 10,
    searchKeyword?: string,
    personType?: PersonType,
    createdByEmployee?: string,
    sort?: Sort,
    mandateType?: string
  ): Observable<Page<Client>> {
    let params = new HttpParams().append('page', page).append('size', size);
    if (sort) params = params.append('sort', `${sort.active},${sort.direction}`);

    if (searchKeyword) params = params.append('searchKeyword', searchKeyword);
    if (personType) params = params.append('personType', personType);
    if (createdByEmployee) params = params.append('createdBy', createdByEmployee);
    if (mandateType) params = params.append('missingMandateTypeReference', mandateType);

    return this.http.get<Page<Client>>(`${this.baseUrl}/clients`, {
      params,
    });
  }

  public getExclusiveClients(
    id: string,
    page: number = 0,
    size: number = 10
  ): Observable<Page<Client>> {
    let params = new HttpParams()
      .append('page', page)
      .append('size', size)
      .append('engagementType', EngagementType.EXCLUSIVE);
    let headers = new HttpHeaders().set('employee-Id', id);

    return this.http.get<Page<Client>>(`${this.baseUrl}/clients`, {
      headers,
      params,
    });
  }

  public getClientById(id: string): Observable<Client> {
    return this.http.get<Client>(`${this.baseUrl}/clients/${id}`).pipe(
      map((client) => {
        this.isExclusive$.next(client.engagementType === EngagementType.EXCLUSIVE);
        if (this.isExclusive$.value) this.getEmployeesWithAccessToClient(id).subscribe();
        return new Client({ ...client });
      })
    );
  }

  public terminateClientById(id: string) {
    return this.http.delete(`${this.baseUrl}/clients/${id}`);
  }

  public updateEngagementType(id: string, type: EngagementType): Observable<any> {
    let command = new EngagementCommand();
    command.engagementType = type;

    return this.http
      .put<any>(`${this.baseUrl}/clients/${id}/engagement`, command)
      .pipe(tap((data) => this.getClientById(id).subscribe()));
  }

  public getEmployeesWithAccessToClient(id: string): Observable<Employee[]> {
    return this.http
      .get<Employee[]>(`${this.baseUrl}/clients/${id}/employees-with-access`)
      .pipe(tap((employees) => this.employeesWithAccess$.next(employees)));
  }

  public addEmployeesToAccessClient(clientid: string, employeeId: string): Observable<any> {
    let command = new EmployeeEngagementCommand();
    command.employeeId = employeeId;

    return this.http
      .post<any>(`${this.baseUrl}/clients/${clientid}/employees-with-access`, command)
      .pipe(tap(() => this.getEmployeesWithAccessToClient(clientid).subscribe()));
  }

  public deleteEmployeeToLimitedAccessDialog(client: Client, employee: Employee) {
    const employeeName = `${employee.firstName} ${employee.lastName}`;
    const clientName = client.person.getScreenName();
    const title = this.translateService.instant('DIALOG.REVOKE-EMPLOYEE', {
      employeeName: employeeName,
      clientName: clientName,
    });

    const dialogRef = this.dialog.open(BaseDialog, {
      data: {
        title: title,
        cancelText: 'DIALOG.NO',
        confirmText: 'DIALOG.YES',
        confirmColor: 'accent',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.deleteEmployeeToLimitedAccess(client.id, employee.id).subscribe();
    });
  }

  public deleteEmployeeToLimitedAccess(clientId: string, employeeId: string) {
    return this.http
      .delete<any>(`${this.baseUrl}/clients/${clientId}/employees-with-access/${employeeId}`)
      .pipe(tap(() => this.getEmployeesWithAccessToClient(clientId).subscribe()));
  }

  public getOrganisationFromKBO(ondernemingsNummer: string, language: string) {
    return this.http.get<OrganisationKBO>(
      `${this.baseUrl}/kbo/${ondernemingsNummer}?language=${language}`
    );
  }
}
