import Address from './Address';
import Language from './Language';
import Person from './Person';
import PersonType from './PersonType';
import Region from './Region';

export default class MoralPerson implements Person {
  id: string | undefined;
  type: PersonType;

  isVatObligated: boolean;
  vatNumber: string | null;
  hasPermanentEstablishment: boolean;
  activeRegions: Region[];
  address?: Address;
  language: Language;

  country: string;
  companyNumber: string;
  companyName: string;
  companyForm: string;
  companyStatus: string;
  hasEmployees: boolean;
  isVatUnityMember: boolean;

  constructor({
    id,
    type,
    isVatObligated,
    vatNumber,
    hasPermanentEstablishment,
    activeRegions,
    address,
    country,
    companyNumber,
    companyName,
    companyForm,
    companyStatus,
    hasEmployees,
    isVatUnityMember,
    language,
  }: {
    id: string | undefined;
    type: PersonType;
    isVatObligated: boolean;
    vatNumber: string | null;
    hasPermanentEstablishment: boolean;
    activeRegions: Region[];
    address: Address;
    country: string;
    companyNumber: string;
    companyName: string;
    companyForm: string;
    companyStatus: string;
    hasEmployees: boolean;
    isVatUnityMember: boolean;
    language: Language;
  }) {
    this.id = id;
    this.type = type;
    this.isVatObligated = isVatObligated;
    this.vatNumber = vatNumber;
    this.hasPermanentEstablishment = hasPermanentEstablishment;
    this.activeRegions = activeRegions;
    this.address = new Address({ ...address });
    this.country = country;
    this.companyNumber = companyNumber;
    this.companyName = companyName;
    this.companyForm = companyForm;
    this.companyStatus = companyStatus;
    this.hasEmployees = hasEmployees;
    this.isVatUnityMember = isVatUnityMember;
    this.language = language;
  }

  getScreenName(): string {
    return this.companyName;
  }

  getScreenNumber(): string {
    return this.companyNumber;
  }

  getPersonType(): PersonType {
    return PersonType.MORAL_PERSON;
  }
}
