import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../../../core/authentication/auth.service';
import { UserInfo } from '../../../core/authentication/UserInfo';

@Component({
  selector: 'mandates-main-toolbar',
  templateUrl: './main-toolbar.component.html',
  styleUrls: ['./main-toolbar.component.scss'],
})
export class MainToolbarComponent implements OnInit {
  userInfo: UserInfo | undefined;

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.userInfo = this.userInfo$.value;
    this.userInfo$.subscribe((userInfo) => (this.userInfo = userInfo));
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['logout']);
  }

  get userInfo$(): BehaviorSubject<UserInfo | undefined> {
    return this.authService.userInfo$;
  }
}
