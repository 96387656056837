enum MandateStatus {
  DRAFT = 'DRAFT',
  SENT = 'SENT',
  ERROR = 'ERROR',
  AWAITING_APPROVAL = 'AWAITING_APPROVAL',
  ACTIVE = 'ACTIVE',
  TERMINATED = 'TERMINATED',
  DECLINED = 'DECLINED',
  ARCHIVED = 'ARCHIVED',
  TO_SEND = 'TO_SEND',
  SENDING = 'SENDING',
}

export default MandateStatus;
