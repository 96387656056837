import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ClientsApiService } from 'src/app/core/clients-api/clients-api.service';
import { EmployeesApiService } from 'src/app/core/employees-api/employees-api.service';
import { ComponentCanDeactivate } from 'src/app/core/guards/can-deactivate.guard';
import { MandatesApiService } from 'src/app/core/mandates-api/mandates-api.service';
import MandateListItem from 'src/app/service/dto/MandateListItem';
import { LanguageService } from 'src/app/service/language.service';
import EngagementType from 'src/app/service/model/EngagementType';
import { ToastService } from 'src/app/service/toast.service';
import { BaseDialog } from 'src/app/shared/components/base-dialog/base-dialog';
import { AuthService } from '../../core/authentication/auth.service';
import Client from '../../service/model/Client';
import EmploymentRoleType from '../../service/model/EmploymentRoleType';
import MandateStatus from '../../service/model/MandateStatus';
import MoralPerson from '../../service/model/MoralPerson';
import NaturalPerson from '../../service/model/NaturalPerson';
import PersonType from '../../service/model/PersonType';
import { TerminateClientDialog } from './terminate-client-dialog/terminate-client-dialog';

@Component({
  selector: 'mandates-client-detail',
  templateUrl: './client-detail.component.html',
  styleUrls: ['./client-detail.component.scss'],
})
export class ClientDetailComponent implements OnInit, ComponentCanDeactivate {
  id: string;
  client: Client;
  index = 0;
  isLoading: boolean = true;
  isEditing: boolean = false;
  isExclusive: boolean;

  mandateTableColumns = ['mandateTypes', 'lastOpened', 'status', 'actions'];
  status = [
    MandateStatus.TO_SEND,
    MandateStatus.SENDING,
    MandateStatus.SENT,
    MandateStatus.AWAITING_APPROVAL,
    MandateStatus.ACTIVE,
    MandateStatus.ERROR,
    MandateStatus.DECLINED,
    MandateStatus.TERMINATED,
  ];

  mandates: MandateListItem[] = [];

  constructor(
    private clientApiService: ClientsApiService,
    private mandatesApiService: MandatesApiService,
    private employeesApiService: EmployeesApiService,
    public translateService: TranslateService,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private toast: ToastService,
    private languageService: LanguageService,
    private matDialog: MatDialog,
    private authService: AuthService
  ) {}

  canDeactivate() {
    return {
      shouldDeactivate: !this.isEditing,
      data: {
        title: 'DIALOG.CANCEL-FORM.DESCRIPTION-PERSON-UPDATE',
        description: '',
        cancelText: 'DIALOG.NO-CONTINUE',
        confirmText: 'DIALOG.YES-CANCEL',
        confirmColor: 'accent',
      },
    };
  }

  ngOnInit(): void {
    this.employeesApiService.getEmployees().subscribe();
    this.isEditing = false;
    this.fetchClient();
    this.clientApiService.isExclusive$.subscribe((isExclusive) => (this.isExclusive = isExclusive));
    this.mandatesApiService.mandates$.subscribe(
      (result) => (this.mandates = result?.content || [])
    );
    this.clientApiService.selectedClient$.subscribe((client) => {
      if (client) {
        this.client = client;
      }
    });
  }

  returnPreviousPage() {
    this.location.back();
  }

  terminateClient() {
    this.matDialog
      .open(TerminateClientDialog, {
        data: {
          client: this.client,
          mandates: this.mandates || [],
        },
      })
      .afterClosed()
      .subscribe((response) => {
        if (response) {
          this.clientApiService.terminateClientById(this.id).subscribe(() => this.location.back());
        }
      });
  }

  private fetchClient() {
    const id = this.route.snapshot.paramMap.get('clientId');
    if (id) {
      this.id = id;
      this.clientApiService.getClientById(this.id).subscribe({
        next: (response) => {
          this.client = response;
          this.clientApiService.selectedClient$.next(response);
          this.isLoading = false;
        },
        error: (error) => {
          this.toast.openErrorToast(`[${error.status}: ${error.name}] ${error.message}`);
          console.error(error);
        },
      });
    } else {
      this.toast.openErrorToast('Could not get Client Id');
    }
  }

  editClient() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([currentUrl], {
        queryParams: { editClient: true },
      })
    );
  }

  get clientType(): PersonType {
    return this.client.person.type;
  }

  get clientName(): string {
    return this.client.person.getScreenName();
  }

  get nationalNumber(): string {
    if ((this.client.person as NaturalPerson).nationalNumber) {
      return (this.client.person as NaturalPerson).nationalNumber;
    }
    return '-';
  }

  get companyNumber(): string {
    if ((this.client.person as MoralPerson).companyNumber) {
      return (this.client.person as MoralPerson).companyNumber;
    }
    return '-';
  }

  get address() {
    const { street, streetNumber, box, zipCode, city, country } = this.client.person.address || {};
    return street && streetNumber && zipCode && city && country
      ? `${street} ${streetNumber} ${
          box ? box : ''
        }, ${zipCode} ${city} (${this.languageService.getCountryName(country)})`
      : '-';
  }

  get clientInfo(): { name: string; value: any }[] {
    return [
      { name: 'COMMON.COMPANY-NUMBER', value: this.companyNumber },
      { name: 'COMMON.NATIONAL-NUMBER', value: this.nationalNumber },
      { name: 'COMMON.ADDRESS', value: this.address },
    ];
  }

  get selectedClient$(): Observable<Client | undefined> {
    return this.clientApiService.selectedClient$;
  }

  get isComplete(): boolean {
    const { street, streetNumber, zipCode, city, country } = this.client.person.address || {};
    return !!country && !!city && !!zipCode && !!street && !!streetNumber;
  }

  onAccessClick() {
    const type = !this.isExclusive ? EngagementType.EXCLUSIVE : EngagementType.COMPLEMENTARY;
    if (
      (type === EngagementType.COMPLEMENTARY &&
        this.authService.userInfo?.role === EmploymentRoleType.ADMIN) ||
      type === EngagementType.EXCLUSIVE
    ) {
      this.openDialog(type);
    }
  }

  private openDialog(type: EngagementType) {
    const title =
      type == EngagementType.EXCLUSIVE
        ? 'DIALOG.UPDATE-CLIENT-ACCESS.LIMITED.DESCRIPTION'
        : 'DIALOG.UPDATE-CLIENT-ACCESS.PUBLIC.DESCRIPTION';
    const paragraph =
      type == EngagementType.EXCLUSIVE
        ? 'DIALOG.UPDATE-CLIENT-ACCESS.LIMITED.PARAGRAPH'
        : 'DIALOG.UPDATE-CLIENT-ACCESS.PUBLIC.PARAGRAPH';
    const dialogRef = this.matDialog.open(BaseDialog, {
      data: {
        title: this.translateService.instant(title, {
          name: this.client.person.getScreenName(),
        }),
        description: paragraph,
        cancelText: 'DIALOG.NO',
        confirmText: 'DIALOG.YES',
        confirmColor: 'accent',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.isExclusive = !this.isExclusive;
        this.clientApiService.updateEngagementType(this.id, type).subscribe();
      }
    });
  }
}
