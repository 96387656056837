import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mandates-dropzone-upload',
  templateUrl: './dropzone-upload.component.html',
  styleUrls: ['./dropzone-upload.component.scss'],
})
export class DropzoneUploadComponent {
  @Input() allowedExtensions: string[];
  @Output() fileSelected = new EventEmitter<File | null>();

  file: File | null = null;

  constructor() {}

  get allowedExtensionsFormatted(): string {
    return this.allowedExtensions.map((ext) => `.${ext}`).toString();
  }

  handleFile(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.files) {
      const firstFile = target.files.item(0);
      const extension = firstFile?.name.split('.').pop();
      if (extension && this.allowedExtensions.includes(extension)) {
        this.file = firstFile;
        this.fileSelected.emit(firstFile);
      }
    }
  }

  clear() {
    this.file = null;
    this.fileSelected.emit(this.file);
  }
}
