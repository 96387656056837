import { Pipe, PipeTransform } from '@angular/core';
import MandateListItem from '../../service/dto/MandateListItem';
import MandateStatus from '../../service/model/MandateStatus';

@Pipe({
  name: 'mandatesStatusFilter',
})
export class MandatesStatusFilterPipe implements PipeTransform {
  transform(mandatelist: MandateListItem[], status: MandateStatus[]): MandateListItem[] {
    return mandatelist?.filter((mandate) => status.includes(mandate.status));
  }
}
