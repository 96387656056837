import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { AuthService } from '../../core/authentication/auth.service';
import { UserInfo } from '../../core/authentication/UserInfo';
import { MandatesApiService } from '../../core/mandates-api/mandates-api.service';
import MandateImportRow from '../../service/model/MandateImportRow';
import { ToastService } from '../../service/toast.service';
import { CustomValidator } from '../../shared/validators/custom.validator';
import { ImportMandatesLoadingComponent } from './import-mandates-loading/import-mandates-loading.component';
import { InvalidMandatesImportDialogComponent } from './invalid-mandates-import-dialog/invalid-mandates-import-dialog.component';
import { LanguageService } from 'src/app/service/language.service';

@Component({
  selector: 'mandates-import-mandates',
  templateUrl: './import-mandates.component.html',
  styleUrls: ['./import-mandates.component.scss'],
})
export class ImportMandatesComponent implements OnInit {
  platformOptions = ['CSAM'];
  allowedExtensions = ['xlsx', 'xls', 'csv'];
  file: File | null;
  mandateImports$ = new Subject<MandateImportRow[]>();
  validMandateImportRows: MandateImportRow[] = [];
  invalidMandateImportForms: FormGroup[] = [];
  userInfo: UserInfo;

  constructor(
    private location: Location,
    private mandatesApiService: MandatesApiService,
    private matDialog: MatDialog,
    private authService: AuthService,
    private router: Router,
    private toast: ToastService,
    private translateService: TranslateService,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.subscribeToMandateImports();
    if (this.authService.userInfo) this.userInfo = this.authService.userInfo;
    this.authService.userInfo$.subscribe((userInfo) => (this.userInfo = userInfo!!));
  }

  subscribeToMandateImports() {
    this.mandateImports$.subscribe((data) => {
      data.forEach((row) => {
        row.mandatorIdentifier = row.mandatorIdentifier?.replace(/\*/g, '0') || undefined;
        row.mandateeIdentifier = row.mandateeIdentifier?.replace(/\*/g, '0') || undefined;
        const form = this.mandateImportRowToFormGroup(row);
        if (form.valid) {
          this.validMandateImportRows.push(row);
        } else {
          this.invalidMandateImportForms.push(form);
        }
      });
    });
  }

  get allowedExtensionsFormatted(): string {
    return this.allowedExtensions.map((ext) => `.${ext}`).toString();
  }

  navigateBack() {
    this.location.back();
  }

  back() {
    this.mandateImports$ = new Subject<MandateImportRow[]>();
    this.subscribeToMandateImports();
    this.validMandateImportRows = [];
    this.invalidMandateImportForms = [];
  }

  next() {
    const element = document.querySelector('#header') as any;
    element.scrollIntoView();
    this.parseSelectedFile();
  }

  setFile(file: File | null) {
    this.file = file;
  }

  parseSelectedFile() {
    if (this.file) {
      this.mandatesApiService.parseMandatesImport(this.file).subscribe(this.mandateImports$);
    }
  }

  mandateImportRowToFormGroup(row: MandateImportRow): FormGroup {
    const form = new FormGroup({
      externalReference: new FormControl(row.externalReference, [Validators.required]),
      mandateType: new FormControl(row.mandateType, [
        Validators.required,
        CustomValidator.validMandateType,
      ]),
      mandator: new FormControl(row.mandator, [Validators.required]),
      mandatorIdentifier: new FormControl(row.mandatorIdentifier, [
        Validators.required,
        CustomValidator.validVatOrBelgianNationalNumber,
      ]),
      mandatee: new FormControl(row.mandatee, [Validators.required]),
      mandateeIdentifier: new FormControl(row.mandateeIdentifier, [
        Validators.required,
        CustomValidator.validVatOrBelgianNationalNumber,
        CustomValidator.stringEquals(this.userInfo?.officeCompanyNumber),
      ]),
      startDate: new FormControl(row.startDate, [Validators.required]),
      endDate: new FormControl(row.endDate),
    });
    form.updateValueAndValidity();
    return form;
  }

  onImportClick() {
    if (this.invalidMandateImportForms.length > 0) {
      this.matDialog
        .open(InvalidMandatesImportDialogComponent, {
          data: this.invalidMandateImportForms,
          width: '90vw',
        })
        .afterClosed()
        .subscribe((response) => {
          console.log(response);
          const element = document.querySelector('#header') as any;
          element.scrollIntoView();
          this.importMandates();
        });
    } else {
      this.importMandates();
    }
  }

  // HERE
  importMandates() {
    if (this.validMandateImportRows.length > 0) {
      const lang = this.languageService.getLanguage().toUpperCase();
      const dialogRef = this.matDialog.open(ImportMandatesLoadingComponent, {
        hasBackdrop: true,
        disableClose: true,
        backdropClass: 'backdropBackground',
        width: '400px',
      });

      this.mandatesApiService.importMandates(this.validMandateImportRows, lang).subscribe({
        next: (mandateRows) => {
          let skipMessage = '';
          if (mandateRows.failedImports === 0) {
            const message = this.translateService.instant('MANDATES.IMPORT.SUCCESS');
            this.toast.openSuccessToast(`${message} ${skipMessage}`);
          } else {
            const message = this.translateService.instant('MANDATES.IMPORT.FAILED', {
              failed: mandateRows.failedImports,
              total: this.validMandateImportRows.length,
            });
            this.toast.openWarningToast(`${message} ${skipMessage}`);
          }
          this.router.navigate(['/mandates']);
        },
        error: (e) => this.toast.openErrorToast(`[${e.status}: ${e.name}] ${e.message}`),
        complete: () => dialogRef.close(),
      });
    }
  }
}
