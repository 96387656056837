<mat-sidenav-container class="sidenav-container" *ngIf="userInfo$ | async as userInfo">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
  >
    <mat-toolbar>
      <img class="logo" src="assets/logo/itaa-logo.png" alt="ITAA" />
    </mat-toolbar>

    <mat-nav-list class="nav-list">
      <h2 matSubheader>{{ 'SIDENAV.MAN-CLIENT-TITLE' | translate }}</h2>
      <a
        mat-list-item
        class="nav-list-item"
        routerLink="/clients"
        routerLinkActive="active-nav-list-item"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <mat-icon>account_circle</mat-icon>
        <span>{{ 'SIDENAV.CLIENTS' | translate }}</span>
      </a>
      <a
        mat-list-item
        class="nav-list-item"
        routerLink="/mandates"
        routerLinkActive="active-nav-list-item"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <mat-icon>list</mat-icon>
        <span>{{ 'SIDENAV.MANDATES' | translate }}</span>
      </a>
      <a
        *ngIf="userInfo.role === 'ADMIN'"
        mat-list-item
        class="nav-list-item"
        routerLink="/employees"
        routerLinkActive="active-nav-list-item"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <mat-icon>group</mat-icon>
        <span>{{ 'SIDENAV.EMPLOYEES' | translate }}</span>
      </a>
      <h2 matSubheader>{{ 'SIDENAV.HELP-TITLE' | translate }}</h2>
      <a mat-list-item class="nav-list-item" (click)="onHelpClick()">
        <mat-icon>description_outline</mat-icon>
        <span>{{ 'SIDENAV.HELP' | translate }}</span>
      </a>
      <a mat-list-item class="nav-list-item" (click)="onFaqClick()">
        <mat-icon>help_outline</mat-icon>
        <span>FAQ</span>
      </a>
    </mat-nav-list>
    <mat-form-field appearance="outline" class="lang-select">
      <mat-select
        (selectionChange)="onSelectionChange($event)"
        [value]="defaultLang"
        class="nav-list-item"
      >
        <mat-option value="fr">{{ 'LANGUAGE.FR' | translate }}</mat-option>
        <mat-option value="nl">{{ 'LANGUAGE.NL' | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
  </mat-sidenav>
  <mat-sidenav-content class="sidenav-content">
    <mandates-main-toolbar></mandates-main-toolbar>
    <ng-container *ngIf="isLoading$ | async">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </ng-container>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
