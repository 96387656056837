<div class="kanban-list">
  <div class="kanban-column" *ngFor="let kanbanColum of kanbanColums">
    <div class="kanban-header">
      <div class="kanban-title">
        {{ kanbanColum.title | translate }} ({{
          (mandates | mandatesStatusFilter: kanbanColum.filter).length
        }})
      </div>
    </div>
    <div class="kanban-content">
      <mandates-kanban-mandate-item
        class="kanban-item"
        *ngFor="let mandate of mandates | mandatesStatusFilter: kanbanColum.filter"
        [mandate]="mandate"
        (terminateMandate)="terminateMandate($event)"
        (archiveMandate)="archiveMandate($event)"
      >
      </mandates-kanban-mandate-item>
    </div>
  </div>
</div>
