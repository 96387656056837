import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'mandates-filter-button-form',
  templateUrl: './filter-button-form.component.html',
  styleUrls: ['./filter-button-form.component.scss'],
})
export class FilterButtonFormComponent implements OnInit {
  @Input() filter: filterData;
  @Input() form: UntypedFormGroup;
  @Output() selectionChange: EventEmitter<any> = new EventEmitter<any>();

  isOpen: boolean = false;
  searchValue = '';

  constructor() {}

  ngOnInit(): void {
    this.form.valueChanges.subscribe(() => {
      if (!this.form.contains(this.filter.controlName)) {
        this.resetComponent();
      }
    });
  }

  onSearchClick() {
    this.saveSelection({ name: this.searchValue, value: this.searchValue });
  }

  onFilterClick() {
    this.isOpen = !this.isOpen;
  }

  saveSelection(selection?: { name: string; value: any }) {
    this.selectionChange.emit(selection?.value);
  }

  removeSelectedValue() {
    this.saveSelection();
  }

  private resetComponent() {
    this.isOpen = false;
    this.searchValue = '';
  }

  get isValidSearchValue(): boolean {
    return this.searchValue && this.searchValue.length >= 3 ? true : false;
  }

  get selectedValue(): any {
    const value = this.form.get(this.filter.controlName)?.value;
    if (value) {
      return this.filter.isSearchFilter
        ? { name: value, value }
        : this.filter.options.find((option) => option.value === value);
    }
    return null;
  }
}

interface filterData {
  name: string;
  options: { name: string; value: any }[];
  controlName: string;
  isSearchFilter?: boolean;
}
