import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DefaultLangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as i18nIsoCountries from 'i18n-iso-countries';
import { forOwn, orderBy } from 'lodash';
import { ClientsApiService } from 'src/app/core/clients-api/clients-api.service';
import { ClientCommand } from 'src/app/service/dto/ClientCommand';
import OrganisationKBO from 'src/app/service/model/OrganisationKBO';
import { ToastService } from 'src/app/service/toast.service';
import { BaseDialog } from 'src/app/shared/components/base-dialog/base-dialog';
import { CustomValidator } from 'src/app/shared/validators/custom.validator';
import CompanyStatus from '../../../service/model/CompanyStatus';
import Language from '../../../service/model/Language';
import MoralPerson from '../../../service/model/MoralPerson';
import Region from '../../../service/model/Region';
import MoralPersonForm from '../../form/moral-person-form';

enum Status {
  CLEAR = 'CLEAR',
  SUCCESS = 'SUCCESS',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
}

@Component({
  selector: 'mandates-create-moral-person',
  templateUrl: './create-moral-person.component.html',
  styleUrls: ['./create-moral-person.component.scss'],
})
export class CreateMoralPersonComponent implements OnInit {
  @Input() clientId: string;
  @Input() person: MoralPerson;
  @Output() formCancelled = new EventEmitter();
  @Output() formSaved = new EventEmitter();

  Status = Status;

  companyForms: { name: string; value: string }[] = [
    { name: 'COMPANY-FORM.BV', value: '610' },
    { name: 'COMPANY-FORM.NV', value: '014' },
    { name: 'COMPANY-FORM.CV', value: '706' },
    { name: 'COMPANY-FORM.MTS', value: '702' },
    { name: 'COMPANY-FORM.COMMV', value: '612' },
    { name: 'COMPANY-FORM.VOF', value: '011' },
    { name: 'COMPANY-FORM.VZW', value: '017' },
    { name: 'COMPANY-FORM.SE', value: '027' },
    { name: 'COMPANY-FORM.EESV', value: '065' },
    { name: 'COMPANY-FORM.SCE', value: '001' },
    { name: 'COMPANY-FORM.BVBA', value: '015' },
    { name: 'COMPANY-FORM.EBVBA', value: '010' },
    { name: 'COMPANY-FORM.S-BVBA', value: '015' },
    { name: 'COMPANY-FORM.COMMVA', value: '013' },
    { name: 'COMPANY-FORM.CVBA', value: '008' },
    { name: 'COMPANY-FORM.CVOA', value: '006' },
    { name: 'COMPANY-FORM.FOREIGN', value: '9900' },
    { name: 'COMPANY-FORM.NVT', value: '9901' },
  ];

  regionOptions: { name: string; value: Region }[] = [
    {
      name: 'REGION.FLANDERS',
      value: Region.FLANDERS,
    },
    {
      name: 'REGION.WALLONIA',
      value: Region.WALLONIA,
    },
    {
      name: 'REGION.BRUSSELS',
      value: Region.BRUSSELS,
    },
  ];

  statusOptions: { name: string; value: CompanyStatus }[] = [
    { name: 'COMPANY-STATUS.ACTIVE', value: CompanyStatus.ACTIVE },
    { name: 'COMPANY-STATUS.STOPPED', value: CompanyStatus.DISCONTINUED },
    { name: 'COMPANY-STATUS.IN_FORMATION', value: CompanyStatus.IN_FORMATION },
  ];

  booleanOptions: { name: string; value: boolean }[] = [
    {
      name: 'FORM-FIELD.YES',
      value: true,
    },
    {
      name: 'FORM-FIELD.NO',
      value: false,
    },
  ];

  languages = [
    { name: 'LANGUAGE.MULTI', value: Language.MULTI },
    { name: 'LANGUAGE.NL', value: Language.NL },
    { name: 'LANGUAGE.FR', value: Language.FR },
    { name: 'LANGUAGE.EN', value: Language.EN },
    { name: 'LANGUAGE.DE', value: Language.DE },
  ];

  countries: { name: string; value: string }[];
  country: string = 'BE';
  vatLiable: boolean = true;
  companyNumber: string;
  fetchCompanyStatus = Status.CLEAR;
  fetchCompanyMessage: string | null = null;

  moralPersonForm = new MoralPersonForm();

  constructor(
    public dialog: MatDialog,
    private clientsApiService: ClientsApiService,
    private translateService: TranslateService,
    private router: Router,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    if (this.person != undefined) {
      this.moralPersonForm.initFromMoralPerson(this.person);
    }

    this.countries = this.getCountries(this.translateService.getDefaultLang());

    this.translateService.onDefaultLangChange.subscribe((event: DefaultLangChangeEvent) => {
      this.countries = this.getCountries(event.lang);
    });

    this.moralPersonForm.form.get('base.companyNumber')!.valueChanges.subscribe((selectedValue) => {
      this.companyNumber = selectedValue;
      this.checkIfInBelgium();
    });

    this.moralPersonForm.form.get('vat.vatLiable')!.valueChanges.subscribe((selectedValue) => {
      this.onVatLiableChange(selectedValue);
    });

    this.baseForm
      .get('country')
      ?.valueChanges.subscribe((value) => this.addressForm.patchValue({ country: value }));
  }

  get baseForm(): UntypedFormGroup {
    return this.moralPersonForm.form.get('base') as UntypedFormGroup;
  }

  get companyForm(): UntypedFormGroup {
    return this.moralPersonForm.form.get('company') as UntypedFormGroup;
  }

  get addressForm(): UntypedFormGroup {
    return this.moralPersonForm.form.get('address') as UntypedFormGroup;
  }

  get vatForm(): UntypedFormGroup {
    return this.moralPersonForm.form.get('vat') as UntypedFormGroup;
  }

  getCountries(lang: string): { name: string; value: string }[] {
    const i18nCountries = i18nIsoCountries.getNames(lang);
    const countries: { name: string; value: string }[] = [];

    forOwn(i18nCountries, (value, key) => {
      countries.push({ value: key, name: value });
    });
    return orderBy(countries, 'name', 'asc');
  }

  openCancelDialog(): void {
    if (this.person?.id) {
      const dialogRef = this.dialog.open(BaseDialog, {
        data: {
          title: 'DIALOG.CANCEL-FORM.DESCRIPTION-PERSON-UPDATE',
          description: '',
          cancelText: 'DIALOG.NO-CONTINUE',
          confirmText: 'DIALOG.YES-CANCEL',
          confirmColor: 'accent',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.formCancelled.emit();
        }
      });
    } else {
      this.router.navigate(['/']);
    }
  }

  mapErrors(errors: ValidationErrors | null): void {
    if (!errors) return;

    const errorKeys = Object.keys(errors);
    if (!errorKeys) return;

    errorKeys.forEach((key) => {
      if (!this.moralPersonForm.form.controls[key]) return;
      this.moralPersonForm.form.controls[key].setErrors({
        custom: errors[key],
      });
    });
  }

  save() {
    this.moralPersonForm.form.markAllAsTouched();

    if (this.moralPersonForm.form.valid) {
      const person = this.moralPersonForm.toPersonDto();
      if (this.clientId) {
        const client: ClientCommand = { person };
        this.clientsApiService.updateClient(client, this.clientId).subscribe(
          (updatedClient) => {
            this.toastService.openClientUpdatedToast(updatedClient.person.getScreenName());
            this.formSaved.emit(updatedClient);
          },
          (error) => this.handleErrors(error)
        );
      } else {
        const client: ClientCommand = { person };

        this.clientsApiService.addClient(client).subscribe(
          (createdClient) => {
            this.toastService.openClientCreatedToast(createdClient.person.getScreenName());
            this.formSaved.emit(createdClient);
          },
          (error) => this.handleErrors(error)
        );
      }
    } else {
      this.toastService.openValidationToast();
    }
  }

  getErrorMessage(form: UntypedFormGroup, element: string) {
    const control = form.get(element);
    const errors = control?.errors;
    if (!errors || !Object.keys(errors).length) return null;

    switch (Object.keys(errors)[0]) {
      case 'required':
        return 'VALIDATION-ERRORS.REQUIRED';
      case 'empty':
        return 'VALIDATION-ERRORS.NOT-EMPTY';
      case 'pattern':
        return 'VALIDATION-ERRORS.PATTERN';
      case 'min':
      case 'max':
        return 'VALIDATION-ERRORS.INVALID';
      case 'minlength':
        return 'VALIDATION-ERRORS.MIN-LENGTH';
      case 'maxlength':
        return 'VALIDATION-ERRORS.MAX-LENGTH';
      case 'custom':
        return control?.errors?.custom;
      default:
        return null;
    }
  }

  onCountryChange(event: { value: string }) {
    this.country = event.value;
    this.checkIfInBelgium();
  }

  onVatLiableChange(value: any) {
    this.vatLiable = value;
    if (this.vatLiable) {
      this.moralPersonForm.form
        .get('vat.vatNumber')!
        .addValidators([CustomValidator.validVat, Validators.required, CustomValidator.notEmpty]);
    } else {
      this.moralPersonForm.form.get('vat.vatNumber')!.clearValidators();
    }
    this.checkIfInBelgium();
  }

  private checkIfInBelgium() {
    if (!this.country || !this.vatLiable) return;
    if (this.country === 'BE') {
      this.setBelgianVatNumber();
      // Only auto fill on ceation
      if (!this.person) {
        this.checkKbo();
      }
    }
  }

  private checkKbo() {
    if (this.companyNumber.length === 10) {
      this.fetchCompanyStatus = Status.PENDING;
      this.fetchCompanyMessage = null;
      this.clientsApiService
        .getOrganisationFromKBO(this.companyNumber, this.translateService.getDefaultLang())
        .subscribe({
          next: (result) => {
            this.fetchCompanyStatus = Status.SUCCESS;
            this.fetchCompanyMessage = this.translateService.instant(
              'VALIDATION-ERRORS.COMPANY-NUMBER.FOUND'
            );
            this.autoFillFields(result);
          },
          error: (error) => {
            this.fetchCompanyStatus = Status.FAILED;
            this.fetchCompanyMessage = this.translateService.instant(
              'VALIDATION-ERRORS.COMPANY-NUMBER.NOT-FOUND'
            );
            this.clearAutoFilledFields();
          },
        });
    } else {
      this.fetchCompanyMessage = null;
      this.clearAutoFilledFields();
      // TODO - make outline orange
    }
  }

  private autoFillFields(org: OrganisationKBO) {
    this.companyForm.patchValue({ name: org.name });
    this.companyForm.patchValue({ companyForm: org.juridicalForm.code });
    this.companyForm.patchValue({ status: CompanyStatus.ACTIVE });

    this.addressForm.patchValue({ country: org.registeredOffice.country });
    this.addressForm.patchValue({ city: org.registeredOffice.city });
    this.addressForm.patchValue({ zipCode: org.registeredOffice.postalCode });
    this.addressForm.patchValue({ street: org.registeredOffice.street });
    this.addressForm.patchValue({ streetNumber: org.registeredOffice.number });
  }

  private clearAutoFilledFields() {
    this.companyForm.reset('name');
    this.companyForm.patchValue({ companyForm: '610' }); // Default value
    this.addressForm.reset('city');
    this.addressForm.patchValue({ country: this.moralPersonForm.form.get('base.country')!.value }); // Default value
    this.companyForm.patchValue({ status: CompanyStatus.ACTIVE });
  }

  private getStatus(status: string) {
    switch (status) {
      case 'actief':
        return CompanyStatus.ACTIVE;
      case 'inactief':
        return CompanyStatus.DISCONTINUED;
      case 'information':
        return CompanyStatus.IN_FORMATION;
      default:
        return CompanyStatus.ACTIVE;
    }
  }

  private setBelgianVatNumber() {
    const number = this.moralPersonForm.form.get('base.companyNumber')!.value;
    if (!number) return;
    this.moralPersonForm.form.patchValue({ vat: { vatNumber: `BE${number}` } });
  }

  private handleErrors(error: any) {
    if (error.errors && error.errors.length > 0) {
      const errors: { defaultMessage: string }[] = error.errors;
      errors.map((error) => {
        this.toastService.openErrorToast(error.defaultMessage);
      });
    } else if (error.status && error.status === 409) {
      this.toastService.openWarningToast(
        '',
        this.translateService.instant('TOAST.CLIENT-EXISTS-MORAL-PERSON')
      );
    } else {
      this.toastService.openErrorToast(`[${error.status}: ${error.error}] ${error.message}`);
    }
  }
}
